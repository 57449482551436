import React, { useEffect, useState, useRef } from 'react'
import ReactQuill from 'react-quill';
import FlashMessage from 'react-flash-message'
import Button from './Button'
import UserAvatar from 'react-user-avatar'
import { colors } from '../../helpers/Colors'
import Loader from './Loader'

const NewPost = ({ authToken, setCommunityPosts, current_user, setShowNewPost, setShowCommunity, is_edit = false, post, channel_id }) => {
  const [editorHtml, setEditorHtml] = useState('');
  const [title, setTitle] = useState('')
  const [searchUser, setSearchUser] = useState(false)

  const [contentStatus, setContentStatus] = useState('content_empty')
  const [disablePost, setDisablePost] = useState(true)
  const [msg, setMsg] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const [loading, setLoading] = useState(false)

  const [validGroupList, setValidGroupList] = useState([])
  const [validGroupIds, setValidGroupIds] = useState([])
  const [currentGroups, setCurrentGroups] = useState([])
  const inActiveGroups = currentGroups?.filter((tag) => !validGroupIds.includes(tag.id))

  const [validUsersList, setValidUsersList] = useState([])
  const [validUserIds, setValidUserIds] = useState([])
  const [currentUsers, setCurrentUsers] = useState([])
  const inActiveUsers = currentUsers?.filter((tag) => !validUserIds.includes(tag.id))



  const updateTaggedUsers = (user, del = false) => {
    if (del) {
      setValidUsersList(validUsersList.filter((tagged) => tagged.id !== user.id))
      setValidUserIds(validGroupIds.filter((tagged) => tagged !== user.id))
    } else if (!validUserIds.includes(user.id)) {
      setValidUsersList([...validUsersList, user])
      setValidUserIds([...validUserIds, user.id])

    }
  }

  const updateTaggedGroups = (tag, del = false) => {
    if (del) {
      setValidGroupList(validGroupList.filter((tagged) => tagged.id !== tag.id))
      setValidGroupIds(validGroupIds.filter((tagged) => tagged !== tag.id))
    } else if (!validGroupIds.includes(tag.id)) {
      setValidGroupList([...validGroupList, tag])
      setValidGroupIds([...validGroupIds, tag.id])
    }
  }

  const getTagList = (search_term) => {
    fetch(`/comm_posts/@${search_term}/comm_tags`, {
      credentials: 'same-origin',
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': authToken,
      }
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        }
        return response.json()
      })
      .then(({ tags_list, custom_tags_list }) => {

        setCurrentUsers(tags_list)
        setCurrentGroups(custom_tags_list)

      })
      .catch(err => {
        console.log(err)
      })
  }



  const handleMessage = (msg) => {
    setMsg(msg)
    setShowMessage(true)
  }

  useEffect(() => {
    if (is_edit && post) {
      setTitle(post.title)
      setEditorHtml(post.content)
    }

  }, [])

  useEffect(() => {
    if (editorHtml?.length > 2) setContentStatus('content_text')
    if (editorHtml?.length > 20) setDisablePost(false)
  }, [editorHtml])


  const handleSubmit = (e) => {
    e.preventDefault()
    if (contentStatus == 'content_empty' || loading) return

    if (is_edit && post) submitPost(`comm_posts/${post.id}`, 'PUT')
    else submitPost('comm_posts/', 'POST')
  }


  const submitPost = (destination, action) => {
    setLoading(true)
    fetch(destination, {
      credentials: 'same-origin',
      method: action,
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': authToken,
      },
      body: JSON.stringify({
        title: title,
        content: editorHtml,
        tagged: validUserIds,
        custom_tags: validGroupIds,
        comm_channel_id: channel_id
      })
    }).then(response => response.json())
      .then(({ status, errors, comm_posts }) => {
        if (status === 201 || status == 200) {
          setCommunityPosts(comm_posts)
          setShowNewPost(false)
          setShowCommunity(true)

        } else {
          handleMessage(errors?.map((er, index) => (
            <span key={index} className='text-danger'>{er} <br /></span>
          )))
        }
        setLoading(false)

      })
      .catch(err => {
        console.log(err)
        handleMessage(<span className='text-danger'>That didn't work. Please try again later...</span>)
      })
  }

  var modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ]
  }

  var formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]




  return (
    <div className={`new_post ${contentStatus}`}>
      <form className="form">
        <div className='form-top'>
          <h6>Create a post</h6>
          <span className="close" onClick={() => {
            setShowNewPost(false)
            setShowCommunity(true)
          }
          }>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </span>
        </div>
        <div className="form-wrap">

          <div>
            <input type="text" className='title' value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title (Optional)" />
          </div>

          <div className="rich-content">
            <ReactQuill
              theme="snow"
              onChange={setEditorHtml}
              value={editorHtml}
              modules={modules}
              formats={formats}
              placeholder='Write Something...'

            />
          </div>
          {showMessage === true &&
            <div className='p-4'>
              <FlashMessage duration={3000} persistOnHover={true} className='text-center mb-3'>
                {msg}
              </FlashMessage>
            </div>
          }

          <div className='tag-people pb-4'>

            <div className='row pb-4'>
              <div className='user-plus col-1' onClick={() => setSearchUser(!searchUser)}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                  <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" /></svg>
              </div>
              <div className='tagged-list col-11'>
                {validUsersList?.map((tagged) => (
                  <div className='tagged_user' key={tagged.id}>
                    <span onClick={() => updateTaggedUsers(tagged, true)}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z" /></svg>
                    </span>
                    <p>{tagged.user_handle}</p>
                  </div>
                ))}

                {validGroupList?.map((tagged) => (
                  <div className='tagged_user' key={tagged.id}>
                    <span onClick={() => updateTaggedGroups(tagged, true)}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z" /></svg>
                    </span>
                    <p>{tagged.code_value}</p>
                  </div>
                ))}
              </div>
            </div>


            {searchUser &&
              <div className='search_users'>
                {(inActiveUsers?.length > 0 || inActiveGroups?.length > 0) &&
                  <div className='tags'>
                    <ul>
                      {inActiveUsers?.map((user, index) => (
                        <li onClick={() => updateTaggedUsers(user)} key={index}>
                          <div className='tag-avatar'>
                            {user.avatar_url ?
                              <UserAvatar size="30"
                                name={user.user_handle}
                                src={user.avatar_url}
                              />
                              :
                              <UserAvatar size="30" name={user.user_handle}
                                colors={colors}
                              />
                            }
                          </div>
                          {user.user_handle} </li>
                      ))
                      }
                      {inActiveGroups?.map((tag, index) => (
                        <li onClick={() => updateTaggedGroups(tag)} key={index}>
                          <div className='tag-avatar'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                              <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" />
                            </svg> &nbsp;
                            {tag.code_value}
                            <span className='text-muted text-small'> &nbsp;Notify {tag.user_count} users</span>
                          </div>
                        </li>

                      ))
                      }
                    </ul>
                  </div>
                }
                {!current_user?.allow_tagging &&
                  <div className='allow'><a href='/profile'>Allow Others to Tag You <b>Here</b></a></div>
                }
                <input
                  placeholder="Start Typing the user's name ..."
                  onChange={(e) => getTagList(e.target.value)}
                ></input>
                <div className='close' onClick={() => {
                  setSearchUser(false)
                  setCurrentGroups([])
                  setCurrentUsers([])
                }}>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                  </span>
                </div>
              </div>
            }


          </div>
          {loading &&
            <div className='posts-loading'>
              <Loader
                type="circle"
              />
            </div>

          }

          {!searchUser &&
            <Button
              text='Post'
              type='button'
              classes='text-center'
              disabled={disablePost && !loading}
              handleClick={(e) => handleSubmit(e)}
            />
          }

        </div>
      </form >
    </div>
  )
}

export default NewPost