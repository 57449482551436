import React from 'react'

import Label from './Label'

function Textarea({ value, onChangeInput, name, cols = 100, rows = 10, label_text }) {

   return (
      <>
         {
            label_text &&
            <Label htmlFor={name} label_text={label_text} />
         }
         <textarea
            onChange={onChangeInput}
            name={name}
            id={name}
            cols={cols}
            rows={rows}
            value={value === null ? "" : value}
         ></textarea>
      </>
   )
}

export default Textarea