import React, { useState } from 'react'

import CustomRating from '../../../shared/buttons/CustomRating'
import ModalWithContinue from '../../../shared/modals/ModalWithContinue'

function ClientSwiperElement({ id, comment, public_image_url, name, country }) {

   const [active_id, setActiveId] = useState(null)

   const handleTextClick = () => {
      setActiveId(id)
   }
   const handleCloseModal = () => {
      setActiveId(null)
   }

   return (
      <article className="client">
         <div className="client__comment-blocK">
            <CustomRating
               average_rating={5}
               disabled={true}
            />
            <p onClick={handleTextClick} className="client_comment">{comment}</p>
         </div>
         <img className="client__avatar" src={public_image_url} alt="client avatar" />
         <h5>{name}</h5>
         <h6>{country}</h6>
         <ModalWithContinue
            show={active_id === id}
            onCloseModal={handleCloseModal}>
            <p className='pb-5'>
               {comment}
            </p>
         </ModalWithContinue>
      </article>
   )
}

export default ClientSwiperElement
