import React from 'react'

import calendar_img from '../../../../../assets/images/calendar.svg'
import CustomRating from '../../../../shared/buttons/CustomRating'


function Workshop({id, title, description, thumbnail_image_url, average_rating, start_date, end_date, is_upcoming_workshop }) {

   const getDate = (date_string) => {
      const data = new Date(date_string)
      const day = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate()
      const month = data.getMonth() + 1 < 10 ? `0${data.getMonth() + 1}` : data.getMonth() + 1
      return `${month}/${day}`
   }
   return (
      <div className="dashboard_workshop row mb-3 ml-0">
         <div className='col-5 p-1'> 
         <a href={`workshops/${id}`}>
           <img className="dashboard_workshop__img" src={thumbnail_image_url} alr="workshop" />
         </a>
         </div> 
         <div className='col-6 p-1'>

         {
            !isNaN(average_rating) &&
            <div className="d-flex justify-content-between">
               <div className="dashboard_workshop_rating">
                  <CustomRating
                     average_rating={average_rating}
                     disabled={true}
                  />
               </div>
               {
                  start_date &&
                  <div className="dashboard_workshop_date">
                     <img src={calendar_img} alt="calendar" />
                     <span>
                        {is_upcoming_workshop && 'Live: '}{getDate(start_date)} - {end_date ? getDate(end_date) : null}
                     </span>
                  </div>
               }
            </div>
         }

         
         <h5 className="dashboard_workshop__title mt-2 mb-2">{title}</h5>

         <p className="dashboard_workshop__sub_title">{description}</p>
         </div>
         <div className='col-1 p-0 d-none d-sm-block'>
            <a href={`workshops/${id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="40.000000pt" height="40.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
               <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#f99267" stroke="none">
                  <path d="M2346 4549 c-369 -39 -752 -199 -1046 -436 -89 -72 -257 -243 -321 -328 -270 -353 -419 -788 -419 -1225 0 -453 161 -906 447 -1260 72 -89 243 -257 328 -321 352 -269 790 -419 1225 -419 435 0 873 150 1225 419 85 64 256 232 328 321 583 722 595 1744 28 2485 -64 85 -232 256 -321 328 -411 332 -950 491 -1474 436z m539 -178 c408 -76 787 -289 1051 -590 218 -249 375 -572 436 -896 32 -173 32 -477 0 -649 -76 -403 -291 -787 -587 -1048 -249 -220 -573 -378 -900 -440 -173 -32 -477 -32 -649 0 -692 131 -1238 622 -1442 1297 -52 170 -68 294 -68 515 0 169 4 225 22 320 50 268 145 500 292 714 140 204 293 356 495 491 263 175 519 268 855 309 89 11 389 -3 495 -23z" fill="#f99267"/>
                  <path d="M2265 3335 c-16 -15 -25 -35 -25 -56 0 -37 -33 -5 432 -421 180 -161 327 -295 327 -298 0 -3 -147 -137 -327 -298 -465 -416 -432 -384 -432 -421 0 -42 38 -81 79 -81 34 0 1 -28 555 470 180 162 319 295 322 308 4 12 4 32 0 45 -4 19 -215 213 -743 685 -115 103 -143 113 -188 67z" fill="#f99267"/>
               </g>
            </svg>
            </a>

         </div>

      </div>
   )
}

export default Workshop
