import React from 'react'

export default function AfformationTexarea({ value, onChange, name }) {
  return (
    <div className="field">
      <label className="field__label--text-area text-center">
        Please complete today's positive affirmation*
      </label>

      <textarea
        placeholder="Today, I am ..."
        name={name}
        value={value}
        id="daily_assessment_i_am"
        cols="100"
        rows="4"
        onChange={onChange}
      ></textarea>
    </div>
  )
}
