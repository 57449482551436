import React from 'react'

function EmotionSliderMiniItem({ emotion = null, isActive, makeActive, emotionType }) {

   function renderEmotionImg() {
      if (emotion.icon_image.type === 'svg') {
         return (
            <div
               className="emotion-list__emotion-img"
               dangerouslySetInnerHTML={{ __html: emotion.icon_image.image }}
            />
         )
      }
      return (
         <img src={emotion.icon_image.image} width="30" height="30" loading="lazy" />
      )
   }

   return (
      <button
         onClick={(e) => {
            e.preventDefault()
            makeActive(emotion.id, emotionType)
         }}
         className={`emotions-list__emotion-mini ${isActive(emotion.id) ? 'active' : ''}`}
      >
         {
            emotion.icon_image &&
            renderEmotionImg()
         }
         <span>{emotion.name}</span>
      </button >
   )
}

export default EmotionSliderMiniItem