import React, { useState, useEffect } from 'react'
import Range from '../../../../shared/components/Range'

function Assessment360(props) {
  const [emotions, setEmotions] = useState([])
  const [flag, setFlag] = useState(false)
  const [selectedEmotion, setSelectedEmotion] = useState(0)
  const [formData, setFormData] = useState({
    activeEmotion: 0,
    feeling_overall: 5,
    financial_rate: 5,
    career_success: 5,
    physical_environment: 5,
    involvment_in_fun: 5,
    personal_growth: 5,
    romantic_life: 5,
    sense_of_purpose: 5,
    contribution_to_society: 5,
    overall_heath: 5,
    relationship: 5,
  })

  const rangeList = [
    {
      value: formData.feeling_overall,
      name: 'feeling_overall',
      label_text: 'On a scale of 1-10, how are you feeling overall?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.financial_rate,
      name: 'financial_rate',
      label_text: 'How would you rate yourself financially?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.career_success,
      name: 'career_success',
      label_text: 'How would you rate your success in your career?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.physical_environment,
      name: 'physical_environment',
      label_text: 'How would you rate your physical environment?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.involvment_in_fun,
      name: 'involvment_in_fun',
      label_text: 'How would you rate your involvment in fun & recreation?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.personal_growth,
      name: 'personal_growth',
      label_text: 'How would you rate your personal growth?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.romantic_life,
      name: 'romantic_life',
      label_text: 'How would you rate your romantic life?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.sense_of_purpose,
      name: 'sense_of_purpose',
      label_text: 'How would you rate your sense of purpose?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.contribution_to_society,
      name: 'contribution_to_society',
      label_text: 'How would you rate your level of contribution to society?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.overall_heath,
      name: 'overall_heath',
      label_text: 'How would you rate your overall health?',
      max: '10',
      amountFlag: true,
    },
    {
      value: formData.relationship,
      name: 'relationship',
      label_text: 'How would you rate your relationship to family & friends?',
      max: '10',
      amountFlag: true,
    },
  ]

  useEffect(() => {
    if (flag) return
    fetch('/assessment-emotions', {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        }
        return response.json()
      })
      .then((data) => {
        setFlag(true)
        setEmotions(data.emotions)
      })
      .catch((error) => console.log(error))
  }, [])

  function handleChange(event) {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <>
      <div className="container__module">
        <div className="module__content">
          <div className="wrapper wheel-assessment2">
            <h2>360 Degree Assessment</h2>
            <div className="header-description">
              <p>
                It’s time to track your progress! Please complete the following assessment as
                honestly as possible. Remember, this is a judgment free zone.
              </p>
              <br />
              <h4 className="field-title">Tell Us About Yourself</h4>
            </div>

            <div className="field">
              <div className="emotions__header">
                <p>How do you feeling today?</p>
              </div>

              <div className="emotions-list">
                {emotions.map((emotion) => {
                  return (
                    <div
                      className={`emotions-list__emotion ${selectedEmotion === emotion.id ? 'active' : ''
                        }`}
                    >
                      <div
                        className="emotion-list__emotion-img"
                        dangerouslySetInnerHTML={{ __html: emotion.svg_image }}
                      />
                      <div
                        className="btn btn_secondary emotion"
                        onClick={() => handleEmotionClick(emotion.id)}
                      >
                        {emotion.name}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            {rangeList.map((range) => {
              return (
                <div className="field" key={range.name}>
                  <Range
                    value={range.value}
                    onChangeRange={handleChange}
                    name={range.name}
                    label_text={range.label_text}
                    max={range.max}
                    amountFlag={range.amountFlag}
                  />
                </div>
              )
            })}

            <div className="additional-block">
              <div className="additional-block__desctiption">
                <h4>Addiction Questions</h4>
                <br />
                <p>
                  We understand that everyone has different struggles with addiction. Please answer
                  the questions below for the types of addiction you would like assistance with.
                </p>
              </div>
              <div className="additional-block__alcohol-addiction"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Assessment360
