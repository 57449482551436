export const rewardPlug = {
   id: 'id',
   state: 'not invisable',
   user_point: {
      points: ""
   },
   text: "You have successfully completed the exercise!",
   reward: {
      default_points: "points",
      short_name: 'short_name',
      description: 'description'
   },
   short_name: 'lesson_complete',

}