import React from 'react'

import ModalWithContinue from '../../../shared/modals/ModalWithContinue'

function WelcomeVideo({ current_program_name, welcome_video_url, show, handleCloseModal }) {

  return (
    <ModalWithContinue
      show={show}
      onCloseModal={handleCloseModal}>
      <div className="container m-0 p-0 dash_welcome_video">
        <div className='dash_install_btn'><a href='/install'>Install App</a></div>
        <h1>Welcome to the IGNTD {current_program_name} Program!</h1>

        <div className="container__video mt-2">
          <iframe
            src={welcome_video_url}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <div className="actions">
        </div>
      </div>
    </ModalWithContinue>
  )
}

export default WelcomeVideo
