import React, { useContext } from 'react'

import LinkToStart from '../../../shared/buttons/LinkToStart'
import IntroForm from '../../introForm/IntroForm'

import MyDrincinScoreLogo from '../../../../assets/images/MY_DRINKING_SCORE_LOGO.png'
import SdsAssessment from '../../AssessmentPage/SdsAssessment'



const IntroPage = (props) => {
  return (
    <section className="intro">
      <div className="wrapper intro__wrapper">
        {/* <header className="header header__wrapper">
          <div className="header_logo logo">
            <img src={logoWhite} />
          </div>
        </header> */}
        <div className="intro__content">
          <div className="intro__col">
            <img className="intro__logo" src={MyDrincinScoreLogo} />
            <h2 className="intro__subtitle subtitle">Stop Trying to Get Sober</h2>
            <h1 className="intro__title">
              Get Your Drinking Score & Personalized Path To Freedom From Alcohol
            </h1>
            <p className="intro__description">
              A customized, personalized, virtual system that provides you with the exact
              recommendations & steps to eliminate your struggles, transform your world & break free
              forever!
            </p>
            <div className="intro__desktop">
              <LinkToStart />
            </div>
          </div>
          <div className="intro__form-col">
            <SdsAssessment
              {...props}
            />
            <div className="intro__mob">
              <LinkToStart />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroPage
