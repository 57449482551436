import React from 'react'

import { iconData_summary, wheelOfLiveCategoryesList } from '../../../helpers/wheelOfLiveHelper'
import WheelOfLiveChart from '../../../shared/components/WheelOfLiveChart'

function WheelOfLive({ this_months_scores }) {

  const chartData = wheelOfLiveCategoryesList.map((category, index) => ({
    ...category,
    current_value: this_months_scores[index],
  }))

  return (
    <div className='card wheel_of_life results__wheelOfLife'>
      <h2 className="results__card-title results__card-title_wheel">
        Key Areas Of Life - Where Do You Struggle?
      </h2>
      <WheelOfLiveChart
        chartData={chartData}
        iconData={iconData_summary}
      />
      <div className='text-center mt-3'>
        <a   className="btn_primary" href='/assessments'>
          Take WheelOfLife Assessment
        </a>
      </div>
    </div>
  )
}

export default WheelOfLive
