import React, { useState } from 'react'

import { postFavorite, deleteFavorite } from '../../helpers/fetchHelper'

import BookmarkActiveIcon from '../../../assets/images/bookmark_active.svg'
import BookmarkIcon from '../../../assets/images/bookmark.svg'

function Bookmark({ isFavorite = false, authToken, contentType, contentId }) {

   const [inFavorites, setInFavorites] = useState(isFavorite)

   const addToFavorites = () => {
      const body = {
         favoritable_type: contentType,
         favoritable_id: contentId
      }
      postFavorite(authToken, body)
         .catch(err => console.log(err))
   }

   const removeFromFavorites = () => {
      deleteFavorite(authToken, contentType, contentId)
         .catch(err => console.log(err))
   }

   const toggleBookmark = () => {
      const newInFavorites = !inFavorites
      setInFavorites(newInFavorites)
      if (newInFavorites) {
         addToFavorites()
      } else {
         removeFromFavorites()
      }
   }

   return (
      <button className="custom-tooltip">
         {
            !inFavorites &&
            <span className="custom-tooltip__text custom-tooltip__top">Add to your favorites</span>
         }
         <img
            src={inFavorites ? BookmarkActiveIcon : BookmarkIcon}
            onClick={toggleBookmark}
            alt="bookmark_icon"
         />
      </button>
   )
}

export default Bookmark