import React, { useState } from 'react'
import SnippetCard from '../../../shared/components/SnippetCard'


const RecommendedSnippets = ({ recommendation_snippets, authToken }) => {
    const [recommendedSnippets, setRecommendedSnippets] = useState(recommendation_snippets)

    const getSnippets = () => {
        fetch('/recommendation-snippets', {
            credentials: 'same-origin',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': authToken,
            },
        })
        .then(response => response.json())
        .then((data) => {
            setRecommendedSnippets(data.snippets)
        })
        .catch(err => {
            console.log(err)
          })

    }

  return (
    <section className = 'recommended_snippets mb-5'>
        <h4 className='d-none d-sm-block section_header pb-2'>Recommended Videos 
        <span className='float-right link'>
        <div 
        className="btn_secondary_orange refresh"
        onClick={() => getSnippets() }
            >
            Refresh &nbsp;&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
            </svg>
        </div>
        </span>
        </h4>
        <div className='snippets mt-3'>
            <h4 className='d-sm-none section_header pb-2'>Recommended Videos &nbsp;  
                <span className='float-right link'>
                    <div 
                    className="refresh"
                    onClick={() => getSnippets() }
                        >
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#536CDE"/>
                        <path d="M20.8408 13.1696H25.6413V8.36914" stroke="white" strokeWidth="1.60016" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.80029 9.77928C10.6168 8.96161 11.5864 8.31293 12.6538 7.87035C13.7212 7.42776 14.8654 7.19995 16.0209 7.19995C17.1764 7.19995 18.3206 7.42776 19.388 7.87035C20.4554 8.31293 21.4251 8.96161 22.2416 9.77928L25.6419 13.1696" stroke="white" strokeWidth="1.60016" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.1999 18.8303H6.39941V23.6308" stroke="white" strokeWidth="1.60016" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M22.241 22.2207C21.4246 23.0383 20.4549 23.687 19.3875 24.1296C18.3201 24.5722 17.1759 24.8 16.0204 24.8C14.8649 24.8 13.7207 24.5722 12.6533 24.1296C11.5859 23.687 10.6162 23.0383 9.79976 22.2207L6.39941 18.8303" stroke="white" strokeWidth="1.60016" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </div>
                </span>
            </h4>
            <span className='d-none d-sm-block mb-2'>Click <b>'F'</b> while playing video to toggle fullscreen mode.</span>
            <div className="videos-wrapper">
                <div className="videos">
                    {
                        recommendedSnippets?.length && recommendedSnippets.map((snipet) =>
                        <SnippetCard 
                        key={snipet.snippet_id}
                        authToken={authToken}
                        snippet={{
                            ...snipet,
                            isFavorite: snipet['favorite?'],
                            ratable_type: "Snippet",
                            disabled_rating: false
                        }}
                        /> )
                    }
                </div>
            </div>
        </div>
 </section>
  )
}

export default RecommendedSnippets