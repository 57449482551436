import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import InactiveRightArrow from '../../../../assets/images/arrow_grey_right.svg'
import LeftArrow from '../../../../assets/images/arrow_navy_blue_left.svg'

function Introduction({ profile, title = '', text = '', isCompleted = false, stepsAmount, step, changeStep }) {

   return (
      <>
         <h1 className="text_to_center">{ReactHtmlParser(profile.title || "")}</h1>
         <h4 className="mt-40">{ReactHtmlParser(title)}</h4>
         <p className="mt-30 mb-30">{ReactHtmlParser(text)}</p>
         <div className="flex_container">
            <button className={step === 0 ? "btn_hide" : "btn_secondary"} onClick={() => changeStep(step - 1)}>
               <img className="btn_secondary-img" src={LeftArrow} /> Back
            </button>
            <button className={step === stepsAmount ? "btn_hide" : "btn_primary"} onClick={() => changeStep(step + 1)}>
               Next <img className="btn_secondary-img next" src={InactiveRightArrow} />
            </button>
         </div>
      </>
   )
}

export default Introduction