import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'
import emotionsHappy from '../../../../assets/images/emotions_happy_new.svg'
import emotionsUnhappy from '../../../../assets/images/emoji_upset.svg'
import equal from '../../../../assets/images/equal.svg'
import more from '../../../../assets/images/more.svg'
import less from '../../../../assets/images/less.svg'
import info from '../../../../assets/images/info.svg'
import blackInfo from '../../../../assets/images/black_info_icon.svg'
import notdrink from '../../../../assets/images/notDrink.svg'
import drink from '../../../../assets/images/drink.svg'

import Tooltip from '../components/Tooltip'

export default function UsedAddictions(props) {
  const [tooltipImg, setTooltipImg] = useState(false)

  function changeTooltip() {
    setTooltipImg(true)
  }

  return (
    <>
      <div className="field">
        <div className="box">
          <div className="split-label">
            <p className="daily_assesment_emotions_title">
              {props.currentUser.addiction === 'alcohol'
                ? 'Did you drink in the past day?'
                : `Did you use ${props.currentUser.addiction === 'sex' ? 'sex/porn' : props.currentUser.addiction} in the past days?`}
            </p>
          </div>
          <div
            className={`emotions-wrapper emotions-wrapper-used-addictions ${props.currentUser.addiction !== 'alcohol' ? 'not-alco-addiction' : ''
              } `}
          >
            <div className="emotions-list__emotion emotions-list__did">
              <img
                src={props.currentUser.addiction === 'alcohol' ? drink : emotionsUnhappy}
                height="auto"
                width="100%"
              />
              <div
                className={`btn ${props.drinkOrNot ? 'btn_primary' : 'btn_secondary'} emotion`}
                onClick={() => props.handleDrinkingBtn(true)}
              >
                Yes
              </div>
            </div>
            <div className="emotions-list__emotion emotions-list__did">
              <img
                src={props.currentUser.addiction === 'alcohol' ? notdrink : emotionsHappy}
                height="auto"
                width="100%"
              />
              <div
                className={`btn ${!props.drinkOrNot && props.drinkOrNot !== '' ? 'btn_primary' : 'btn_secondary'
                  } emotion`}
                onClick={() => props.handleDrinkingBtn(false)}
              >
                No
              </div>
            </div>
          </div>
        </div>

        {props.drinkOrNot && (
          <div className="box">
            <div className="split-label">
              <p className="daily_assesment_emotions_title">How much?</p>
            </div>
            <div className="quantity-items-wrapper">
              <div
                className={`item ${props.specificQuantity === 'Less than usual' ? 'btn_active' : ''
                  }`}
                onClick={() => props.handleQuantity('Less than usual')}
              >
                <img src={less} />
                <span>Less than usual</span>
              </div>
              <div
                className={`item ${props.specificQuantity === 'Same as usual' ? 'btn_active' : ''}`}
                onClick={() => props.handleQuantity('Same as usual')}
              >
                <img src={equal} />
                <span> Same as usual</span>
              </div>
              <div
                className={`item ${props.specificQuantity === 'More than usual' ? 'btn_active' : ''
                  }`}
                onClick={() => props.handleQuantity('More than usual')}
              >
                <img src={more} />
                <span>More than usual</span>
              </div>
            </div>
          </div>
        )}
        {props.drinkOrNot && props.specificQuantity.length > 0 && (
          <div className="box">
            <div className="split-label">
              <p className="daily_assesment_emotions_title">
                Please indicate the specific quantity:
              </p>
              <p className="text-center">
                Enter numbers only
              </p>
            </div>
            <div className="w-100">
              <input
                type="number"
                value={props.tracking_amount.toString()}
                onChange={(e) => {
                  props.handleDrinksQuantity(e)
                }}
              />
              {props.currentUser.addiction === 'alcohol' && (
                <>
                  <span className="addiction-type">Standard drinks</span>

                  <Popup
                    trigger={
                      <img src={info} onClick={() => changeTooltip()} className="tooltip-info" />
                    }
                    content={Tooltip}
                    basic
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
