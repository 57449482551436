import React from 'react'

import home from '../../../../assets/images/dashboardMobileMenu/home.svg'
import colorWheel from '../../../../assets/images/dashboardMobileMenu/color-wheel.svg'
import favorites from '../../../../assets/images/dashboardMobileMenu/favorites.svg'
import lessons from '../../../../assets/images/dashboardMobileMenu/lessons.svg'
import group from '../../../../assets/images/dashboardMobileMenu/group.svg'


function MobileNavigation({ current_lesson_id, role_access }) {

   return (
      <nav className="mobile-navigation pt-3">
         <div className="mobile-navigation__items-container">
            <a href="/dashboard/#hero" className="mobile-navigation__item">
               <img alt="home" src={home} />
               <p>Home</p>
            </a>
            <a href="/dashboard/#wheel_of_life" className="mobile-navigation__item">
               <img alt="wheel of life" src={colorWheel} />
               <p>Wheel of life</p>
            </a>
            <a href="/favorites" className="mobile-navigation__item">
               <img alt="favorites" src={favorites} />
               <p>Favorites</p>
            </a>
            {current_lesson_id && 
               <a href={`/lesson/${current_lesson_id}`} target="_blank" className="mobile-navigation__item">
                  <img alt="lesson" src={lessons} />
                  <p>My Lessons</p>
               </a>
            }
            {!!role_access && role_access.find(item => item.id === 10) && 
               <a href="/community" className="mobile-navigation__item">
                  <img alt="community" src={group} />
                  <p>Community</p>
               </a>
            }

         </div>
      </nav>
   )
}

export default MobileNavigation