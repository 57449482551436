import React, { useState } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import { Modal} from 'semantic-ui-react'
import Button from '../../bundles/community/Button'
import ReactHtmlParser from 'react-html-parser'

export default function VideoPlayer({vimeo_safe_url, title, showPlayer, setShowPlayer, destination, source_id, authToken}) {
  const [success, setSuccess] = useState(false)
  const updatedTime = (data) => {
    if (data.percent >= 0.75 && success == false) {
      let requestData = {
        source_id: source_id, 
        percent: data.percent,
      }
      fetch(destination, {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authToken,
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status > 204) {
            throw new Error('Not success')
          } else {
            setSuccess(true)
          }
        })
        .catch((error) => console.log(error))
    }
  }
  return (
    showPlayer && 
    <div className='video-wrap'
    onClick={(e)=>{setShowPlayer(false)}}>
      <div className='pop_up__video'
        onClick={(e)=>{ e.stopPropagation()}}
      >
        <Vimeo
        video={vimeo_safe_url}
        height={600}
        onTimeUpdate={(data) => updatedTime(data)}
        /> <br/>
        <h3>{ReactHtmlParser(title)}</h3> <br/>
        <Button
        handleClick={(e)=>{
          setShowPlayer(false)
        }}
        type="button"
        text="Finish"
        disabled={false}
        />
      </div>
    </div>

  )
}