import React, { useState, useEffect } from 'react'
import useResizeObserver from "use-resize-observer"
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

const Speedometer = ({ speedo_meter_percentile }) => {

  const { ref, height = 1 } = useResizeObserver()

  useEffect(() => {
    switch (height) {
      case 400:
        renderChart(86.3)
        break
      case 220:
        renderChart(71.5)
        break
    }
  }, [height])

  const renderChart = (border_bottom_radius) => {

    // Themes begin
    am4core.useTheme(am4themes_animated)
    am4core.addLicense('ch-custom-attribution')
    // Themes end

    // create chart
    var chart = am4core.create('chartdiv3', am4charts.GaugeChart)
    chart.hiddenState.properties.opacity = 0

    chart.colors.list = [
      am4core.color('#0B3653'),
      am4core.color('#296E93'),
      am4core.color('#FFDB96'),
      am4core.color('#F99268'),
      am4core.color('#E7683B'),
      am4core.color('#D23D26'),
    ]

    chart.innerRadius = -35

    var axis = chart.xAxes.push(new am4charts.ValueAxis())
    axis.min = 0
    axis.max = 100
    axis.strictMinMax = true
    axis.renderer.grid.template.strokeWidth = 0
    axis.renderer.radius = am4core.percent(100);
    axis.renderer.labels.template.fill = am4core.color('#919098')
    axis.renderer.labels.template.fontSize = 12
    axis.renderer.labels.template.fontWeight = 700

    axis.renderer.labels.template.adapter.add('rotation', function (rotation, target) {
      var value = target.dataItem.value
      var position = axis.valueToPosition(value)
      var angle = axis.renderer.positionToAngle(position) - 270
      return angle
    })
    //add border bottom
    var border_bottom = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0
    axis.max = 100
    border_bottom.renderer.labels.template.opacity = 0
    border_bottom.renderer.radius = am4core.percent(border_bottom_radius);
    border_bottom.renderer.line.strokeOpacity = 1;
    border_bottom.renderer.line.strokeWidth = 20;
    border_bottom.renderer.line.stroke = am4core.color('#C4CFD4');
    border_bottom.zIndex = -1

    var range0 = axis.axisRanges.create()
    range0.value = 0
    range0.endValue = 10
    range0.axisFill.fillOpacity = 1
    range0.axisFill.fill = chart.colors.getIndex(0)
    range0.axisFill.zIndex = -1

    var range1 = axis.axisRanges.create()
    range1.value = 10
    range1.endValue = 30
    range1.axisFill.fillOpacity = 1
    range1.axisFill.fill = chart.colors.getIndex(1)
    range1.axisFill.zIndex = -1

    var range2 = axis.axisRanges.create()
    range2.value = 30
    range2.endValue = 50
    range2.axisFill.fillOpacity = 1
    range2.axisFill.fill = chart.colors.getIndex(2)
    range2.axisFill.zIndex = -1

    var range3 = axis.axisRanges.create()
    range3.value = 50
    range3.endValue = 70
    range3.axisFill.fillOpacity = 1
    range3.axisFill.fill = chart.colors.getIndex(3)
    range3.axisFill.zIndex = -1

    var range4 = axis.axisRanges.create()
    range4.value = 70
    range4.endValue = 90
    range4.height = 15
    range4.axisFill.fillOpacity = 1
    range4.axisFill.fill = chart.colors.getIndex(4)
    range4.axisFill.zIndex = -1

    var range5 = axis.axisRanges.create()
    range5.value = 90
    range5.endValue = 100
    range5.axisFill.fillOpacity = 1
    range5.axisFill.fill = chart.colors.getIndex(5)
    range5.axisFill.zIndex = -1

    var label = chart.radarContainer.createChild(am4core.Label)
    label.isMeasured = false
    label.fontSize = 16
    label.fontWeight = 700
    label.x = am4core.percent(50)
    label.y = 10
    label.horizontalCenter = 'middle'
    label.verticalCenter = 'bottom'

    var hand = chart.hands.push(new am4charts.ClockHand())
    hand.pinRadius = 35
    hand.radius = am4core.percent(75)
    hand.innerRadius = 35
    hand.dy = -1

    hand.pin.fillOpacity = 0
    hand.pin.strokeOpacity = 1
    hand.pin.stroke = am4core.color('#000000')
    hand.pin.strokeWidth = 5
    hand.pin.radius = 32
    hand.pin.topWidth = 1
    hand.pin.bottomWidth = 15

    hand.hand.fillOpacity = 1
    hand.hand.strokeOpacity = 1
    hand.hand.stroke = am4core.color('#000000')
    hand.hand.fill = am4core.color('#000000')
    hand.hand.strokeWidth = 1
    hand.hand.height = 10


    chart.setTimeout(() => {
      hand.showValue(speedo_meter_percentile, 1000, am4core.ease.cubicOut);
    }, 1000);
    label.text = speedo_meter_percentile + '%'
  }

  return <div ref={ref} id="chartdiv3"></div>
}

export default Speedometer
