import ReactOnRails from 'react-on-rails'
import DailyAssessment from '../bundles/Homepage/components/DailyAssessment'
import LessonPage from '../bundles/LessonPage/LessonPage'
import LandingPage from '../bundles/Landing/LandingPage'
import WorkshopPage from '../bundles/WorkshopPage/WorkshopPage'
import Workshop from '../bundles/WorkshopPage/Workshop'
import DashboardPage from '../bundles/Dashboard/DashboardPage'
import SdsAssessment from '../bundles/AssessmentPage/SdsAssessment'
import SummaryPage from '../bundles/SummaryPage/SummaryPage'
import FavoritesPage from '../bundles/Favorites/FavoritesPage'
import CommunityPage from '../bundles/community/CommunityPage'
import Profile from '../bundles/Profile/Profile'
import 'bootstrap'
import $ from 'jquery'
import 'particles.js'
import 'chart.js'

window.jQuery = $
window.$ = $
ReactOnRails.register({
  DailyAssessment,
  LessonPage,
  LandingPage,
  FavoritesPage,
  SummaryPage,
  WorkshopPage,
  Workshop,
  DashboardPage,
  SdsAssessment,
  CommunityPage,
  Profile,
})
