import React from 'react'

import Coach from '../../Landing/components/Coach'
import Card from '../../../shared/components/Card'

function CardAdapter(props) {
  switch (props.resource_type) {
    case 'outer_link':
    case 'Lesson':
      return (
        <Card
          contentType={props.ratable_type}
          href={props.outer_url ? props.outer_url : `lesson/${props.id}`}
          {...props}
        />
      )
    case 'video':
      return (
        <Card
          contentType={props.ratable_type}
          video_url={props.vimeo_safe_url}
          {...props}
        />
      )
    case 'Coach':
      return <Coach {...props} />
    default:
      return (
        <Card
          contentType={props.ratable_type}
          {...props}
        />
      )
  }
}

export default CardAdapter
