export const category_list = [
   'Mental Health',
   'Cannabis/marijuana',
   'Other addiction-related problems',
   'Food & Binge Eating',
   'Sex & Porn',
   'Nicotine & Tobacco',
   'Gambling',
   'Meth',
   'Cocaine',
   'Opiates',
]
