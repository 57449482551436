import React from 'react'

import motivationPic from '../../../../assets/images/motivation-img.png'
import LinkToStart from '../../../shared/buttons/LinkToStart'

const Motivation = () => {
  return (
    <section className="motivation">
      <div className="wrapper motivation__wrapper">
        <div className="motivation__content">
          <div className="motivation__block">
            <h3 className="motivation__subtitle subtitle">Stop counting days</h3>
            <h2 className="motivation__title title">Start seeing results with IGNTD</h2>
            <p className="motivation__text">
              You don't have to wait to get help and you don't have to be perfect to get better.
              All you have to do is commit to trying your best right now! At IGNTD, we help anyone
              who is struggling by offering effective tools, delivered with ease.
            </p>
            <LinkToStart />
          </div>
          <div className="motivation__img-wrapper">
            <img src={motivationPic} alt="picture" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Motivation
