import React, {useState} from 'react'
import Lesson from './assignments/Lesson'
import Meeting from './assignments/Meeting'
import Video from './assignments/Video'
import Community from './assignments/Community'
import { colors } from '../../helpers/Colors'
import DailyCheckin from './assignments/DailyCheckin'
import ReactHtmlParser from 'react-html-parser'

const Checklist = ({current_user, assignments, setShowGroupModal, authToken}) => {
    const [currentAssignment, setCurrentAssignment] = useState(null)
    const [showAssignment, setShowAssignment] = useState(false)

    const toggleAssignment = (assignment_id) => {
        if(currentAssignment !== assignment_id){
            setCurrentAssignment(assignment_id)
            setShowAssignment(true)
        }else{
            setShowAssignment(!showAssignment)
        }
    }
return (
    assignments?.length > 0 && 
    <div className='daily_checklist'>
        <h4 className='section_header d-block'>Daily Checklist </h4>
        <div className='slim-card'>
            {assignments.map((assignment) => (
                <div 
                className={`assignment ${assignment.status}`}
                key={assignment.id}
                > 
                <div className='title'
                    onClick={()=>toggleAssignment(assignment.id)}>
                    <span className={`assign_label ${assignment.status}`}>
                        {assignment.status === "complete" ?
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                            <path d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/>
                        </svg>

                        }
                    </span>
                    <span className={`toggler ${(assignment.id === currentAssignment && showAssignment) }`}>
                        {(assignment.id === currentAssignment && showAssignment) ? 
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" >
                              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" >
                                <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/>
                            </svg>
                        }
                    </span>
                    <h5>
                        {(assignment.assignable_type ===  "SdsGroup" || assignment.assignable_type ===  "SdsGroupEvent") &&
                                <>{assignment.due_today ?  `Attend  ` :  `Book `} </>
                         }

                        {(assignment.assignable_type ==  "Lesson" || assignment.assignable_type ==  "Workshop") && 
                            `${assignment.assignable_type}: `}

                        {assignment.assignable_type ==  "Video" && `Video: ` }
                
                        {ReactHtmlParser(assignment.title)}</h5>
                </div>

                    {currentAssignment == assignment.id && showAssignment &&
                    <div>
                        {assignment.assignable_type ==  "DailyCheckin" &&
                            <DailyCheckin assignment={assignment}/>
                        }
                        {(assignment.assignable_type ==  "Lesson" || assignment.assignable_type ==  "Workshop") &&  
                            <Lesson assignment={assignment}/>
                        }
                        {assignment.assignable_type ==  "CommPost" &&
                            <Community assignment={assignment}/>
                        }
                        {(assignment.assignable_type ==  "SdsGroup" || assignment.assignable_type ==  "SdsGroupEvent") &&
                            <Meeting
                            assignment={assignment}
                            setShowGroupModal = {setShowGroupModal}
                            />
                        }
                        {assignment.assignable_type ==  "Video" && 
                            <Video
                            assignment={assignment}
                            authToken={authToken}
                            />
                        }
                    </div>
                    }
                </div>
            ))}
        </div>
    </div>

)}


export default Checklist