import React from 'react'
import { Bar } from 'react-chartjs-2'

import calendar from '../../../../assets/images/calendar.svg'

function DailyScores({ 
   start_week_day, end_week_day, weeks_daily_scores, 
   b2b_user, days_counter_amount, streak_score,
   setShowSession, role_access
 }) {

   const data = {
      labels: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      datasets: [{
         label: 'Daily Score',
         data: weeks_daily_scores,
         backgroundColor: '#296E93'
      }]
   }

   const options = {
      scales: {
         yAxes: [{
            ticks: {
               beginAtZero: true,
               fontColor: '#b7b7b7',
               fontSize: 12,
               fontStyle: "bold",
               fontFamily: 'henderson-sans-basic, sans-serif',
               min: 0,
               max: 100,
               stepSize: 20,
            },
            gridLines: {
               drawBorder: false
            }
         }],
         xAxes: [{
            gridLines: {
               display: false
            },
            ticks: {
               fontColor: '#b7b7b7',
               fontSize: 12,
               fontStyle: "bold",
               fontFamily: 'henderson-sans-basic, sans-serif',
            }
         }]
      },
      plugins: {
         legend: {
            display: false
         },
         datalabels: {
            anchor: 'end',
            align: 'end',
            color: '#000',
            font: {
               weight: 'bold'
            },
            formatter: Math.round
         }
      }
   }

   return (
      <div className="card__half daily_scores" >
         <div className="score-header">
            <h4>Daily Scores</h4>
            <div className="score-dates float-right">
               <span >
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <g clipPath="url(#clip0_27_230)">
                     <path fillRule="evenodd" clipRule="evenodd" d="M1.4873 10.4403C1.4873 11.5172 2.36035 12.3903 3.4373 12.3903H10.1736C11.2506 12.3903 12.1236 11.5172 12.1236 10.4403V3.70391C12.1236 2.62695 11.2506 1.75391 10.1736 1.75391H3.4373C2.36035 1.75391 1.4873 2.62695 1.4873 3.70391V10.4403Z" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path d="M1.56055 4.34399H12.0506" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path d="M4.05664 0.650024V2.4126" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path d="M9.55371 0.650024V2.4126" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path fillRule="evenodd" clipRule="evenodd" d="M3.25977 6.87194C3.25977 7.19824 3.52432 7.46279 3.85067 7.46279C4.17703 7.46279 4.44158 7.19824 4.44158 6.87194C4.44158 6.54557 4.17703 6.28101 3.85067 6.28101C3.52432 6.28101 3.25977 6.54557 3.25977 6.87194Z" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path fillRule="evenodd" clipRule="evenodd" d="M6.21484 6.87194C6.21484 7.19824 6.47941 7.46279 6.80575 7.46279C7.1321 7.46279 7.39663 7.19824 7.39663 6.87194C7.39663 6.54557 7.1321 6.28101 6.80575 6.28101C6.47941 6.28101 6.21484 6.54557 6.21484 6.87194Z" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path fillRule="evenodd" clipRule="evenodd" d="M9.16895 6.87194C9.16895 7.19824 9.4335 7.46279 9.75986 7.46279C10.0862 7.46279 10.3508 7.19824 10.3508 6.87194C10.3508 6.54557 10.0862 6.28101 9.75986 6.28101C9.4335 6.28101 9.16895 6.54557 9.16895 6.87194Z" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path fillRule="evenodd" clipRule="evenodd" d="M3.25977 9.82614C3.25977 10.1525 3.52432 10.4171 3.85067 10.4171C4.17703 10.4171 4.44158 10.1525 4.44158 9.82614C4.44158 9.49978 4.17703 9.23523 3.85067 9.23523C3.52432 9.23523 3.25977 9.49978 3.25977 9.82614Z" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     <path fillRule="evenodd" clipRule="evenodd" d="M6.21484 9.82614C6.21484 10.1525 6.47941 10.4171 6.80575 10.4171C7.1321 10.4171 7.39663 10.1525 7.39663 9.82614C7.39663 9.49978 7.1321 9.23523 6.80575 9.23523C6.47941 9.23523 6.21484 9.49978 6.21484 9.82614Z" stroke="#3D6D90" strokeLinecap="round" strokeLinejoin="round"/>
                     </g>
                     <defs>
                     <clipPath id="clip0_27_230">
                     <rect width="13" height="13" fill="white" transform="translate(0.1875)"/>
                     </clipPath>
                     </defs>
                  </svg>&nbsp;&nbsp;
                  {start_week_day.month.substr(0, 3)} {start_week_day.day} - {end_week_day.day}</span>
            </div>
         </div>
         <div className="streak_score">
            <svg width="22" height="22" viewBox="0 3 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_27_247)">
               <path d="M2.27539 8.27588C2.27539 10.3327 3.94276 12 5.99954 12C8.05632 12 9.72369 10.3327 9.72369 8.27588C9.72369 6.20691 8.6892 4.55173 9.38328 3.10345C9.38328 3.10345 8.27539 3.31036 7.65472 4.96552C7.65472 4.96552 5.58575 2.89655 6.45017 0C6.45017 0 4.34438 0.827578 4.5646 3.51724C4.75346 5.82363 4.34438 6.20691 4.34438 6.20691C4.34438 4.34484 2.88856 3.51724 2.88856 3.51724C3.30988 5.79312 2.27539 6.41381 2.27539 8.27588Z" fill="#FA5608"/>
               <path d="M8.6882 9.15783C8.6882 10.7069 7.49211 12.0293 5.94334 11.9994C4.48581 11.9713 3.40138 10.8624 3.51728 9.41376C3.569 8.76722 4.06037 7.7198 4.8621 6.65946L5.39659 6.98273C5.37932 5.67241 6.00001 3.93103 6.00001 3.93103C6.40773 5.76579 8.6882 6.24136 8.6882 9.15783Z" fill="#FC8F10"/>
               <path d="M4.96542 7.86209C4.75852 9.10348 4.34473 9.55543 4.34473 10.3449C4.34473 11.1725 5.0858 12 5.99991 12C6.92062 12 7.65762 11.2478 7.65506 10.327C7.64815 7.8542 6.20681 6.62073 6.20681 6.62073C6.41372 7.86212 5.79302 8.48279 5.79302 8.48279C5.53446 8.09499 4.96542 7.86209 4.96542 7.86209Z" fill="#FBC743"/>
               </g>
               <defs>
               <clipPath id="clip0_27_247">
               <rect width="12" height="12" fill="white"/>
               </clipPath>
               </defs>
            </svg>
            <span style={{color: '#FA5608'}}>{ days_counter_amount }-DAY STREAK! </span> 
            <span style={{color: '#3D6D90'}}>X{ streak_score }</span>
         </div>
         <div className="daily-scores__chart-container">
            <div className="w-100">
               <Bar data={data} options={options} />
            </div>
         </div>
         {role_access.find(item => item.id == 11)?.content &&
            <div className="card__button">
               <button type="button" className="btn_card" onClick={()=>setShowSession(true)}>
                  <span aria-hidden="true"> Book a Coaching Session</span>
               </button>
            </div>
         }
      </div >
   )
}

export default DailyScores