import React, { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'

import EmotionSlider from '../../../../shared/components/emotionSlider/EmotionSlider'
import CustomRating from '../../../../shared/buttons/CustomRating'

import { emotionsSwiperBreakpoints } from '../../../../helpers/swiperBreakpoints'

function RatingModal(props) {
  const [ratingValue, setRatingValue] = useState(5)
  const [impactTextArea, setImpactTextArea] = useState('')
  const [open, setOpen] = useState(true)
  const [positiveEmotions, setPositiveEmotions] = useState([])
  const [negativeEmotions, setNegativeEmotions] = useState([])
  const [selectedPositive, setPositive] = useState(null)
  const [selectedNegative, setNegative] = useState(null)
  const [flag, setFlag] = useState(false)

  const handleRate = (e, { rating }) => {
    setRatingValue(rating)
  }

  const handleTextAreaChange = (e) => {
    setImpactTextArea(e.target.value)
  }

  const handleModalClose = () => {
    setOpen(false)
    props.closeModal()
  }

  function makeActive(emotionId, emotion) {
    if (emotion == 'positive') {
      setPositive(emotionId)
    } else {
      setNegative(emotionId)
    }
  }

  const handleFormSubmit = () => {
    fetch(`/lesson/${props.currentLesson.id}/summary`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
      body: JSON.stringify({
        lesson_rating: {
          rating: ratingValue,
          impact: impactTextArea,
          emotion_id: [selectedPositive, selectedNegative],
          helpful: ''
        },
      }),
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        }
        handleModalClose()
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (flag) return
    fetch('/rate-emotions', {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        }
        return response.json()
      })
      .then((data) => {
        setFlag(true)
        setNegativeEmotions(data.emotions.filter(emotion => emotion.is_negative === true))
        setPositiveEmotions(data.emotions.filter(emotion => emotion.is_negative === false))
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <Modal open={open} onClose={() => handleModalClose()} onOpen={() => setOpen(true)} className="full-screen-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">Share Your Thoughts</h3>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleModalClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Please share your thoughts about the {props.type}. Your answers will help us improve your
            experience.
          </p>
          <div className="lesson-ratings">
            <div className="field">
              <h5>How would you rate this {props.type} from 1 (worst) to 5 (best)?</h5>
              <br />
              <CustomRating average_rating={5} disabled={false} onRate={handleRate} />
            </div>
          </div>

          <div className="field">
            <h5>What Impact did this {props.type} have on you?</h5>
            <br />
            <textarea
              onChange={handleTextAreaChange}
              rows={4}
              placeholder="Type here"
              className="text-area"
            />
          </div>

          <div className="field">
            <div className="split-label">
              <p className="daily_assesment_emotions_title">
                What <span>negative</span>emotion are you struggling with the most today?*
              </p>

              <h6 className="text-link">
                <a href="mailto:info@igntd.com"> E-mail us to add an emotion </a>
              </h6>
            </div>
            <p className="prompt">Pick one emotion for each question</p>
            <EmotionSlider
              emotionsArray={negativeEmotions}
              emotionType={'negative'}
              chosenEmotion={selectedNegative}
              makeActive={makeActive}
              breakpoints={emotionsSwiperBreakpoints}
            />

            <h6 className="text-link mobile">
              <a href="mailto:info@igntd.com">E-mail us to add an emotion </a>
            </h6>
          </div>
          <div className="field slider-wrapper" id="positive-emotion">
            <div className="split-label">
              <p className="daily_assesment_emotions_title">
                What <span>positive</span> emotion are you feeling most strongly today?*
              </p>
              <h6 className="text-link">
                <a href="mailto:info@igntd.com"> E-mail us to add an emotion</a>
              </h6>
            </div>
            <p className="prompt">Pick one emotion for each question</p>
            <EmotionSlider
              emotionsArray={positiveEmotions}
              chosenEmotion={selectedPositive}
              makeActive={makeActive}
              emotionType={'positive'}
              breakpoints={emotionsSwiperBreakpoints}
            />

            <h6 className="text-link mobile">
              <a href="mailto:info@igntd.com">E-mail us to add an emotion </a>
            </h6>
          </div>
          <div className="actions">
            <button
              onClick={() => handleFormSubmit()}
              className="btn btn_primary"
              disabled={!selectedNegative || !selectedPositive || !impactTextArea.length}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RatingModal
