import React from 'react'

const LinkToStart = ({ text = "Yes! I'm Ready to Break Free!", link = "https://www.igntdrecovery.com/change-your-life-var-21-5-daily" }) => {
  return (
    <a
      target='_blank'
      href={link}
      onClick={() => { }}
      className="link-tostart">
      {text}
    </a>
  )
}

export default LinkToStart
