import React, { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import EmotionSlider from '../../../shared/components/emotionSlider/EmotionSlider'
import RangeInput from './RangeInput'
import AfformationTexarea from './AfformationTexarea'
import UsedAddictions from './UsedAddictions'

import { emotionsSwiperMiniBreakpoints } from '../../../helpers/swiperBreakpoints'

const DailyAssessment = (props) => {
  const [userData, setUserData] = useState({
    score: 50,
    emotion_ids: [],
    i_am: '',
    tracking_used: '',
    tracking_status: '',
    tracking_amount: '',
  })
  const [open, setOpen] = useState(true)
  const [rangeSliderData, setRangeSliderData] = useState(50)
  const [selectedPositive, setPositive] = useState(null)
  const [selectedNegative, setNegative] = useState(null)
  const [textareaData, setTextareData] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [activeQuestion, setActiveQuestion] = useState(0)

  const formDataBySteps = {
    0: rangeSliderData,
    1: isValid,
    2: selectedNegative,
    3: selectedPositive,
    4: textareaData
  }
  const disableNextBtn = formDataBySteps[activeQuestion] ? false : true

  const history = useHistory()

  useEffect(() => {
    if (!props.showDaily) {
      history.push('/')
    }
  }, [])

  //logic for handling info about drinking or not
  function handleDrinksQuantity(e) {
    setUserData({ ...userData, ['tracking_amount']: e.target.value })
    if (e.target.value.length > 0 && e.target.value > 0) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  function handleDrinkingBtn(answer) {
    if (!answer) {
      setUserData({
        ...userData,
        ['tracking_used']: answer,
        ['tracking_status']: '',
        ['tracking_amount']: 0,
      })
      setIsValid(true)
    } else {
      setUserData({ ...userData, ['tracking_used']: answer })
      setIsValid(false)
    }
  }

  function handleQuantity(text) {
    setUserData({
      ...userData,
      ['tracking_status']: text,
    })
    if (userData.tracking_amount === 0 || userData.tracking_amount === '') {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }
  //end of logic for handling info about drinking or not

  function makeActive(emotionId, emotion) {
    if (emotion == 'positive') {
      setPositive(emotionId)
    } else {
      setNegative(emotionId)
    }
  }

  useEffect(() => {
    let new_ids = []
    if (selectedPositive !== null) new_ids.push(selectedPositive)
    if (selectedNegative !== null) new_ids.push(selectedNegative)
    setUserData({ ...userData, emotion_ids: new_ids })
  }, [selectedPositive, selectedNegative])

  function handleChange(e) {
    e.preventDefault()
    const val = e.target.value
    setUserData({ ...userData, score: val })
    setRangeSliderData(val)
  }

  const submitForm = (e, data) => {
    fetch('/daily-assessment', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (response.status > 302) {
          throw new Error('Not success')
        }
        return response.json()
      })
      .then((data) => {
        history.push(data.redirect_path)
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
        history.push("/showRecommendations")
        window.location.reload()
      })
  }

  function handleTextarea(e) {
    const val = e.target.value
    setUserData({ ...userData, i_am: val })
    setTextareData(val)
  }

  function onNextQuestionClick(e) {
    e.preventDefault()
    const newActiveQuestionNumber = activeQuestion + 1
    if (newActiveQuestionNumber >= 5) {
      submitForm()
      return
    }
    setActiveQuestion(newActiveQuestionNumber)
  }

  function getQuestion() {
    switch (activeQuestion) {
      case 0:
        return (
          <RangeInput onChange={handleChange} value={rangeSliderData} />
        )
      case 1:
        return (
          <UsedAddictions
            drinkOrNot={userData.tracking_used}
            specificQuantity={userData.tracking_status}
            tracking_amount={userData.tracking_amount}
            handleQuantity={handleQuantity}
            handleDrinksQuantity={handleDrinksQuantity}
            handleDrinkingBtn={handleDrinkingBtn}
            currentUser={props.current_user}
          />
        )
      case 2:
        return (
          <div className="field">
            <div className="split-label">
              <p className="daily_assesment_emotions_title">
                What <span>negative</span> emotion are you struggling with the most today?*
              </p>
            </div>
            <p className="text-center">Pick one emotion for each question</p>
            <EmotionSlider
              emotionsArray={props.negative_emotions}
              emotionType={'negative'}
              chosenEmotion={selectedNegative}
              makeActive={makeActive}
            />
            <h6 className="text-link">
              <a href="mailto:info@igntd.com">E-mail us to add an emotion</a>
            </h6>
          </div>
        )
      case 3:
        return (
          <div className="field slider-wrapper" id="positive-emotion">
            <div className="split-label">
              <p className="daily_assesment_emotions_title">
                What <span>positive</span> emotion are you feeling most strongly today?*
              </p>
            </div>
            <p className="text-center">Pick one emotion for each question</p>
            <EmotionSlider
              emotionsArray={props.positive_emotions}
              chosenEmotion={selectedPositive}
              makeActive={makeActive}
              emotionType={'positive'}
              breakpoints={emotionsSwiperMiniBreakpoints}
            />
            <h6 className="text-link">
              <a href="mailto:info@igntd.com"> E-mail us to add an emotion</a>
            </h6>
          </div>
        )
      case 4:
        return (
          <AfformationTexarea onChange={handleTextarea} value={textareaData} />
        )
      default:
        return (
          <></>
        )
    }
  }

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <form className="introform">
        <div className="introform__wrapper-daily">
          <h2 className="introform__title">Hello {props.current_user && props.current_user.first_name}</h2>
          {
            activeQuestion === 0 &&
            <p className="sub-header">
              Please share your current feelings and struggles. Your answers will help us improve
              your experience.
            </p>
          }
          <div className='field__container-daily'>
            {
              getQuestion()
            }
          </div>
          <div className="introform__button-container introform__button-container-daily">
            <button
              className={`introform__button ${disableNextBtn ? '' : 'active'}`}
              type="submit"
              onClick={onNextQuestionClick}
            >Next</button>
          </div>
        </div>
      </form >
    </Modal>
  )
}

export default DailyAssessment
