import React from 'react'

import getSect from '../../../../assets/images/get-sect.png'
import GetCard from '../components/GetCard'
import LinkToStart from '../../../shared/buttons/LinkToStart'

const GetSect = () => {
  return (
    <section className="get-section">
      <div className="get-section__wrapper wrapper">
        <h2 className="title get-section__title">What You Will Get</h2>
        <div className="get-section__content">
          <div className="get-section__col">
            <GetCard title={"Track Your Progress"} description={'Watch your improvement and goal accomplishment along your recovery journey, all on our easy-to-use dashboard. You get both daily and monthly assessments, plus a 360 degree assessment package!'} />
            <GetCard title={"No-Quit & No Shame"} description={'We focus on the root-cause of addiction and healing misaligned habits, instead of shaming people for their past. Choose a recovery program that matches who you are, instead of who someone else tells you to be.'} />
          </div>
          <div className="get-section__col">
            <div className="get-section__img-wrapper">
              <img src={getSect} alt="picture" />
            </div>
            <GetCard title={"Accountability Coaching"} description={'Daily and monthly assessments along with live accountability coaches work one-on-one to keep you on track for success and push you towards achieving progress and results.'} />
          </div>
          <div className="get-section__col">
            <GetCard title={'Virtual Live Groups'} description={'Connect with our IGNTD community during our daily live support groups. Sort out challenges, discuss questions, and support fellow Heroes on a schedule that works for you, any day of the week.'} />
            <GetCard title={'Available 24/7'} description={'Access the platform immediately upon sign up and move through the content at your convenience. It’s recovery that’s customized to your needs, your schedule, and your lifestyle.'} />
          </div>
        </div>
        <div className="get-section__link-block">
          <LinkToStart />
        </div>
      </div>
    </section>
  )
}

export default GetSect
