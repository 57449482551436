import React, {useState, useCallback, useRef, useEffect} from 'react'
import { Modal} from 'semantic-ui-react'
import Button from './Button'
import ReactCrop from 'react-image-crop'
import Loader from './Loader'


const AvatarUploader = ({setEditAvatar, authToken, setUserAvatar}) => {
    
    const [upImg, setUpImg] = useState();

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
  
    const [crop, setCrop] = useState({ unit: 'px', width: 200, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [loading, setLoading] = useState(false)

  
    // on selecting file we set load the image on to cropper
    const onSelectFile = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
    };
  
    const onLoad = useCallback((img) => {
      imgRef.current = img;
    }, []);
  
    useEffect(() => {
      setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    }, [completedCrop]);

    const setCanvasImage = (image, canvas, crop) =>{
        if (!crop || !canvas || !image) {
            return;
        }
        
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
        const pixelRatio = window.devicePixelRatio;
        
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        
        // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        
        // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }

    const uploadFiles = (canvas, crop)=>{
        
      if (!crop || !canvas) {
          return;
        }
      setLoading(true)
      
      canvas.toBlob(
        (blob) => {
          let croppedFile = new File([blob], 'test.png', { type: 'image/png' })

          const formData = new FormData()
          formData.append("avatar", croppedFile)
      
          fetch('/user/avatar', {
            credentials: 'same-origin',
            method: "POST",
            headers: {
                'X-CSRF-Token': authToken,
            }, 
            body: formData,
          }).then(response => response.json())
              .then(({status, avatar}) => {
                if (status === 201) {
                  setUserAvatar(avatar)
                  setEditAvatar(false)
                  setLoading(false)
      
                }
      
              })
              .catch(err => {
                console.log(err)
              })
        },
        'image/png',
        1
      );
 
    }

  return (
    <Modal
    centered={true}
    open={true}
    tabIndex="-1"
    role="dialog"
    size="small"
    dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }}
    className='avatar-uploader'
  >
    <>
    
    <Modal.Content>
      <Modal.Description >
        <div className='head'>
        <h5>Add a profile picture</h5>
        <button onClick={()=>  setEditAvatar(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span className="closing_cross" aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className='text-center p-2'>
          <label className="custom-file-upload py-3">
              <input type="file"
                onChange={onSelectFile}
                accept="image/*"
              />
              Select a Photo
          </label>
        </div>

        <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      />
      <div className='avatar-preview'>
        {/* Canvas to display cropped image */}
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div> 
      {loading ?
          <div className='loading'>
              <Loader
                  type="circle"
              />
          </div>
        :
          <Button
          text='Save'
          type='button'
          classes='py-4'
          disabled={!completedCrop?.width || !completedCrop?.height}
          handleClick={() =>
          uploadFiles(previewCanvasRef.current, completedCrop)
          }
          />
      }
      </Modal.Description>
    </Modal.Content>
    </>
  </Modal>

  )
}

export default AvatarUploader