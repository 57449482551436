import React, { useEffect } from 'react'

import LeftArrow from '../../../../assets/images/arrow_navy_blue_left.svg'
import RightArrow from '../../../../assets/images/arrow-right.svg'
import InactiveLeftArrow from '../../../../assets/images/arrow-left.svg'
import InactiveRightArrow from '../../../../assets/images/arrow_grey_right.svg'

function ExercisesSwitch({ currentIndex, exercisesAmount, completedEmount, onPrevClick, onNextClick, type = ' ' }) {

   return (
      <>
         <div className="prev_next_buttons_container">
            <button className={currentIndex === 1 ? "btn_secondary btn_secondary--grey" : "btn_secondary"} onClick={onPrevClick}>
               <img className="btn_secondary-img" src={currentIndex === 1 ? InactiveLeftArrow : LeftArrow} alt="" />Prev {type}
            </button>
            <h4>{currentIndex} / {exercisesAmount}</h4>
            <button className={currentIndex === exercisesAmount ? "btn_secondary btn_secondary--grey" : "btn_secondary"} onClick={onNextClick}>
               Next {type}<img className="btn_secondary-img next" src={currentIndex === exercisesAmount ? InactiveRightArrow : RightArrow} alt="" />
            </button>
         </div>
         <span className="exercise_counter_text elem_to_center">completed {completedEmount} out of {exercisesAmount} {type.toLowerCase() + 's'}</span>
      </>
   )
}

export default ExercisesSwitch