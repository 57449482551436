import React from 'react'

const Button = ({classes, disabled=true, text, type, handleClick}) => {
  return (
    <div className={`general-btn ${classes}`}>
        <button
        className='btn-component'
        type={type}
        disabled={disabled}
        onClick={handleClick}
        >{text}</button>
    </div>
  )
}

export default Button