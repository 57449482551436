import React, { useState } from 'react'

import AddictionalColorMeanings from './AddictionalColorMeanings'
import AddictionalDayChart from './AddictionalDayChart'
import AddictionalWeekChart from './AddictionalWeekChart'
import AddictionalMonthChart from './AddictionalMonthChart'
import ChapterSwitcher from '../../../../shared/components/chapterSwitcher/ChapterSwitcher'

import { chapterButtons, dailyAndWeeklyColors, mounthlyColors } from './helper'
import { capitalizeFirstLetter } from '../../../../helpers/helperFunctions'

function AddictionalProgress({ addiction_progress, current_user, setShowSession, role_access }) {

   const [activeChart, setActiveChart] = useState('daily')

   function renderActiveChart() {
      switch (activeChart) {
         case 'daily':
            return (
               <>
                  <AddictionalDayChart
                     addiction_progress_daily={addiction_progress.daily}
                  />
                  <AddictionalColorMeanings
                     colors={dailyAndWeeklyColors}
                  />
               </>
            )
         case 'weekly':
            return (
               <>
                  <AddictionalWeekChart
                     addiction_progress_weekly={addiction_progress.weekly}
                     average_usage={addiction_progress.average_usage}
                     current_user={current_user}
                  />
                  <AddictionalColorMeanings
                     colors={dailyAndWeeklyColors}
                  />
               </>
            )
         case 'monthly':
            return (
               <>
                  <AddictionalMonthChart
                     addiction_progress_monthly={addiction_progress.monthly}
                     average_usage={addiction_progress.average_usage}
                  />
                  <AddictionalColorMeanings
                     colors={mounthlyColors}
                  />
               </>
            )
      }
   }

   function getTitle() {
      const addictionPortion = current_user.addiction === "alcohol" ? 'Drinks' : 'Use'
      const chartName = capitalizeFirstLetter(activeChart)
      return `${chartName} ${addictionPortion}`
   }

   const title = getTitle()

   return (
      <div className="card__half addiction_progress" >
         <h4>{title}</h4>
         {/* <p>{ReactHtmlParser(subtitle || " ")}</p> */}
         <div className="flex_to_center">
            <div className="dropdown show">
               <a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {capitalizeFirstLetter(activeChart)}
               </a>

               <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <ChapterSwitcher
                     buttons={chapterButtons}
                     activeChapter={activeChart}
                     handleActiveChapterChange={setActiveChart}
                     dropdown={false}
                  />
               </div>
            </div>

         </div>
         {
            renderActiveChart()
         }
         {role_access.find(item => item.id == 11)?.content &&
            <div className="card__button book-session">
               <button type="button" className="btn_card" onClick={() => setShowSession(true)}>
                  <span aria-hidden="true">Contact My Coach</span>
               </button>
            </div>
         }
      </div >
   )
}

export default AddictionalProgress