import React from 'react'

function Applied({ applied }) {

   if (Array.isArray(applied)) {
      return (
         <ol className="applied">
            {
               applied.map((apply_text, index) =>
                  <li key={index} className="applied__item">{index}. {apply_text}</li>
               )
            }
         </ol>
      )
   } else {
      return (
         <p className="applied">
            {applied}
         </p>
      )
   }
}

export default Applied