import React, { useState, useEffect, createContext } from 'react'
import Truncate from 'react-truncate';
import ReactHtmlParser from 'react-html-parser'
import UserAvatar from 'react-user-avatar'
import PostView from './PostView';

var hdate = require('human-date')

const Notifications = ({ notifications, current_user, mentions, authToken, reactions }) => {
    const colors = ['#f4dcb0', '#98b6ca', '#cf9494', '#a6d2a9', '#b1e2ff', '#ebbc44']
    const [notificationsView, setNotificationsView] = useState(false)

    const [currentNotification, setCurrentNotification] = useState(null)

    const navigateNotification = (notification) => {
        setNotificationsView(true)
        setCurrentNotification(notification)
    }


    return (
        notificationsView ?
            <PostView
                authToken={authToken}
                reactions={reactions}
                current_user={current_user}
                currentNotification={currentNotification}
                setNotificationsView={setNotificationsView}
            />
            :
            <div className='notifications'>
                {
                    mentions.map((notification) => (
                        notification?.triggers[0]?.content &&
                        <div
                            className={`row notification-item ${notification.read ? 'read' : 'unread'}`}
                            key={notification.id}
                            onClick={() => navigateNotification(notification)}
                        >
                            <div className='col-2 col-md-1'>
                                <div className="user-avatar" key={notification.id}>
                                    {notification.user?.avatar_url ?
                                        <UserAvatar size="30"
                                            name={notification.user.user_handle}
                                            src={notification.user.avatar_url}
                                        />
                                        :
                                        <UserAvatar size="30" name={notification.user.user_handle}
                                            colors={colors}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='col-10 col-md-11'>
                                <div className="user_details">
                                    <h6 className="name mb-0"> {notification.user.user_handle}
                                        <span className="posted_at small float-right"> {hdate.relativeTime(notification.created_at)}</span>
                                    </h6>
                                    <div className='replying'> {notification.user.user_handle} mentioned you&nbsp;
                                        {notification.triggered_by == "CommPost" ?
                                            <span>in a post</span>
                                            :
                                            current_user.id != notification.replying_to.id &&
                                            <span>in a reply to&nbsp;
                                                <span className='bolded'>@{notification.replying_to.user_handle}</span></span>
                                        }

                                    </div>

                                </div>
                                <Truncate className='truncated' lines={3} ellipsis={"..."}>
                                    {ReactHtmlParser(notification.triggers[0].content)}
                                </Truncate>
                            </div>
                        </div>
                    ))
                }
                {
                    Object.keys(notifications).map((notifications_group) => {
                        let notification_list = notifications[notifications_group]
                        return (

                            notification_list[0].triggered_by == "Comment" ?
                                notification_list.map((notification) => (
                                    notification?.triggers[0]?.content &&
                                    <div
                                        className={`row notification-item ${notification.read ? 'read' : 'unread'}`}
                                        key={notification.id}
                                        onClick={() => navigateNotification(notification)}
                                    >
                                        <div className='col-2 col-md-1'>
                                            <div className="user-avatar" key={notification.id}>
                                                {notification.user.avatar_url ?
                                                    <UserAvatar size="30"
                                                        name={notification.user.user_handle}
                                                        src={notification.user.avatar_url}
                                                    />
                                                    :
                                                    <UserAvatar size="30" name={notification.user.user_handle}
                                                        colors={colors}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className='col-10 col-md-11'>
                                            <div className="user_details">
                                                <h6 className="name mb-0"> {notification.user.user_handle}
                                                    <span className="posted_at small float-right"> {hdate.relativeTime(notification.created_at)}</span>
                                                </h6>
                                                <div className='replying'>Replying to <span>You</span></div>

                                            </div>
                                            <Truncate className='truncated' lines={3} ellipsis={"..."}>
                                                {ReactHtmlParser(notification.triggers[0]?.content)}
                                            </Truncate>
                                        </div>
                                    </div>
                                ))

                                :
                                notification_list?.length == 1 ?
                                    notification_list.map((notification) => (
                                        notification?.notifiable?.content &&
                                        <div
                                            className={`row notification-item ${notification.read ? 'read' : 'unread'}`}
                                            key={notification.id}
                                            onClick={() => navigateNotification(notification)}
                                        >
                                            <div className='col-2 col-md-1'>
                                                <svg className='is_reaction' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                    <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                                                </svg>
                                            </div>
                                            <div className='col-10 col-md-11'>
                                                <div className='reactors'>
                                                    <div className="user-avatar">
                                                        {notification.user.avatar_url ?
                                                            <UserAvatar size="30"
                                                                name={notification.user.user_handle}
                                                                src={notification.user.avatar_url}
                                                            />
                                                            :
                                                            <UserAvatar size="30" name={notification.user.user_handle}
                                                                colors={colors}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='reactions-list'>
                                                    <span className='bolded'>{notification.user.user_handle}</span>&nbsp;
                                                    reacted to your {notification.notifiable_type == "Comment" ? 'Reply' : 'Post'}
                                                    <span className="posted_at small float-right"> {hdate.relativeTime(notification.created_at)}</span>
                                                </div>
                                                <Truncate className='truncated' lines={1} ellipsis={"..."}>
                                                    {ReactHtmlParser(notification.notifiable.content)}
                                                </Truncate>
                                                <div></div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div
                                        className='row notification-item'
                                        onClick={() => navigateNotification(notification_list[0])}
                                    >
                                        <div className='col-2 col-md-1'>
                                            <svg className='is_reaction' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
                                            </svg>
                                        </div>
                                        <div className='col-10 col-md-11'>
                                            <div className='reactors list-reactors'>
                                                {notification_list.map((notification) => (
                                                    notification_list[0]?.notifiable?.content &&
                                                    <div
                                                        className="user-avatar"
                                                        key={notification.id}
                                                    >
                                                        {notification.user.avatar_url ?
                                                            <UserAvatar size="30"
                                                                name={notification.user.user_handle}
                                                                src={notification.user.avatar_url}
                                                            />
                                                            :
                                                            <UserAvatar size="30" name={notification.user.user_handle}
                                                                colors={colors}
                                                            />
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='reactions-list'>
                                                You have <span>{notification_list?.length}</span> new reactions to your&nbsp;
                                                {notification_list[0].notifiable_type == "Comment" ? 'Reply' : 'Post'}
                                                <span className="posted_at small float-right"> {hdate.relativeTime(notification_list[0].created_at)}</span>
                                            </div>
                                            <Truncate className='truncated' lines={1} ellipsis={"..."}>
                                                {ReactHtmlParser(notification_list[0].notifiable.content)}
                                            </Truncate>
                                        </div>
                                    </div>
                        )
                    })
                }

            </div>
    )
}

export default Notifications