import React from 'react'

import Label from './Label'

function Range({ value, onChangeRange, name, label_text, max = 100, min = 0, amountFlag, forLanding }) {
  return (
    <>
      {
        label_text &&
        <Label htmlFor={name} label_text={label_text} />
      }
      <div className="field__input-container">
        <input
          type="range"
          value={value === null ? 0 : value}
          onChange={onChangeRange}
          name={name}
          id={name}
          min={0}
          max={max}
        />
        {
          forLanding &&
          <output id="sliderOutput1">{value === null ? 0 : value}</output>
        }
      </div>
      {
        forLanding ?
          null :
          amountFlag ? (
            <output id="sliderOutput1">
              {value === null ? 0 : value}/{max}
            </output>
          ) : (
            <output id="sliderOutput1">{value === null ? 0 : value}</output>
          )}
    </>
  )
}

export default Range