import React, {useContext, useState } from 'react'
import BackIcon from '../../../assets/images/back_icon.svg'
import FlashMessage from 'react-flash-message'
import { upcomingContext } from '../../bundles/Dashboard/Dashboard'
import { formContext } from '../../bundles/Dashboard/modals/GroupMeetingModal'

function GroupForm ({group_id, zoom_email, first_name, last_name , authToken }) {
    const [zoomEmail, setZoomEmail]   = useState(zoom_email)
    const [firstName, setFirstName]   = useState(first_name)
    const [lastName, setLastName]     = useState(last_name)
    const [recurring, setRecurring]   = useState(false)

  const [activeCredits, setActiveCredits, setShowGroups, groupsData, setGroupsData] = useContext(formContext)
  const [ upcoming, setUpcoming] = useContext(upcomingContext)
  
    const [disablePost, setDisablePost] = useState(false)

    let selectedGroup =  groupsData.find(x =>  x.id == group_id)

    const [msg, setMsg] = useState(null)
    const [showMessage, setShowMessage] = useState(false)

    const handleMessage = (msg) => {
      setMsg(msg)
      setShowMessage(true)
    }


    const sendForm = (e) => {
      e.preventDefault()
      setShowMessage(false)

      const data = JSON.stringify({
        email: zoomEmail,
        first_name: firstName,
        last_name: lastName,
        sds_group_id: group_id,
        recurring: recurring,
    })
    
      fetch("/register_attendee", {
        credentials: 'same-origin',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-Token': authToken,
        },
        body: data
     }).then(response => response.json())
        .then(({status, errors, meetings_info}) => {
          if (status === 200) {
            handleMessage(<span className='text-success'>Meeting Booked successfully!</span>)
            
            setDisablePost(true)
            setActiveCredits(activeCredits==1? 0 : activeCredits-1)
            setTimeout(()=> {
              setGroupsData(meetings_info[0])
              setUpcoming(meetings_info[1])
              setShowGroups(true)
            }, 
             2000
            );

          }else{
            handleMessage(errors.map((er, index) => (
              <span key = {index} className='text-danger'>{er} <br/></span>
            )))
            }

        })
        .catch(err => {
          console.log(err)
          handleMessage(<span className='text-danger'>That didn't work. Please try again later...</span>)
        })
    }
  


    return (
     <>
      <h4 className='mb-3'>Your information</h4>
        <a className="back-btn mt-3 mb-4" onClick={() => setShowGroups(true)}>
          <img src={BackIcon} alt="Back" />
          <span className="back-btn__text">Back</span>
        </a>
        {selectedGroup?.booked ? (
          <div className='mt-5 text-muted'>
            <h5 className='mt-5'>You have already booked this meeting. </h5>

            {selectedGroup?.subscribed &&  <h5 className='mt-3'>To unsubscribe from future occurrences, please click the back button above </h5>}
          </div>

        ) :  

          <form>
            <div className='row p-3'>
        
              <div className="field col-12 col-sm-6">
                  <label className='field__label' htmlFor="first_name">First Name</label>
                  <input type="text" value={firstName} onChange={(e)=>setFirstName(e.target.value)}
                    id="first_name"/>
              </div>
              
              <div className="field col-12 col-sm-6 field">
                  <label className='field__label'  htmlFor="last_name">Last Name</label>
                  <input type="text" value={lastName} onChange={(e)=>setLastName(e.target.value)}
                    id="last_name"/>
              </div>

              <div className="field col-12 col-sm-6 field">
                  <label htmlFor="zoom_email">Email address </label>
                  <input type="email"  value={zoomEmail} onChange={(e)=>setZoomEmail(e.target.value)} 
                    id="zoom_email" aria-describedby="emailHelp"/>
              </div>

              <div className="col-12 col-sm-6 form-check mt-40 ms-20">
                  <input  type="checkbox"  value={recurring} checked={recurring}
                    onChange={(e)=>setRecurring(e.target.checked)}  className="form-check-input" id="recurring"/>
                  <label className="field__label form-check-label"  htmlFor="recurring"><h6>I want to attend every week</h6></label>
              </div>
              
            
            </div>
            <div className='text-center mt-3'>
            {showMessage === true &&
              <div className='p-4'>
                <FlashMessage duration={5000} persistOnHover={true} className='text-center mb-3'>
                {msg}
                </FlashMessage>
              </div>
            }
              <button type="submit" onClick={sendForm} className="btn_primary btn-sm bg-dark mt-6" disabled={disablePost}>Book Meeting</button>
            </div>
          </form>

      }

      </>
    )
}

export default GroupForm

