import React, { useState } from 'react'
import Vimeo from '@u-wave/react-vimeo'

export default function Video(props) {
  const [success, setSuccess] = useState(false)
  const updatedTime = (data) => {
    if (data.percent >= 0.75 && success == false) {
      let requestData = {
        lesson_id: props.currentLesson.id,
        user_id: props.currentUser.id,
      }
      fetch('/api/v1/video_events', {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.authToken,
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status > 204) {
            throw new Error('Not success')
          } else {
            setSuccess(true)
          }
        })
        .catch((error) => console.log(error))
    }
  }
  return (
    <div className="container__module">
      <div className="module__title">
        <h3 className="module__title-subtitle">{props.currentLesson && props.currentLesson.title}</h3>
      </div>
      <div className="module__content">
        <div className='container__video--full'>
          <Vimeo
            video={Array.isArray(props.currentLesson.vimeo_safe_url) ? props.currentLesson.vimeo_safe_url[0] : props.currentLesson.vimeo_safe_url}
            onTimeUpdate={(data) => updatedTime(data)}
          />
        </div>
      </div>
    </div>
  )
}
