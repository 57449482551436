import React from 'react'

import LinkToStart from '../../../shared/buttons/LinkToStart'

import img from '../../../../assets/images/users-say-block.png'

const RewiewHeader = () => {
  return (
    <div className="review-header">
      <div className="sum-page__wrapper">
        <div className="review-header__content">
          <div className="review-header__description-wrapper">
            <div className="review-header__description">
              <h2 className="review-header__title">
                IGNTD works for people just like you
              </h2>
              <p className="review-header__sub-title">
                Over the years, we’ve helped thousands of people from all backgrounds - our community come from all ages (19-72), income ranges, cultures, geographical locations (english only for now, but stay tuned!), races and sexual orientations. We don’t exclude and we don’t shame anyone for being different, because we are all different!              </p>
              <LinkToStart
                link="https://www.igntdrecovery.com/change-your-life-drinking-score1"
              />
            </div>
          </div>
          <div className="review-header__img-wrapper">
            <img src={img} alt="img" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RewiewHeader
