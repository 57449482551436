import React from 'react'

function Coach({ public_image_url, name, experience, expertise }) {
  return (
    <div className={`coach`}>
      <img className="coach_img" src={public_image_url} alt="coach img" />
      <div className="coach_with-border">
        <h3>{name}</h3>
        <h6>({experience} years of experience)</h6>
      </div>
      <ul className="coach__field_of_expertise">
        <h4>Field of Expertise:</h4>
        {expertise && expertise.map((field, index) => (
          <li key={index} className="coach__skill">
            <span>{field}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Coach
