import React from 'react'

import SdsQuestionField from './SdsQuestionField'
import { propsAdapter } from '../../helpers/helperFunctions'

function QuestionsPage({
   page,
   handleChange, 
   formData, 
   active = false, 
   propsAdapterInstructions = null, 
   needToTransferAnswerType = false,
   addictionQue,
   setAddictionQue
}) {
   const getSectionStyles = () => {
      if (active) {
         return "card"
      } else {
         return "card d-none"
      }
   }

   return (
      <div className={getSectionStyles()} >
         <div className="react-card-body card-body">
            {page.questions.map((question, index) => {
               return (
                  <SdsQuestionField
                     key={index}
                     needToTransferAnswerType={needToTransferAnswerType}
                     question={ question }
                     value={formData[question?.id] ? formData[question?.id] : null}
                     formData = {formData}
                     handleChange={handleChange}
                     addictionQue = {addictionQue}
                     setAddictionQue = {setAddictionQue}
                  />
               )
            })}
         </div>
      </div>
   )
}
export default QuestionsPage