import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import arrowImg from '../../../../../assets/images/down_black_arrow.svg'

function AddictionalWeekChart({ addiction_progress_weekly, current_user, average_usage }) {

   useEffect(() => {
      renderChart()
   })

   function renderChart() {

      //remove chart lib button 
      am4core.useTheme(am4themes_animated)
      am4core.addLicense('ch-custom-attribution')

      // Create chart instance
      var chart = am4core.create("addictionalXYChart", am4charts.XYChart);

      // Add data
      chart.data = getChartData()

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "day";
      categoryAxis.renderer.line.strokeOpacity = 0
      categoryAxis.renderer.grid.template.strokeOpacity = 0
      categoryAxis.renderer.grid.template.stroke = am4core.color('red')
      categoryAxis.renderer.grid.template.strokeOpacity = 0
      categoryAxis.renderer.grid.template.strokeDasharray = 4
      categoryAxis.renderer.minGridDistance = 5
      //custom categoryAxis label font
      categoryAxis.renderer.labels.template.fontWeight = 600
      categoryAxis.renderer.labels.template.fontSize = 12
      categoryAxis.renderer.labels.template.fontFamily = 'Henderson Sans Basic, sans-serif';
      //hide categoryAxis bottom line
      categoryAxis.renderer.line.strokeOpacity = 1;
      categoryAxis.renderer.line.stroke = am4core.color("#fff");

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.max = getMaxValue()
      valueAxis.min = 0
      valueAxis.renderer.minGridDistance = 20
      valueAxis.renderer.grid.template.strokeOpacity = 0
      //custom valueAxis label font
      valueAxis.renderer.labels.template.fontWeight = 400
      valueAxis.renderer.labels.template.fontSize = 12
      valueAxis.renderer.labels.template.fontFamily = 'Henderson Sans Basic, sans-serif';


      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "value";
      series.dataFields.valueX = "percentage_value";
      series.dataFields.categoryX = "day";
      series.columns.template.fill = "color"
      series.clustred = true;
      series.zIndex = 10;
      series.stacked = false;
      series.columns.template.adapter.add("fill", (_, target) => {
         return getSeriesColor(target.dataItem.values.valueX.value)
      });
      series.dy = 0
      var columnTemplate = series.columns.template
      columnTemplate.width = am4core.percent(50)
      columnTemplate.maxWidth = 20
      columnTemplate.column.cornerRadius(100, 100, 100, 100)
      columnTemplate.strokeOpacity = 0

      //add and customize tooltip
      var tooltipHTML = `<p style="color: #151515; font-weight: 600; font-family: Montserrat; font-size: 12px; padding: 4px 15px; margin: 0;">{value}</p>`
      series.columns.template.tooltipHTML = tooltipHTML
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#fff")
      series.columns.template.tooltipY = am4core.percent(0);
      series.tooltip.background.cornerRadius = 50
      series.tooltip.background.strokeWidth = 0
      series.tooltip.pointerOrientation = 'down'
      let shadow = series.tooltip.background.filters.getIndex(0);
      shadow.dx = 0;
      shadow.dy = 2;
      shadow.blur = 22;
      shadow.color = am4core.color("rgba(0, 0, 0, 0.17);");

      //add bg series
      var seriesBg = chart.series.push(new am4charts.ColumnSeries());
      seriesBg.dataFields.valueY = "max";
      seriesBg.dataFields.value = "value";
      seriesBg.dataFields.categoryX = "day";
      seriesBg.columns.template.tooltipHTML = null
      seriesBg.columns.template.fill = am4core.color("#EEEEF1")
      seriesBg.stacked = false;
      series.dy = 0
      //important
      seriesBg.clustered = false;

      var columnTemplateBg = seriesBg.columns.template
      columnTemplateBg.width = am4core.percent(50)
      columnTemplateBg.maxWidth = 20
      columnTemplateBg.column.cornerRadius(100, 100, 100, 100)
      columnTemplateBg.strokeOpacity = 0
      // columnTemplateBg.zIndex = -1
      seriesBg.columns.template.tooltipText = "{value}";
      seriesBg.columns.template.adapter.add("tooltipHTML", (_, target) => {
         if (target.dataItem.values.value.value == -1) {
            return `<p style="color: #151515; font-weight: 600; font-family: Montserrat; font-size: 12px; padding: 4px 15px; margin: 0;">no data</p>`
         } else {
            return `<p style="color: #151515; font-weight: 600; font-family: Montserrat; font-size: 12px; padding: 4px 15px; margin: 0;">{value}</p>`
         }
      });
      //add and customize tooltip for bacground
      seriesBg.tooltip.getFillFromObject = false;
      seriesBg.tooltip.background.fill = am4core.color("#fff")
      seriesBg.columns.template.tooltipY = am4core.percent(44.5);
      seriesBg.tooltip.background.cornerRadius = 50
      seriesBg.tooltip.background.strokeWidth = 0
      seriesBg.tooltip.pointerOrientation = 'down'
      let shadowBg = seriesBg.tooltip.background.filters.getIndex(0);
      shadowBg.dx = 0;
      shadowBg.dy = 2;
      shadowBg.blur = 22;
      shadowBg.color = am4core.color("rgba(0, 0, 0, 0.17);");
      seriesBg.columns.template.tooltipText = "{value}";
      seriesBg.columns.template.adapter.add("tooltipY", (_, target) => {
         switch (target.dataItem.values.value.value) {
            case 0:
            case -1:
               return am4core.percent(0);
            default:
               return am4core.percent(getBgTooltipY(target.dataItem.values.value.value))
         }
      });
   }

   function getChartData() {
      return addiction_progress_weekly.map(({ day, percentage_value, value }) => ({
         "day": day.substring(0, 3),
         "value": value === "no data" ? -1 : value,
         "percentage_value": percentage_value === "no data" ? -1 : percentage_value,
         "max": getMaxValue(),
         "color": getSeriesColor(percentage_value),
      }))
   }

   function getMaxValue() {
      const maxValue = Math.max(...addiction_progress_weekly.map(day => day.value === 'no data' ? 0 : day.value))
      return maxValue === 0 ? 3 : maxValue
   }

   function getSeriesColor(value) {
      if (value > 105) return '#E7683B'
      if (value < 95) return '#45af89'
      return '#296E93'
   }

   function getBgTooltipY(value) {
      const max = getMaxValue()
      const persentOfMax = value / max * 100
      return 100 - persentOfMax
   }

   const filterDaysWithoutData = () => addiction_progress_weekly.filter(day => day.value !== 'no data')

   function getAverageWeekValue() {
      const valuesList = filterDaysWithoutData().map(({ value }) => value)
      const sum = valuesList.reduce(add, 0)

      function add(accumulator, a) {
         return accumulator + a;
      }

      const average = sum / valuesList.length
      return average.toFixed(1)
   }

   function getProgressDayByWeekPoint() {
      const averegeWeekValue = getAverageWeekValue()

      if (filterDaysWithoutData().length === 0) return null
      if (averegeWeekValue > average_usage) {
         return {
            value: averegeWeekValue - average_usage,
            bigger: true
         }
      } else {
         return {
            value: average_usage - averegeWeekValue,
            bigger: false
         }
      }
   }

   const progressDayByWeekPoint = getProgressDayByWeekPoint()

   return (
      <div>
         {
            progressDayByWeekPoint &&
            <div className="card__text-above-chart flex_to_center">
               <img className={progressDayByWeekPoint.bigger ? 'flip-vertically' : null} src={arrowImg} alt="arrow icon" />
               <span>{progressDayByWeekPoint.value.toFixed(1)}</span>
               {current_user.addiction === "alcohol" ? 'drinks/day' : 'portions/day'}
            </div>
         }
         <div className="card__chart-container">
            <div className="card__chart" id="addictionalXYChart"></div>
         </div>
      </div>
   )
}

export default AddictionalWeekChart