import React from 'react'

const Community = ({assignment}) => {
  return (
    <div>
      <div className='desc text-muted'>
      {assignment.status === "assigned" ? 
          `${assignment.sds_user.fullname} would like you to engage in the community. You can do this by making new posts or comments.`
          : 
          `You completed a community assignment by ${assignment.sds_user.fullname}`
        }
      </div>
      <a href={assignment.url}> {assignment.status === "assigned" ? 'Start Now' : 'Redo it'}</a>
    </div>
  )
}

export default Community