import React, { useRef } from 'react'

import Worksheet from '../../../shared/components/worksheet/Worksheet'

const WorkshopWorksheet = (props) => {
  const containerRef = useRef(null)

  return (
    <div ref={containerRef}>
      <div className="container__module">
        <div className="module__content">
          <div className="container__video--full">
            {
              props.currentLesson.worksheet_video_url &&
              <iframe
                src={props.currentLesson.worksheet_video_url}
                width="100%"
                height="auto"
                frameBorder="0"
                allow="fullscreen"
                allowFullScreen
              ></iframe>
            }
          </div>
          <Worksheet
            type="workshop"
            currentLesson={props.currentLesson}
            updateProgress={props.updateProgress}
            authToken={props.authToken}
            changeStep={props.changeStep}
            url="worksheet-upload"
            submitStep={props.submitStep}
          />
        </div>
      </div>
    </div>
  )
}

export default WorkshopWorksheet
