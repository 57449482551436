import React, { useEffect, useState } from 'react'
import useResizeObserver from "use-resize-observer"
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { category_list } from '../../../helpers/otherSrugglesHelper'

const XYChart = ({ other_addiction_percentages = [] }) => {

  const { ref, width = 1000, height = 680 } = useResizeObserver()
  const [legendPosicion, senLegendPosicion] = useState('right')

  useEffect(() => {
    if (width < 540 && legendPosicion === 'right') {
      senLegendPosicion('bottom')
      return
    }
    if (width >= 540 && legendPosicion === 'bottom') {
      senLegendPosicion('right')
      return
    }
  }, [width])


  useEffect(() => {
    renderChart(legendPosicion)
  }, [legendPosicion])

  function renderChart(legendPosition = 'right') {
    am4core.useTheme(am4themes_animated)
    am4core.addLicense('ch-custom-attribution')

    var chart = am4core.create('chartdiv2', am4charts.XYChart)
    // Add data

    chart.colors.list = [
      am4core.color('#E7683B'),
      am4core.color('#FFDB96'),
      am4core.color('#E5A8B9'),
      am4core.color('#C883B1'),
      am4core.color('#96B4E9'),
      am4core.color('#296E93'),
      am4core.color('#0B3653'),
    ]

    const getChartData = () => {
      const other_addictions = other_addiction_percentages?.reduce((obj, item) => Object.assign(obj, { [item.category]: item.value }), {});
      return category_list.map(category => ({
        category,
        value: other_addictions?.hasOwnProperty(category) ? other_addictions[category] : 0
      }))
    }

    chart.data = getChartData()
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    categoryAxis.renderer.labels.template.visible = false;
    categoryAxis.renderer.line.strokeOpacity = 1
    categoryAxis.renderer.line.strokeWidth = 1
    categoryAxis.renderer.grid.template.strokeOpacity = 1
    categoryAxis.renderer.grid.template.stroke = am4core.color('#C4CFD4')
    categoryAxis.renderer.grid.template.strokeWidth = 1
    categoryAxis.renderer.grid.template.strokeDasharray = 4
    categoryAxis.renderer.minGridDistance = 10

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.line.strokeOpacity = 1
    valueAxis.renderer.line.strokeWidth = 1
    valueAxis.max = 100
    valueAxis.min = 0
    valueAxis.renderer.minGridDistance = 10
    valueAxis.renderer.grid.template.strokeOpacity = 0

    // Create series
    var series1 = chart.series.push(new am4charts.ColumnSeries())
    series1.dataFields.valueY = 'value'
    series1.dataFields.categoryX = 'category'

    var columnTemplate = series1.columns.template
    columnTemplate.width = am4core.percent(50)
    columnTemplate.maxWidth = 66
    columnTemplate.column.cornerRadius(14, 14, 0, 0)
    columnTemplate.strokeOpacity = 0

    var bullet = new am4charts.CircleBullet()
    bullet.circle.radius = 7.5
    bullet.strokeOpacity = 0

    let dyHeight = document.querySelector('#chartdiv2').clientHeight
    bullet.properties.dy = -dyHeight + 61

    categoryAxis.dataItems.template.bullet = bullet

    //chart column colors
    columnTemplate.adapter.add('fill', (fill, target) => {
      return chart.colors.getIndex(target.dataItem.index)
    })

    //chart bullet colors
    bullet.adapter.add('fill', (fill, target) => {
      return chart.colors.getIndex(target.dataItem.index)
    })
    //create and add tooltip to bullet and column 
    var tooltipHTML = `
    <h6 style="text-align: center; color: #151515; font-weight: 600; font-family: Montserrat; margin: 12px 10px 0 10px">{category}</h6>
    <hr style="border: 1px dashed #C4CFD5;"/>
    <p style="text-align: center; color: #E7683B; font-weight: bold; font-family: Montserrat; margin-bottom: 8px">{value}%</p>`
    bullet.tooltipHTML = tooltipHTML
    series1.columns.template.tooltipHTML = tooltipHTML
    //customize tooltip
    chart.tooltip.getFillFromObject = false;
    chart.tooltip.background.fill = am4core.color("#fff")
    chart.tooltip.background.cornerRadius = 20
    chart.tooltip.background.strokeWidth = 1
    chart.tooltip.background.stroke = am4core.color('#C4CFD5')
    chart.tooltip.background.filters.clear()
    chart.tooltip.dy = 3
    //customize series tooltip
    // columnTemplate.tooltip.getFillFromObject = false
    series1.tooltip.getFillFromObject = false;
    series1.tooltip.background.fill = am4core.color("#fff")
    series1.tooltip.background.cornerRadius = 20
    series1.tooltip.background.strokeWidth = 1
    series1.tooltip.background.stroke = am4core.color('#C4CFD5')
    series1.tooltip.pointerOrientation = 'down'

    //chart legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = legendPosition
    chart.legend.useDefaultMarker = true;
    var marker = chart.legend.markers.template.children.getIndex(0)
    marker.cornerRadius(50, 50, 50, 50)
    marker.height = 10
    marker.width = 10
    marker.dy = 5
    marker.dx = 10

    series1.events.on("dataitemsvalidated", function () {
      var data = [];
      series1.dataItems.each(function (dataItem) {
        data.push({ name: dataItem.categoryX, fill: dataItem.column.fill, seriesDataItem: dataItem })
      })

      chart.legend.data = data;
      chart.legend.itemContainers.template.events.on("toggled", function (event) {
        var seriesDataItem = event.target.dataItem.dataContext.seriesDataItem;
        if (event.target.isActive) {
          seriesDataItem.hide(series1.interpolationDuration, 0, 0, ["valueY"]);
        }
        else {
          seriesDataItem.show(series1.interpolationDuration, 0, ["valueY"]);
        }
      })
    })
  }


  return (
    <section>
      <h2 className="results__card-title">Other Identified Struggles</h2>
      <div ref={ref} id="chartdiv2"></div >
    </section>
  )
}

export default XYChart
