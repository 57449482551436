import environment from '../../assets/images/wheelOfLive/environment.svg'
import fun from '../../assets/images/wheelOfLive/fun.svg'
import growth from '../../assets/images/wheelOfLive/growth.svg'
import romance from '../../assets/images/wheelOfLive/romance.svg'
import family from '../../assets/images/wheelOfLive/family.svg'
import health from '../../assets/images/wheelOfLive/health.svg'
import finances from '../../assets/images/wheelOfLive/finances.svg'
import business from '../../assets/images/wheelOfLive/business.svg'
import contribution from '../../assets/images/wheelOfLive/contribution.svg'
import purpose from '../../assets/images/wheelOfLive/purpose.svg'

export const wheelOfLiveCategoryesList = [
  {
    label: 'Purpose',
  },
  {
    label: "Contribution",
  },
  {
    label: `Business 
      Career`,
  },
  {
    label: "Finances",
  },
  {
    label: "Health",
  },
  {
    label: "Family & Friends",
  },
  {
    label: "Romance",
  },
  {
    label: `Personal
       Growth`,
  },
  {
    label: `Fun &
       Recreation`,
  },
  {
    label: `     Physical 
      Environment`,
  }
]

export const iconData_dashboard = {
  //version 1
  'id-385': {
    dx: 0,
    dy: -37,
    href: environment
  },
  'id-380': {
    dx: -32,
    dy: -26,
    href: fun
  },
  'id-375': {
    dx: -14,
    dy: -9,
    href: growth
  },
  'id-370': {
    dx: -5,
    dy: 14,
    href: romance
  },
  'id-365': {
    dx: 32,
    dy: 21.5,
    href: family
  },
  'id-360': {
    dx: 38,
    dy: 21,
    href: health
  },
  'id-355': {
    dx: 4.5,
    dy: 13,
    href: finances
  },
  'id-350': {
    dx: 14,
    dy: -8,
    href: business
  },
  'id-345': {
    dx: 3.5,
    dy: -14,
    href: contribution
  },
  'id-340': {
    dx: -16,
    dy: -22,
    href: purpose
  },
  //version 2
  'id-530': {
    dx: 0,
    dy: -37,
    href: environment
  },
  'id-525': {
    dx: -32,
    dy: -26,
    href: fun
  },
  'id-520': {
    dx: -14,
    dy: -9,
    href: growth
  },
  'id-515': {
    dx: -5,
    dy: 14,
    href: romance
  },
  'id-510': {
    dx: 32,
    dy: 21.5,
    href: family
  },
  'id-505': {
    dx: 38,
    dy: 21,
    href: health
  },
  'id-500': {
    dx: 4.5,
    dy: 13,
    href: finances
  },
  'id-495': {
    dx: 14,
    dy: -8,
    href: business
  },
  'id-490': {
    dx: 3.5,
    dy: -14,
    href: contribution
  },
  'id-485': {
    dx: -16,
    dy: -22,
    href: purpose
  },
}
export const iconData_summary = {
  'id-337': {
    dx: -3,
    dy: -37,
    href: environment
  },
  'id-333': {
    dx: -35,
    dy: -26,
    href: fun
  },
  'id-329': {
    dx: -15,
    dy: -8,
    href: growth
  },
  'id-325': {
    dx: -4,
    dy: 13.5,
    href: romance
  },
  'id-321': {
    dx: 37,
    dy: 22,
    href: family
  },
  'id-317': {
    dx: 42,
    dy: 21.5,
    href: health
  },
  'id-313': {
    dx: 3,
    dy: 13,
    href: finances
  },
  'id-309': {
    dx: 13,
    dy: -8,
    href: business
  },
  'id-305': {
    dx: 2,
    dy: -14,
    href: contribution
  },
  'id-301': {
    dx: -19,
    dy: -22,
    href: purpose
  },
  //version 2
  'id-460': {
    dx: 0,
    dy: -37,
    href: environment
  },
  'id-456': {
    dx: -32,
    dy: -26,
    href: fun
  },
  'id-452': {
    dx: -14,
    dy: -9,
    href: growth
  },
  'id-448': {
    dx: -5,
    dy: 14,
    href: romance
  },
  'id-444': {
    dx: 36,
    dy: 21.5,
    href: family
  },
  'id-440': {
    dx: 40,
    dy: 21,
    href: health
  },
  'id-436': {
    dx: 4.5,
    dy: 13,
    href: finances
  },
  'id-432': {
    dx: 14,
    dy: -8,
    href: business
  },
  'id-428': {
    dx: 3.5,
    dy: -14,
    href: contribution
  },
  'id-424': {
    dx: -18,
    dy: -22,
    href: purpose
  },
}