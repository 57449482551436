import React from 'react';

// import '../../../../assets/stylesheets/landing/sections/sparxintro.scss'
import sparxIntro from '../../../../assets/images/sparxIntro.png'

const SparxIntro = () => {

    return (
        <section className="about-system">
            <div className="about-system__wrapper wrapper">
                <h2 className="title about-system__title">Introducing IGNTD</h2>
                <div className="about-system__content">
                    <div className="about-system__img-wrapper">
                        <img src={sparxIntro} alt="Introducting SPARx" />
                    </div>
                    <div className="about-system__description">
                        <p className="about-system__info">
                            <span>SPARx</span> - Smart Personalized Adaptive Recovery System
                        </p>
                        <p className="about-system__info">
                            Experience the first recovery platform that learns your needs from day to day and adapts to match your current mood and your specific struggles. It's an intelligent recovery system that's customized for you.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SparxIntro;
