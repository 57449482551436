import React from 'react'

const DailyCheckin = ({assignment}) => {
  return (
    <div>
      <div className='desc text-muted'>
        You completed your daily checkin. 
      </div>
    </div>
  )
}

export default DailyCheckin