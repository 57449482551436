import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  infoList,
  data,
  aboutYourselfQuestions,
  mentalQuestions,
} from '../../../../helpers/intakeAssessmentQuestions'
import QuestionsSection from '../../../../shared/components/QuestionsSection'

function Assessment(props) {

  const history = useHistory()

  const [openEl, setOpenEl] = useState('alcoholAddiction')
  const [formData, setFormData] = useState(data)

  function handleChange(event) {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSectionClick = (e) => {
    setOpenEl(e.target.id)
  }

  const handleSubmit = () => {
    fetch(`/lesson/${props.currentLesson.id}/intake_assessment`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        } else if (response.status < 204) {
          return response.json()
        }
        return response.json()
      })
      .then(() => {
        history.push('/wheelassessment')
      })
      .catch((error) => console.log(error))
  }

  return (
    <div className="wrapper intake-assessment">
      <h2>Intake{props.monthly_assessment && '/Monthly'} Assessment</h2>
      <div className="header-description">
        <p>
          Our platform is designed to adjust to your specific needs. In order to make sure we are
          best able to address your specific needs and follow your progress, we need to get to know
          you better and understand your struggles specifically.
        </p>
        <br />
        <p>To help us do that, please answer the following questions:</p>
      </div>
      <br />

      <h4>Tell Us About Yourself</h4>
      <QuestionsSection
        section={aboutYourselfQuestions}
        handleChange={handleChange}
        formData={formData}
        withoutAccordion={true}
      />
      <br />

      <div className="inrake-section-header">
        <h4>Addiction Questions</h4>
        <div className="header-description">
          <p>
            Our platform is designed to adjust to your specific needs. In order to make sure we are
            best able to address your specific needs and follow your progress, we need to get to
            know you better and understand your struggles specifically.
          </p>
        </div>
      </div>

      <div className="accordion">
        {infoList.map((section) => (
          <QuestionsSection
            key={section.id}
            section={section}
            handleChange={handleChange}
            handleSectionClick={handleSectionClick}
            openEl={openEl}
            formData={formData}
          />
        ))}
      </div>

      <div className="inrake-section-header">
        <h4>Additional Questions</h4>
        <div className="header-description">
          <p>
            We understand that everyone has different struggles with addiction. Please answer the
            questions below for the types of addiction you would like assistance with.
          </p>
        </div>
      </div>

      <QuestionsSection
        section={mentalQuestions}
        handleChange={handleChange}
        formData={formData}
        withoutAccordion={true}
      />

      <div className="actions">
        <button className="intake_complete_assessment btn_primary" onClick={handleSubmit}>
          Complete Assessment
        </button>
      </div>
    </div >
  )
}

export default Assessment
