import React, { createContext } from 'react'
import { install } from 'resize-observer'

import IntroPage from './sections/IntroPage'
import Sponsors from './sections/Sponsors'
import SparxIntro from './sections/SparxIntro'
import ClientsSpeak from './sections/ClientsSpeak'
import GetSect from './sections/GetSect'
import Coaches from './sections/Coaches'
import Motivation from './sections/Motivation'

export const PropsContext = createContext()

if (!window.ResizeObserver) install()

function LandingPage(props) {
  return (
    <div className="landing_page">
      <PropsContext.Provider value={props}>
        <IntroPage {...props}/>
        <Sponsors />
        <ClientsSpeak reviews={props.reviews} />
        <GetSect />
        <SparxIntro />
        <Coaches coaches={props.coaches} />
        <Motivation />
      </PropsContext.Provider>
    </div>
  )
}

export default LandingPage
