import React from 'react'
import { SwiperSlide } from 'swiper/react'

// import '../../../../assets/stylesheets/landing/sections/clientsSpeak.scss'

import ClientSwiperElement from '../components/ClientSwiperElement'
import CustomCarousel from '../../../shared/components/CustomCarousel'

function ClientsSpeak(props) {
  return (
    <section className="clients-speak">
      <div className="wrapper">
        <h2 className="title">Our Clients Speak</h2>
        <h6 className="clients-speak__sub-title">
          We've already helped thousands of people get to the root of their
          <br />
          &nbsp;addiction and ignite their recovery — and we're not done yet.
        </h6>
        <CustomCarousel
          className="carousel"
          pagination={true}
          navigation={true}
          prevEl="prev-el1"
          nextEl="next-el1"
        >
          {props.reviews.map((client, index) => (
            <SwiperSlide key={index}>
              <ClientSwiperElement
                id={index}
                name={client.reviewer_name}
                {...client} />
            </SwiperSlide>
          ))}
        </CustomCarousel>
      </div>
    </section>
  )
}

export default ClientsSpeak
