import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getDatePickerSettings } from '../../helpers/datePickerHelper';

function CalendarRangeInput({ onChangeInput, value, name }) {

   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);

   const settings = getDatePickerSettings(1)

   const handleStartDateChange = (date) => {
      setStartDate(date)
      onChangeInput({
         target: {
            value: {
               ...value,
               start_date: date
            },
            name
         }
      })
   }

   const handleEndDateChange = (date) => {
      setEndDate(date)
      onChangeInput({
         target: {
            value: {
               ...value,
               end_date: date
            },
            name
         }
      })
   }

   return (
      <div className="flex_container">
         <DatePicker
            placeholderText="Enter a start date"
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            {...settings}
         />
         <DatePicker
            placeholderText="Enter end date"
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            {...settings}
         />
      </div>
   );
}

export default CalendarRangeInput