import React from 'react'

import FireLoader from '../../../assets/images/fire-loader.gif'
import CircleLoader from '../../../assets/images/blue-loader.jpg'

const Loader = ({type, classes}) => {
    return(
        <div className='loader-spin'>
        {type == 'fire' ?
            <span className={classes}>
                <img src={FireLoader} alt="fire-icon" />
            </span>
            :
            <span className={classes}>
                <img src={CircleLoader} alt="circle-icon" />
            </span>
        }
        </div>

    )

}

export default Loader