import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import QuestonField from '../../../../shared/components/QuestonField'

import rangeList from '../../../../helpers/wheelAssessmentQuestions'

function WheelAssessment(props) {

  const history = useHistory()

  const [formData, setFormData] = useState({
    Purpose: 0,
    Contribution: 0,
    Business: 0,
    Finance: 0,
    Health: 0,
    ['Family & Friends']: 0,
    Romance: 0,
    ['Personal Growth']: 0,
    ['Fun & Recreation']: 0,
    ['Physical Environment']: 0,
  })

  function handleChange(event) {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = () => {
    fetch(`/lesson/${props.currentLesson.id}/wheel-of-life`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        } else if (response.status < 204) {
          return response.json()
        }
      })
      .then(() => {
        window.location.href = '/'
      })
      .catch((error) => console.log(error))
  }

  return (
    <div className="wrapper wheel-assessment2">
      <h2>Wheel of Life Assessment</h2>
      <div className="header-description">
        <p>
          Many people who seek help for addictions and a host of other mental health problems come
          in prepared to tackle the specific issues they want addressed - In your case alcohol. The
          motivation to create change around alcohol is real and strong. But at IGNTD we know that,
          even in the simplest of cases, the problems that people first come in with are only part
          of the problem.
        </p>
        <p>
          The question of “why” you are struggling can often have its origins in other areas of life
          that have long been neglected and ignored. For instance, are you smoking too much in order
          to avoid stress related to feelings of inadequacy around work and career? Are you seeking
          fulfillment in pornography because romance and intimacy in your personal relationships are
          lacking and you feel lonely? Is your life so lacking in fun and recreation that you feel
          you need to escape its monotony with alcohol? Identifying the missing pieces in order to
          build awareness for later work is crucial. For this reason, we created the IGNTD Wheel Of
          Life - a more complete look at our life that will help us understand what areas it might
          make sense to focus on.
        </p>
        <p>
          The purpose of this exercise is to assess your satisfaction and fulfillment across 10
          different areas of life. Review each of the 10 categories on the wheel below. For each
          one, mark your overall level of satisfaction in the category on a scale from 0 (extremely
          dissatisfied) to 10 (fully satisfied). Make sure to indicate the number that first comes
          to mind, not the number that you would like to achieve (we'll get there).
        </p>
        <br />
      </div>

      {rangeList.map((question, index) => {
        return (
          <QuestonField
            key={index}
            question={{
              ...question,
              answer_type: 'integer',
              max_value: 10
            }}
            value={formData[question.answer_key]}
            handleChange={handleChange}
            questionImg={question.img}
          />
        )
      })}
      <div className="actions">
        <button className="WOL_complete_assessment btn_primary" onClick={handleSubmit}>
          Complete Assessment
        </button>
      </div>
    </div>
  )
}

export default WheelAssessment
