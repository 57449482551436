import React from 'react'

import Card from '../../shared/components/Card'
import SnippetCard from '../../shared/components/SnippetCard'
import CardAdapter from '../SummaryPage/components/CardAdapter'

function Favorites({ authToken, chapter, favoritesList }) {

   function renderFavoritesItems(favorite) {
      switch (chapter) {
         case 'favorite_lessons':
         case 'favorite_workshops':
            const type = chapter == 'favorite_lessons' ? 'lesson' : 'workshops'
            return (
               <Card
                  fromFavoritePage={true}
                  contentType="Lesson"
                  isFavorite={true}
                  safe_resource_image={type === 'lesson' ? null : favorite.image_url}
                  href={`/${type}/${favorite.id}`}
                  completed={favorite.progress >= 60}
                  authToken={authToken}
                  {...favorite}
               />
            )
         case 'favorite_snippets':
            return (
               <SnippetCard authToken={authToken} snippet={{
                  ...favorite,
                  snippet_id: favorite.id,
                  snippet_description: favorite.description,
                  isFavorite: true
               }} />
            )
         case 'favorite_additional_resources':
            return (
               <CardAdapter
                  fromFavoritePage={true}
                  ratable_type="AdditionalResource"
                  isFavorite={true}
                  authToken={authToken}
                  disabled_rating={false}
                  style="blue"
                  {...favorite}
               />
            )
         default:
            return <></>
      }
   }

   return (
      <div className="cards mt-50">
         {
            favoritesList.map(favorite =>
               <div className="cards__item" key={favorite.id}>
                  {
                     renderFavoritesItems(favorite)
                  }
               </div>)
         }
      </div>
   )
}

export default Favorites