import React from 'react'

import ScoreCard from '../components/ScoreCard'

import need_follow from '../../../../assets/images/need_follow.svg'

const LifeScore = ({ life_score, with_two_months = true, account }) => {
  const lifeScoreArray = [
    {
      title: 'Best Performing',
      areas: life_score.average_score ? life_score.best_performing : [{ icon: need_follow, title: `You have zero scores. Please take an assessment.` }],
      isSpecial: true,
    },
    {
      title: 'Most Support Needed',
      areas: life_score.average_score ? life_score.most_support_needed : [{ icon: need_follow, title: `You have zero scores. Please take an assessment.` }],
      isSpecial: false,
    },
    {
      title: 'Most Improved',
      areas: Array.isArray(life_score.most_improved) && with_two_months ?
        life_score.most_improved : [{ icon: need_follow, title: 'Need follow assessment to be determined' }],
      isSpecial: false,
    },
  ]

  return (
    <div className='dash_life_score'>
      <h2 className="results__card-title">IGNTD Life Score</h2>
      <div className="results__score">
        <span>{life_score.average_score}</span>
      </div>
      {lifeScoreArray.map((card, i) => (
        <ScoreCard {...card} key={i} />
      ))}
    </div>
  )
}

export default LifeScore
