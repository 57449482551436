import React from 'react'

import BackIcon from '../../../assets/images/back_icon.svg'

function BackBtn({ link = '/dashboard', icon = BackIcon, text = 'BACK' }) {
   return (
      <a className="back-btn" href={link}>
         <img src={icon} alt="Back" />
         <span className="back-btn__text">{text}</span>
      </a>
   )
}

export default BackBtn