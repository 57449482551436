import React from 'react'

import Label from './Label'

function LabelField({ text_with_fields, value, onChangeLabelField, name, label_text }) {

   const handleChange = (event) => {
      onChangeLabelField({
         target: {
            name,
            value: {
               ...value,
               [event.target.name]: event.target.value
            }
         }
      })
   }

   const convertToFields = () => {
      let counter = -1
      return text_with_fields.split(' ').map((word, index) => {
         switch (word) {
            case '<Integer>':
               counter++
               return <input
                  key={index}
                  className='label-field__number'
                  type="number"
                  name={counter}
                  value={value[counter] || ''}
                  onChange={handleChange} />
            case '<String>':
               counter++
               return <input
                  key={index}
                  className='label-field__text'
                  type="text"
                  name={counter}
                  value={value[counter] || ''}
                  onChange={handleChange} />
            default:
               return <span key={index}>{word}</span>
         }
      })
   }

   return (
      <>
         {
            label_text &&
            <Label htmlFor={name} label_text={label_text} />
         }
         <p className="label-field__container">
            {convertToFields()}
         </p>
      </>
   )
}

export default LabelField