import React, { Children } from 'react'
import { Modal } from 'semantic-ui-react'


function ModalWithContinue({ show, onCloseModal, children }) {
   return (
      <Modal
         centered={true}
         open={show}
         tabIndex="-1"
         size="large"
         dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
      >
         {children}
         <button className="link-tostart link-tostart_summary" onClick={onCloseModal}>
            Continue
         </button>
      </Modal>
   )
}


export default ModalWithContinue