import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

import WorksheetForm from './WorksheetForm'
import Assessment360 from '../../../bundles/LessonPage/components/lessonPages/Assessment360'
import ExercisesSwitch from '../lessons/ExercisesSwitch';

export default function Worksheet(props) {
  const stepNumber = 3

  const [worksheetIndex, setworksheetIndex] = useState(1)
  const [worksheets, setWorksheets] = useState([])
  const [uploadedWorksheets, setUploadedWorksheets] = useState([])
  const [selectedFiles, setSelectedFiles] = useState({})

  const history = useHistory();

  useEffect(() => {
    if (!worksheets.length) {
      fetch(`/lesson/${props.currentLesson.id}/worksheet`, {
        credentials: 'same-origin',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.authToken,
        },
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          setWorksheets(data.worksheets)
          setUploadedWorksheets([...new Set(data.uploaded_worksheet_ids)])
        })
    }
  }, [])

  function onNextWorksheet() {
    if (worksheetIndex === worksheets.length) return
    setworksheetIndex(worksheetIndex + 1)
  }

  function onPrevWorksheet() {
    if (worksheetIndex === 1) return
    setworksheetIndex(worksheetIndex - 1)
  }

  function uploadWorksheet(event, worksheet_id) {
    const new_file = event.target.files[0]
    setSelectedFiles(prevState => ({
      ...prevState,
      [worksheet_id]: new_file.name
    }))
    if (new_file) {
      sendWorksheet(new_file, worksheet_id)
    }
  }

  function sendWorksheet(file, id) {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("worksheet_id", id)

    fetch(`/lesson/${props.currentLesson.id}/${props.url}`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': props.authToken,
        },
        body: formData,
      }
    )
      .then((response) => {
        // if (response.status > 204) {
        //   throw new Error('Not success')
        // }
        return response.json()
      })
      .then((data) => {
        const uploaded_worksheets = [...new Set(data.uploaded_worksheet_ids)]
        setUploadedWorksheets(uploaded_worksheets)
        setworksheetIndex(uploaded_worksheets.length)
        if (uploaded_worksheets.length === worksheets.length) {
          props.changeStep(stepNumber + 1, data.progress)
          props.submitStep(stepNumber + 1, stepNumber)
          history.push('/summary')
        }
      })
    // .catch(err => {
    //   console.log(err)
    // })
  }
  return (
    <>
      {
        props.isAssessment ?
          <Assessment360
            currentLesson={props.currentLesson}
            authToken={props.authToken}
          />

          :
          <>
            {worksheets.length > 1 &&
              <ExercisesSwitch
                currentIndex={worksheetIndex}
                exercisesAmount={worksheets.length}
                onPrevClick={onPrevWorksheet}
                onNextClick={onNextWorksheet}
                type="Worksheet"
              />
            }
            {
              worksheets.map((worksheet, index) => {
                if (index === worksheetIndex - 1) {
                  return (
                    <WorksheetForm
                      type={props.type}
                      key={worksheet.id}
                      fileName={selectedFiles[worksheet.id]}
                      currentWorksheet={worksheet}
                      uploadedWorksheets={uploadedWorksheets}
                      onChangeFile={uploadWorksheet}
                    />)
                }
              })
            }
          </>
      }
    </>
  )
}
