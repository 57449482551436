import React, { useEffect, useState } from 'react'

import check from '../../../../assets/images/round-check.svg'
import success from '../../../../assets/images/worcksheet_upload_success.svg'

function WorksheetForm({ uploadedWorksheets, onChangeFile, fileName, currentWorksheet, type }) {

   const [worksheetUploded, setWorksheetUploded] = useState(false)
   const [worksheetLoading, setWorksheetLoading] = useState(false)

   useEffect(() => {
      const isWorksheetUploaded = uploadedWorksheets.includes(currentWorksheet.id)
      setWorksheetUploded(isWorksheetUploaded)
      if (worksheetLoading) {
         setWorksheetLoading(!isWorksheetUploaded)
      }
   }, [uploadedWorksheets])

   const handleChange = (e) => {
      setWorksheetLoading(true)
      onChangeFile(e, currentWorksheet.id)
   }

   return (
      <form className="new_completed_lesson" id="new_completed_lesson">
         <input name="utf8" type="hidden" value="✓" />

         <div className={`uploader uploader--${worksheetUploded ? "green" : "red"}`}>
            <div className="border-text">
               <span className="d-none d-md-inline">{worksheetUploded ? "Your worksheet is uploaded!" : `Please complete the exercise and upload your work to complete the ${type} and get your badge!`}</span>
               <span className="d-inline d-md-none">{worksheetUploded ? "Your worksheet is uploaded!" : "Please upload your worksheet!"}</span>
               <img src={worksheetUploded ? success : check} alt="Check icon" />
            </div>
            <div className="uploader__intro">
               <h5>FINISH THIS {type}</h5>
               <h4 className="uploader-title">{currentWorksheet.name}</h4>
               <br />
            </div>
            <div className="uploader__buttons">
               <a href={currentWorksheet.service_url} target="_blank" className="btn_secondary download_btn">
                  Download Worksheet
               </a>
               <div className="field__button">
                  <label className="btn_primary">
                     Upload Worksheet
                     <span className="uploader_submit_btn">
                        <input
                           className="btn_primary lesson_worksheet_uploader"
                           type="file"
                           onChange={handleChange}
                           id="completed_lesson_signed_worksheet_file"
                        />
                     </span>
                  </label>
               </div>
            </div>
            {
               fileName &&
               <div className="uploader__confirmation">
                  <p>{fileName}</p>
               </div>
            }
            {
               worksheetLoading &&
               <div className="spinner-border text-primary mt-2" role="status"></div>
            }
         </div>
         <div className="actions" id="submit-btn__worksheet">
            <input type="submit" name="commit" value="Continue" data-disable-with="Continue" />
         </div>
      </form >
   )
}

export default WorksheetForm
