import React, { useEffect, useState } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

function WheelOfLiveChart({
   chartData,
   iconData,
   onMonthToggle = () => onMonthToggle,
   last_month_active = false,
   current_month_active,
   subtraction = 0,
   with_prev_month = false
}) {

   function renderChart() {

      am4core.useTheme(am4themes_animated)
      am4core.addLicense('ch-custom-attribution')

      var chart = am4core.create('chartdiv', am4charts.RadarChart)
      chart.hiddenState.properties.opacity = 0
      chart.responsive.enabled = true;
      chart.colors.list = [
         am4core.color('#8B5C9C'),
         am4core.color('#96B4E9'),
         am4core.color('#F99268'),
         am4core.color('#FFDB96'),
         am4core.color('#E5A8B9'),
         am4core.color('#C884B1'),
      ]

      chart.data = chartData.map(data => ({
         ...data,
         color: chart.colors.next()
      }))

      chart.padding(19, 19, 19, 19)

      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'label'
      categoryAxis.renderer.labels.template.padding(0, 7, 0, 7);
      categoryAxis.renderer.labels.template.location = 0.5
      categoryAxis.renderer.grid.template.strokeOpacity = 0

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.labels.template.disabled = true
      valueAxis.min = 0
      valueAxis.max = 10
      valueAxis.renderer.minGridDistance = 10
      valueAxis.renderer.grid.template.strokeOpacity = 1
      valueAxis.renderer.grid.template.stroke = am4core.color('#C4CFD4')
      valueAxis.renderer.grid.template.strokeWidth = 1
      valueAxis.renderer.grid.template.strokeDasharray = 4
      categoryAxis.renderer.labels.template.fontSize = 12

      var image = new am4core.Image()
      image.width = 12
      image.height = 16
      image.horizontalCenter = 'middle'
      image.verticalCenter = 'middle'
      image.adapter.add("href", function (_, img) {
         const right_id = img._uid.slice(3) - subtraction
         if (!iconData[`id-${right_id}`] || !iconData[`id-${right_id}`].href) return ""
         return iconData[`id-${right_id}`].href
      })
      image.adapter.add("dx", function (_, img) {
         const right_id = img._uid.slice(3) - subtraction
         if (!iconData[`id-${right_id}`] || !iconData[`id-${right_id}`].dx) return -1
         return iconData[`id-${right_id}`].dx
      })
      image.adapter.add("dy", function (_, img) {
         const right_id = img._uid.slice(3) - subtraction
         if (!iconData[`id-${right_id}`] || !iconData[`id-${right_id}`].dy) return -1
         return iconData[`id-${right_id}`].dy
      })
      image.opacity = 1
      categoryAxis.dataItems.template.bullet = image

      if (with_prev_month) {
         var series = chart.series.push(new am4charts.RadarColumnSeries())
         series.columns.template.tooltipText = '{label}: {valueY.value}'
         series.columns.template.width = am4core.percent(100)
         series.columns.template.strokeWidth = 0
         series.columns.template.column.propertyFields.fill = 'color'
         series.dataFields.categoryX = 'label'
         series.dataFields.valueY = 'last_value'
         series.fillOpacity = 0.5
         series.name = "Last Month"
         if (last_month_active) {
            series.fill = am4core.color('rgba(231, 104, 59, 0.5)')
         } else {
            series.fill = am4core.color('#999')
         }
      }

      var series2 = chart.series.push(new am4charts.RadarColumnSeries())
      series2.columns.template.width = am4core.percent(100)
      series2.columns.template.strokeWidth = 0
      series2.columns.template.column.propertyFields.fill = 'color'
      series2.fill = am4core.color('#E7683B')
      series2.dataFields.categoryX = 'label'
      series2.dataFields.valueY = 'current_value'
      series2.name = "Current Month"
      if (with_prev_month) {
         series2.stacked = true
         series2.columns.template.tooltipText = '{label}: {current_value_label}'
         if (current_month_active) {
            series2.fill = am4core.color('#E7683B')
         } else {
            series2.fill = am4core.color('#999')
         }
      } else {
         series2.columns.template.tooltipText = '{label}: {valueY.value}'
      }

      chart.legend = new am4charts.Legend();
      chart.legend.itemContainers.template.events.on("hit", (event) => {
         if (event.target.dataItem.dataContext.name === "Current Month") {
            onMonthToggle(last_month_active, !current_month_active)
         }
         if (event.target.dataItem.dataContext.name !== "Last Month") return
         onMonthToggle(!last_month_active, current_month_active)
      })

      chart.legend.useDefaultMarker = true;
      var marker = chart.legend.markers.template.children.getIndex(0)
      marker.cornerRadius(50, 50, 50, 50)
   }

   useEffect(() => {
      renderChart()
   }, [chartData])

   return (
      <div className='wheel_of_live_chart__container'>
         <div id="chartdiv"></div>
      </div>
   )
}

export default WheelOfLiveChart