import React, { useState } from 'react'
import { Rating } from 'semantic-ui-react'

import doneImg from '../../../assets/images/done.svg'
import videoButton from '../../../assets/images/video_button.svg'
import Video from '../Video'
import Bookmark from '../buttons/Bookmark'
import CustomRating from '../buttons/CustomRating'

const Card = (props) => {
  const [activeVideo, setActiveVideo] = useState('')

  const handleVideoClose = () => setActiveVideo('')
  const openVideo = () => setActiveVideo(props.video_url)

  const renderImg = () => {
    if (props.href && props.safe_resource_image) {
      return (
        <a
          href={props.href ? props.href : ''}
          target="_blank">
          <img src={props.safe_resource_image} alt="picture" />
        </a>
      )
    }
    if (props.safe_resource_image && !props.href) {
      return (
        <img src={props.safe_resource_image} alt="picture" />
      )
    }
    return null
  }

  return (
    <div
      className={`workshop-card ${props.completed ? 'done' : ''} ${props.style}`}
    >
      <div className="workshop-card__content-wrapper">
        <div className="workshop-card__img-wrapper">
          {
            props.video_url &&
            <div className="video-button" onClick={openVideo}>
              <img src={videoButton} />
            </div>
          }
          {
            renderImg()
          }
        </div>
        <div className="workshop-card__rating">
          <div className="workshop-card__rating-block">
            <CustomRating
              {...props}
              average_rating={props.rating}
              ratable_id={props.id}
              disabled={props.disabled_rating}
            />
            <div className="small_bookmark">
              <Bookmark
                isFavorite={props.isFavorite || props['favorite?']}
                contentId={props.id}
                contentType={props.contentType}
                authToken={props.authToken}
              />
            </div>
          </div>
          <div className="workshop-card__done-img">
            <img src={doneImg} alt="done" />
          </div>
        </div>
        <h3 className="workshop-card__title">{props.title}</h3>
        <p className="workshop-card__description">{props.description}</p>
      </div>
      {
        props.public_pdf_url &&
        <div className="workshop-card__pdf-container">
          <a className="workshop-card__pdf" href={props.public_pdf_url}>
            Download PDF File
          </a>
        </div>
      }
      {
        props.href &&
        <div className="workshop-card__link-block">
          <a href={props.href ? props.href : ''}
            className={`custom-tooltip workshop-card__link ${props.isSummary ? 'workshop-card__link_summary' : ''}`}
          >
            {
              props.fromFavoritePage &&
              <span className="custom-tooltip__text custom-tooltip__top">View Favorites</span>
            }
          </a>
        </div>
      }
      {
        activeVideo &&
        <Video
          video={activeVideo}
          onCloseVideo={handleVideoClose}
        />
      }
    </div>
  )
}

export default Card