import React from 'react'
import { Modal} from 'semantic-ui-react'

function ReactionsModal({emojis, reactionable_id, type, authToken, setNewReactions, hideOptions, currentPostId}) {
  const addReaction = (emoji_id)=>{

      fetch(`/${type}/${reactionable_id}/reactions`, {
        credentials: 'same-origin',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-Token': authToken,
        }, 
        body: JSON.stringify({
          emoji_id: emoji_id,
          currentPostId: currentPostId
        })
      }).then(response => response.json())
          .then(({status, reactions}) => {
            if (status === 201) {
              setNewReactions(reactions)
              hideOptions()
            }
  
          })
          .catch(err => {
            console.log(err)
          })
  }

  return (

    <Modal
    centered={true}
    open={true}
    tabIndex="-1"
    role="dialog"
    size="small"
    dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0)' } }}
    className='reactions-modal'
  >
    <>
    <Modal.Header>
        <h5>Add a reaction</h5>
        <button onClick={()=>  hideOptions()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span className="closing_cross" aria-hidden="true">&times;</span>
        </button>
    </Modal.Header>
    
    <Modal.Content>
      <Modal.Description >
        <div className='emoji-wrap'>
            {emojis.map((emoji) => (
                <div className='emoji' key={emoji.id}>
                    <span onClick={()=>addReaction(emoji.id)}>{emoji.e_character}</span>
                </div>
            ))
            }
        </div>
      </Modal.Description>
    </Modal.Content>
    </>
  </Modal>


  )
}

export default ReactionsModal