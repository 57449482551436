import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import Vimeo from '@u-wave/react-vimeo'

import Bookmark from '../buttons/Bookmark'
import CustomRating from '../buttons/CustomRating'

import { postSnippetWatched } from '../../helpers/fetchHelper'

function SnippetCard({ authToken, snippet }) {

   const [firstPlay, setFirstPlay] = useState(true)

   function onSnippetPlay() {
      if (!firstPlay) return
      setFirstPlay(false)

      const body = {
         snippet_id: snippet.snippet_id
      }
      postSnippetWatched(authToken, body)
   }
   return (
      <div className="recommendation-videos__video">
         <div className="iframe__container">
            <Vimeo
               className='iframe__item'
               video={snippet.snippet_vimeo_url}
               onPlay={onSnippetPlay}
               width="100%" height="100%"
               frameBorder="0"
               allow="fullscreen"
               allowFullScreen
            />
         </div>
         <div className="pt-3 ml--5 flex_container recommendation-videos__rating-container">
            <CustomRating
               ratable_id={snippet.snippet_id}
               average_rating={snippet.rating}
               already_rated={!!snippet.rating}
               ratable_type="Snippet"
               disabled={false}
            />
            <div className="small_bookmark">
               <Bookmark
                  isFavorite={snippet.isFavorite || snippet['favorite?']}
                  authToken={authToken}
                  contentType="Snippet"
                  contentId={snippet.snippet_id}
               />
            </div>
         </div>
         <p>{ReactHtmlParser(snippet.snippet_description ? snippet.snippet_description : "")}</p>
      </div>
   )
}

export default SnippetCard