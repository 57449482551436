import React from 'react'

import check from '../../../assets/images/round-check.svg'
import success from '../../../assets/images/worcksheet_upload_success.svg'

function ProgressBorder({ isCompleted, completedText, uncompletedText, children }) {

   return (
      <div className={`uploader uploader--${isCompleted ? "green" : "red"}`}>
         <div className="border-text">
            <span className="d-inline">{isCompleted ? completedText : uncompletedText}</span>
            <img src={isCompleted ? success : check} alt="Check icon" />
         </div>
         {children}
      </div>
   )
}

export default ProgressBorder