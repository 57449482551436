import React, { useState, useEffect, useRef } from 'react'
import { SwiperSlide } from 'swiper/react'
import Vimeo from '@u-wave/react-vimeo'

import ClientSwiperElementSummary from '../components/ClientSwiperElementSummary'
import CustomCarousel from '../../../shared/components/CustomCarousel'
import Video from '../../../shared/Video'

const ClientsSpeakSummary = ({ reviews }) => {
  const prevEl = `prev-el3`
  const nextEl = `next-el4`
  const [activeVideo, setActiveVideo] = useState('')

  const handleVideoClose = () => setActiveVideo('')
  const handleVideoOpen = (url) => setActiveVideo(url)

  return (
    <div className="clients-speak">
      <div className="sum-page__wrapper">
        <div className="clients-speak__content-wrapper">
          <h2 className="clients-speak__title">Our Clients Speak</h2>
          <p className="clients-speak__subtitle">
            We've already helped thousands of people get to the root of their addiction and ignite
            their recovery — and we're not done yet.
          </p>
          <CustomCarousel
            className="carousel_summary"
            navigation={true}
            prevEl={prevEl}
            nextEl={nextEl}
            slidesPerView={2}
            centeredSlides={false}
          >
            {reviews.map((client, index) => (
              <SwiperSlide key={index}>
                <ClientSwiperElementSummary
                  {...client}
                  id={index}
                  name={client.reviewer_name}
                  video_url={client.vimeo_safe_url}
                  videoClick={(open) => handleVideoOpen(open)}
                />
              </SwiperSlide>
            ))}
          </CustomCarousel>
        </div>
      </div>
      {
        activeVideo &&
        <div className="clients-speak__video-wraper">
          <Video
            video={activeVideo}
            onCloseVideo={handleVideoClose}
          />
        </div>
      }
    </div>
  )
}

export default ClientsSpeakSummary
