import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'

import EmotionSliderMiniItem from './EmotionSliderMiniItem'

SwiperCore.use([Navigation, Pagination])
function EmotionSlider(props) {
  const prevEl = `#prev-${props.emotionType}`
  const nextEl = `#next-${props.emotionType}`

  function isActive(emotionId) {
    return props.chosenEmotion == emotionId
  }

  return (
    <div className="emotion_swiper__container emotion_swiper__container-mini">
      <Swiper
        breakpoints={props.breakpoints || {}}
        slidesPerView={"auto"}
        slidesPerColumn={3}
        freeMode={true}
        spaceBetween={0}
        // navigation={{
        //   prevEl: prevEl,
        //   nextEl: nextEl,
        //   disabledClass: 'disabled_swiper_button',
        // }}
        // pagination={true}
        slidesPerColumnFill={'row'}
      >
        {
          props.emotionType !== 'all' &&
          <SwiperSlide
            className="text"
          >
            <EmotionSliderMiniItem
              emotion={{
                id: `no-${props.emotionType}-emotion`,
                name: `I don’t feel any ${props.emotionType} emotion`
              }}
              emotionType={props.emotionType}
              isActive={isActive}
              makeActive={props.makeActive}
            />
          </SwiperSlide>
        }
        {props.emotionsArray.map((emotion, index) => {
          return (
            <SwiperSlide key={index}>
              <EmotionSliderMiniItem
                key={emotion.id}
                emotion={emotion}
                emotionType={props.emotionType}
                isActive={isActive}
                makeActive={props.makeActive}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default EmotionSlider
