import React, { useState } from 'react'
import '../../../assets/stylesheets/workshop/sections/workshop.scss'
import imgEl from '../../../assets/images/illustration.svg'
import Card from '../../shared/components/Card'

const WorkshopPage = (props) => {
  console.log(props)
  return (
    <>
      <section className="workshop">
        <div className="workshop__wrapper_">
          <div className="workshop__info-block">
            <div className="workshop__text">
              <h1 className="workshop__title">Welcome to Your Workshops</h1>
              <p className="workshop__description">
              Our workshops, taught by world-leading experts, are here to teach, inspire and inform your journey to your ultimate life!
              </p>
            </div>
            <div className="workshop__img-wrapper">
              <img src={imgEl} alt="illustration" />
            </div>
          </div>
            { props.workshops === null?
                <div>
                    <h5 className="col-sm-8 colored mt- mb-4">Please Upgrade your package to access workshops.</h5>
                    <a href="https://www.igntdrecovery.com/transforming-addiction-2021-annual#col-full-171-105-162-101-141" target="_blank">
                    <button type="button" className="btn_primary ml-3 mt-2">
                        Upgrade Now
                    </button>
                    </a>
                </div> 
              :
              <div className="workshop__card-block">
                {
                props.workshops.map((workshop, index) =>
                  <Card
                    contentType="Lesson"
                    key={index}
                    safe_resource_image={workshop.thumbnail_image}
                    href={`/workshops/${workshop.id}`}
                    completed={workshop.progress >= 60}
                    authToken={props.authToken}
                    {...workshop}
                  />)
                }
              </div>
            }
        </div>
      </section>
    </>
  )
}

export default WorkshopPage
