import React, { useEffect, useState } from 'react'

import BackIcon from '../../../assets/images/back_icon.svg'

function FullScreenImg({ src, alt, className }) {

   const [fullScreen, setFullScreen] = useState(false)

   const openFullScreen = () => setFullScreen(true)
   const closeFullScreen = () => setFullScreen(false)

   if (fullScreen) {
      return (
         <div className="full-screen">
            <div className="full-screen__blackout" onClick={closeFullScreen}></div>
            <button className="full-screen__exit" onClick={closeFullScreen}>
               <img src={BackIcon} alt="Back icon" />
            </button>
            <img
               src={src}
               alt={alt ? alt : ""}
               className="full-screen__img"
            />
         </div>
      )
   }

   return (
      <img
         src={src}
         alt={alt ? alt : ""}
         className={className ? className : ''}
         style={{ cursor: 'zoom-in' }}
         onClick={openFullScreen}
      />
   )
}

export default FullScreenImg