import React, { useEffect, useState } from 'react'
import Driver from 'driver.js'
import Cookies from 'js-cookie'

const AppTips = ({alerts}) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    let defaultSteps = []
    const hasiOSCookie = Cookies.get('showiOSInstall');
    if (isIOS && isSafari) {
      if (hasiOSCookie){
        defaultSteps = [{
          element: ".badged_title",
          popover: {
            title: "Install the IGNTD App",
            description: "You can now install the IGNTD App using these steps:"+ 
                          "<br> 1. Tap the “Share” icon in Safari. <br/>"+
                          "2. Select “Add to Home Screen” from the options. <br/>"+
                          "3. Confirm the installation by tapping the “Add” button." ,
            position: "bottom",
          },
        }]
        Cookies.set('showiOSInstall', 'true', { expires: 365000 });
      }
  }


  useEffect(() => {
    setTimeout(() => {
    if (!window.location.href.includes("#")){
  
      const dynamicSteps = alerts.map((app_tip) => {
        const cookieId = app_tip.sds_code+app_tip.id
        if(!Cookies.get(cookieId)){
          Cookies.set(cookieId, 'true', { expires: 365000 });
          return {
            element: "."+app_tip.sds_code.code_value,
            popover: {
              title: app_tip.title,
              description: app_tip.description,
              position: app_tip.position,
            },
          }
        }
      }).filter((element) => element !== undefined);

      if(dynamicSteps?.length > 0 || defaultSteps?.length > 0 ){
        const driver = new Driver({
          allowClose: false,
        })
    
        driver.defineSteps([...defaultSteps, ...dynamicSteps])
        driver.start()
    
        return () => {
          driver.close()
        }

      }
    }
    }, 4000);



  }, []) // Ensure the effect runs only once after initial render

  return (<></>)
}

export default AppTips
