import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

function AddictionalDayChart({ addiction_progress_daily = 0 }) {


   useEffect(() => {
      renderChart()
   }, [])

   function renderChart() {

      //remove chart lib button 
      am4core.useTheme(am4themes_animated)
      am4core.addLicense('ch-custom-attribution')

      var chart = am4core.create("addictionalPieChart", am4charts.RadarChart);

      // Add data
      chart.data = [{
         "category": "",
         "value": addiction_progress_daily,
         "full": 100
      },];

      // Make chart not full circle
      chart.startAngle = -90;
      chart.endAngle = 270;
      chart.innerRadius = am4core.percent(65);

      // Set number format
      chart.numberFormatter.numberFormat = "";

      // Create axes
      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.strokeOpacity = 0;


      categoryAxis.renderer.minGridDistance = 100;

      var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.max = 100;

      // Create series
      var series1 = chart.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.valueX = "full";
      series1.dataFields.categoryY = "category";
      series1.clustered = false;
      series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
      series1.columns.template.fillOpacity = 0.08;
      series1.columns.template.cornerRadiusTopLeft = 100;
      series1.columns.template.strokeWidth = 5;
      series1.columns.template.stroke = am4core.color('#f7f7f7')
      series1.columns.template.radarColumn.cornerRadius = 100;

      var series2 = chart.series.push(new am4charts.RadarColumnSeries());
      series2.dataFields.valueX = "value";
      series2.dataFields.categoryY = "category";
      series2.columns.template.radarColumn.fill = getSeriesColor();
      series2.clustered = false;
      series2.columns.template.strokeWidth = 5;
      series2.columns.template.stroke = am4core.color('#f7f7f7')
      series2.columns.template.strokeOpacity = 0
      series2.columns.template.radarColumn.cornerRadius = 100;
   }

   function getSeriesColor() {
      if (addiction_progress_daily > 105) return '#E7683B'
      if (addiction_progress_daily < 95) return '#45af89'
      return '#296E93'
   }

   return (
      <div className="card__chart-container">
         <div className="card__chart" id="addictionalPieChart"></div>
         <div className="card__chart-center-text-container">
            <span>{addiction_progress_daily + '%'}</span>
         </div>
      </div>
   )
}

export default AddictionalDayChart