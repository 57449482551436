import React, { useState } from 'react'

import ModalWithContinue from '../../../shared/modals/ModalWithContinue'
import CustomRating from '../../../shared/buttons/CustomRating'

function ClientSwiperElementSummary({
  id,
  comment,
  public_image_url,
  name,
  country,
  social_media_type = null,
  vimeo_safe_url = '',
  videoClick,
}) {

  const [active_id, setActiveId] = useState(null)

  const handleButtonClick = (e) => {
    videoClick(vimeo_safe_url)
  }

  const handleTextClick = () => {
    setActiveId(id)
  }
  const handleCloseModal = () => {
    setActiveId(null)
  }

  const chooseIcon = () => {
    if (vimeo_safe_url) {
      return (
        <button
          className="client-summary__button client-summary__button_video"
          onClick={handleButtonClick}
        ></button>
      )
    }
    switch (social_media_type) {
      case 'Facebook':
        return (
          <div className='client-summary__button client-summary__button_facebook'></div>
        )
      case 'Instagram':
        return (
          <div className='client-summary__button client-summary__button_instagram'></div>
        )
      case 'Email':
        return (
          <div className="client-summary__button client-summary__button_email"></div>
        )
    }
    return <button className="client-summary__button client-summary__button_none"></button>
  }
  return (
    <article className="client-summary">
      <div className="client-summary__client-block">
        {/* to set dynamic use rating */}
        <div className="client-summary__stars-block">
          <CustomRating
            average_rating={5}
            disabled={true}
          />
        </div>
        <p onClick={handleTextClick} className='client-summary__comment'>{comment}</p>
        {chooseIcon()}
      </div>
      <div className="client-summary__user">
        <img className="client-summary__avatar" src={public_image_url} alt="client avatar" />
        <div className="client-summary__description">
          <h5>{name}</h5>
          <h6>{country}</h6>
        </div>
      </div>
      <ModalWithContinue
        show={active_id === id}
        onCloseModal={handleCloseModal}>
        <p className='pb-5'>
          {comment}
        </p>
      </ModalWithContinue>
    </article>
  )
}

export default ClientSwiperElementSummary
