import React from 'react'

import QuestonField from './QuestonField'

import { propsAdapter } from '../../helpers/helperFunctions'

function QuestionsSection({ section, handleChange, handleSectionClick, openEl, formData, withoutAccordion = false, propsAdapterInstructions = null, needToTransferAnswerType = false }) {
   const getSectionStyles = () => {
      if (withoutAccordion) {
         return "card selected"
      } else {
         return `card ${openEl == section.id ? 'selected' : ''}`
      }
   }

   const getSortedQuestons = () => {
      const questons = [...section.questions]
      if (questons.length <= 1) return questons
      return questons.sort((queston, nextQueston) => queston.sort_order - nextQueston.sort_order)
   }
   const sortedQuestion = getSortedQuestons()

   return (
      <div className={getSectionStyles()} >
         {
            !withoutAccordion &&
            <div className="card-header bg-white">
               <h2 className="mb-0">
                  <button
                     className={`btn btn-link text-dark font-weight-bold collapsible-link ${openEl == section.id ? 'selected' : ''}`}
                     type="button"
                     id={section.id}
                     onClick={handleSectionClick}
                  >
                     {section.title}
                  </button>
               </h2>
               <p className="ml-3 mb-5">{section.subtitle || section.description}</p>
            </div>
         }
         <div className="react-card-body card-body">
            {sortedQuestion.map((question, index) => {

               return (
                  <QuestonField
                     key={index}
                     needToTransferAnswerType={needToTransferAnswerType}
                     question={
                        propsAdapterInstructions ?
                           {
                              ...question,
                              ...propsAdapter(question, propsAdapterInstructions)
                           } :
                           question
                     }
                     value={formData[question.id] ? formData[question.id] : formData[question.answer_key]}
                     sectionId={section.id}
                     handleChange={handleChange}
                  />
               )
            })}
         </div>
      </div>
   )
}
export default QuestionsSection