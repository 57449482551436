import React, { useEffect } from 'react'

import Range from '../../shared/components/Range'
import Input from '../../shared/components/Input'
import Select from '../../shared/components/Select'
import InputRadioCheckbox from '../../shared/components/InputRadioCheckbox'
import Textarea from '../../shared/components/Textarea'
import Applied from '../../shared/components/Applied'
import Label from '../../shared/components/Label'
import CalendatInput from '../../shared/components/CalendarInput'
import CalendarRangeInput from '../../shared/components/CalendarRangeInput'
import EmotionSlider from '../../shared/components/emotionSlider/EmotionSlider'
import LabelField from '../../shared/components/LabelField'
import FullScreenImg from '../../shared/components/FullScreenImg'

import { exerciseEmotionsSwiperBreakpoints } from '../../helpers/swiperBreakpoints'
import { booleanQuestionOptions } from '../../helpers/questionsHelper'

const SdsQuestionField = ({
  question,
  handleChange,
  value,
  forLanding = false,
  clarifyInputData = null,
  applied,
  popUpDate = null,
  questionImg = null,
  needToTransferAnswerType = false,
  addictionQue, 
  setAddictionQue,
  formData
}) => {

  const handleEmotionChange = (id) => {
    const event = {
      target: {
        name: question.id,
        value: id
      }
    }

    handleChange(event, question.widget_type)
  }

  useEffect(() => {
    if(question.destination_column == 'addiction_id'){
      setAddictionQue(question.id)
    }
  }, [])
  

  const onChangeField = (event) => {
    handleChange(event, question.widget_type)
  }

  const renderLabel = () => (
    <>
      <Label 
        htmlFor={question.id} 
        label_text={question.question_label} 
        title = {question.title}
        label_img={questionImg} 
        html_label={question.html_label}
        required={question.required ? 'required' : ''}

      />
      {
        applied &&
        <Applied applied={applied} />
      }
      {
        question.content_image_url &&
        <FullScreenImg src={question.content_image_url} alt="question img" className="field__img" />
      }
    </>
  )

  switch (question.widget_type) {
    case "integer":
    case "range":
      if (question.max_value) {
        return (
          <div className="field">
            {
              renderLabel()
            }
            <Range
              value={value || 0}
              onChangeRange={needToTransferAnswerType ? onChangeField : handleChange}
              name={question.id}
              max={question.max_value}
              min={question.min_value ? question.min_value : 0}
              forLanding={forLanding}
            />
          </div>
        )
      } else {
        return (
          <div className="field">
            {
              renderLabel()
            }
            <Input
              value={value}
              onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
              name={question.id}
              inputType="number"
            />
          </div>
        )
      }
    case "text":
    case "string":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <Input
            value={value}
            onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
            inputType="text"
          />
        </div>
      )
    case "textarea":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <Textarea
            value={value}
            onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
            cols={10}
            rows={20}
          />
        </div>
      )
    case "selection":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <Select
            value={value}
            onChange={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
            options={question.question_options}
            clarifyInputData={clarifyInputData}
            popUpDate={popUpDate}

          />
        </div>
      )
    case "radio":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <InputRadioCheckbox
            type="radio"
            onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
            optionsList={question.question_options}
            applied={applied}
            value={value}
            ommitOption = {(formData[addictionQue] && question.id != addictionQue) ? formData[addictionQue] : null }

          />
        </div>
      )
    case "boolean":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <InputRadioCheckbox
            type="radio"
            onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
            optionsList={booleanQuestionOptions}
            applied={applied}
            value={value}
          />
        </div>
      )
    case "array":
    case "checkbox":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <InputRadioCheckbox
            type="checkbox"
            onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
            optionsList={question.question_options}
            clarifyInputData={clarifyInputData}
            applied={applied}
            value={value}
            ommitOption = {(formData[addictionQue] && question.id != addictionQue) ? formData[addictionQue] : null }

          />
        </div>
      )
    case "date":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <CalendatInput
            value={value}
            onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
          />
        </div>
      )
    case "date_range":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <CalendarRangeInput
            value={value || { start_date: null, end_date: null }}
            onChangeInput={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
          />
        </div>
      )
    case "img_question":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <EmotionSlider
            emotionsArray={question.emotions}
            emotionType='all'
            chosenEmotion={value}
            makeActive={(id) => handleEmotionChange(id)}
            breakpoints={exerciseEmotionsSwiperBreakpoints}
          />
        </div>
      )
    case "label":
      return (
        <div className="field">
          {
            renderLabel()
          }
          <LabelField
            text_with_fields={question.label}
            value={value || {}}
            onChangeLabelField={needToTransferAnswerType ? onChangeField : handleChange}
            name={question.id}
          />
        </div>
      )
    //not a question
    case "html_type":
      return (
        <div className="field">
          {
            renderLabel()
          }
        </div>
      )
    //not a question
    case "image":
      return (
        <div className="field">
          {
            renderLabel()
          }
        </div>
      )
    default:
      return (
        <></>
      )
  }
}

export default SdsQuestionField
