import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { getCurrentMonth } from '../../../../helpers/helperFunctions'

function AddictionalMonthChart({ addiction_progress_monthly, average_usage }) {

   useEffect(() => {
      renderChart()
   }, [])

   function renderChart() {

      //remove chart lib button 
      am4core.useTheme(am4themes_animated)
      am4core.addLicense('ch-custom-attribution')

      // Create chart instance
      var chart = am4core.create("addictionalMonthChart", am4charts.XYChart);

      // Add data
      chart.data = getChartData()

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      if (addiction_progress_monthly) {
         categoryAxis.startLocation = 0.5;
         categoryAxis.endLocation = 0.5;
      }
      categoryAxis.renderer.grid.template.stroke = am4core.color('#C4CFD4')
      categoryAxis.renderer.minGridDistance = 5
      //custom categoryAxis label font
      categoryAxis.renderer.labels.template.fontWeight = 600
      categoryAxis.renderer.labels.template.fontSize = 12
      categoryAxis.renderer.labels.template.fontFamily = 'Henderson Sans Basic, sans-serif';
      //hide categoryAxis bottom line
      categoryAxis.renderer.line.strokeOpacity = 1;
      categoryAxis.renderer.line.stroke = am4core.color("#fff");

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.max = getMaxValue()
      valueAxis.min = 0
      valueAxis.renderer.minGridDistance = 20
      valueAxis.strictMinMax = true;
      valueAxis.renderer.grid.template.disabled = true;
      // valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.strokeWidth = 0;
      //custom valueAxis label font
      valueAxis.renderer.labels.template.fontWeight = 400
      valueAxis.renderer.labels.template.fontSize = 12
      valueAxis.renderer.labels.template.fontFamily = 'Henderson Sans Basic, sans-serif';


      // Create series
      function createSeries(field, name, color, dasharray, isAverage) {
         var series = chart.series.push(new am4charts.LineSeries());
         series.dataFields.valueY = field;
         series.dataFields.categoryX = "month";
         series.name = name;
         series.strokeWidth = 3;
         series.tooltip.getFillFromObject = false;
         series.tooltip.background.fill = am4core.color("#fff")
         series.tooltip.background.cornerRadius = 50
         series.tooltip.background.strokeWidth = 0
         series.tooltip.pointerOrientation = 'down'
         let shadow = series.tooltip.background.filters.getIndex(0);
         shadow.dx = 0;
         shadow.dy = 2;
         shadow.blur = 22;
         shadow.color = am4core.color("rgba(0, 0, 0, 0.17);");
         if (name === "Average") {
            var tooltipHTML = `<p style="color: #151515; font-weight: 600; font-family: Montserrat; font-size: 12px; padding: 5px 10px; margin: 0;">{average}</p>`
            series.tooltipHTML = addiction_progress_monthly ? tooltipHTML : null
         } else {
            var tooltipHTML = `<p style="color: #151515; font-weight: 600; font-family: Montserrat; font-size: 12px; padding: 5px 10px; margin: 0;">{tooltipText}</p>`
            series.tooltipHTML = tooltipHTML
         }

         if (color) {
            series.stroke = am4core.color(color);
         }

         if (dasharray) {
            series.strokeDasharray = dasharray;
         }

         return series;
      }

      createSeries("value", "Addictional progress", "#0077B6", "6,3", false);
      createSeries("average", "Average", "#fcba03", "6,3", true);

      //add cursor
      chart.cursor = new am4charts.XYCursor();
      //disable cursor zoom
      chart.cursor.behavior = "none"
   }

   function getChartData() {
      if (!addiction_progress_monthly) {
         const month = getCurrentMonth()
         return [
            {
               "month": month.substring(0, 3),
               "value": 0,
               "average": average_usage,
               "tooltipText": "no data"
            }
         ]
      }
      return addiction_progress_monthly.map(({ month, value }) => ({
         "month": month.substring(0, 3),
         "value": value === "no data" ? 0 : value,
         "average": average_usage,
         "tooltipText": value
      }))
   }

   function getMaxValue() {
      const defaultMaxValue = 3
      if (!addiction_progress_monthly) return defaultMaxValue
      const maxValue = Math.max(...addiction_progress_monthly.map(({ value }) => value === 'no data' ? 0 : value))
      return maxValue === 0 ? defaultMaxValue : maxValue
   }

   return (
      <div className="card__chart-container">
         <div className="card__chart" id="addictionalMonthChart"></div>
      </div>
   )
}

export default AddictionalMonthChart
