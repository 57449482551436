import React from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import { Link } from 'react-router-dom'

import LeftArrow from '../../../../assets/images/arrow_navy_blue_left.svg'
import RightArrow from '../../../../assets/images/arrow-right.svg'
import BiBiCheck from '../../../../assets/images/bi-bi-check.svg'
import Now from '../../../../assets/images/now.svg'
import BiBiBlock from '../../../../assets/images/bi-bi-lock.svg'

function Stepper(props) {
  const stepNames = {
    1: 'Intro',
    2: 'Video',
    3: 'Worksheet',
    4: 'Summary',
  }

  const stepNameToRoute = {
    1: '/',
    2: '/video',
    3: '/worksheet',
    4: '/summary',
  }

  const getNextStepToRoute = () => stepNameToRoute[props.currentStep + 1] ? stepNameToRoute[props.currentStep + 1] : "/"
  const getPrevStepToRoute = () => stepNameToRoute[props.currentStep - 1] ? stepNameToRoute[props.currentStep - 1] : "/"

  const onNextStepLinkClick = (event) => {
    props.submitStep(props.currentStep + 1, props.currentStep)
  }

  const onPrevStepLinkClick = (event) => {
    if (props.currentStep === 1) {
      event.preventDefault()
    }
    props.submitStep(props.currentStep - 1, props.currentStep)
  }

  const onStepClick = (stepNumber) => {
    props.submitStep(stepNumber, props.currentStep)
  }

  const progressColor = (progress) => {
    let bg
    switch (true) {
      case progress <= 25:
        bg = '#E80000'
        break
      case progress <= 50 && progress > 25:
        bg = '#F5CCCD'
        break
      case progress > 50 && progress <= 75:
        bg = '#C9F1CA'
        break
      case progress > 75:
        bg = '#006610'
        break
    }
    return bg
  }

  const renderStepItems = () => {
    return [1, 2, 3, 4].map((n, index) => {
      let isCurrent = n == props.currentStep

      return (
        <div key={index} className="module-nav-items__item">
          <Link
            to={stepNameToRoute[n]}
            onClick={() => onStepClick(n)}
            className={`module-nav-items__status ${isCurrent ? 'active' : ''} ${n < props.currentStep ? 'done' : ''
              }`}
          >
            <div className="module-nav-items__item--icon">
              <h4>
                <img
                  src={isCurrent ? Now : n < props.currentStep ? BiBiCheck : BiBiBlock}
                  className="bi bi-check"
                  alt="steper"
                />
              </h4>
            </div>
          </Link>
          <h6 className="module-nav__text">{stepNames[n]}</h6>
        </div>
      )
    })
  }

  return (
    <div className="module-nav module-nav-progress">
      <div className="container__module--nav-mobile">
        <div className="module-nav-items">
          {renderStepItems()}
          <div className={`module-nav-items__line`}></div>
        </div>
        <div className="lesson-progress mobile">
          <ProgressBar
            completed={props.progress}
            bgColor={progressColor(props.progress)}
            height="15px"
            labelSize="10px"
            labelAlignment="right"
            transitionDuration="2s"
          />
        </div>

        <div className="module-nav--buttons-mobile">
          {
            props.currentStep === 1 ?
              <div className='btn_secondary'
                onClick={onPrevStepLinkClick}>
                <img className="btn_secondary-img" src={LeftArrow} alt="" /> Prev
              </div>
              :
              <button className='btn_secondary'>
                <Link to={getPrevStepToRoute()}
                  onClick={onPrevStepLinkClick}>
                  <img className="btn_secondary-img" src={LeftArrow} alt="" /> Prev
                </Link>
              </button>
          }
          {
            props.currentStep === 4 ?
              <div className='btn_secondary'
                onClick={onNextStepLinkClick}>
                Next <img className="btn_secondary-img next" src={RightArrow} alt="" />
              </div> :
              <button className='btn_secondary' onClick={onNextStepLinkClick}>
                <Link to={getNextStepToRoute()}>
                  Next <img className="btn_secondary-img next" src={RightArrow} alt="" />
                </Link>
              </button>
          }
        </div>
      </div>

      <div className="container__module--nav">
        {
          props.currentStep === 1 ?
            <div className='btn_secondary'
              onClick={onPrevStepLinkClick}>
              <img className="btn_secondary-img" src={LeftArrow} alt="" /> Previous
            </div>
            :
            <Link to={getPrevStepToRoute()}>
              <button className='btn_secondary'
                onClick={onPrevStepLinkClick}>
                <img className="btn_secondary-img" src={LeftArrow} alt="" /> Previous
              </button>
            </Link>
        }
        <div className="module-nav-items">
          {renderStepItems()}
          <div className="module-nav-items__line"></div>
        </div>
        {
          props.currentStep === 4 ?
            <div className='btn_secondary'
              onClick={onNextStepLinkClick}>
              Next <img className="btn_secondary-img next" src={RightArrow} alt="" />
            </div> :
            <Link to={getNextStepToRoute()}>
              <button className='btn_secondary' onClick={onNextStepLinkClick}>
                Next <img className="btn_secondary-img next" src={RightArrow} alt="" />
              </button>
            </Link>
        }
      </div>
      <div className="lesson-progress">
        <ProgressBar
          completed={props.progress}
          bgColor={progressColor(props.progress)}
          height="15px"
          labelSize="10px"
          labelAlignment="right"
          transitionDuration="2s"
        />
      </div>
    </div >
  )
}

export default Stepper