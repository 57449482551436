import React, { useEffect, useState } from 'react'

import { iconData_dashboard, wheelOfLiveCategoryesList } from '../../../helpers/wheelOfLiveHelper'
import WheelOfLiveChart from '../../../shared/components/WheelOfLiveChart'

function WheelOfLive({ this_months_scores, last_months_scores, show_summary_button, account}) {
  const [date_for_chart, setDateForChart] = useState({
    chart_data: null,
    last_month_active: true,
    current_month_active: true,
    subtraction: 0
  })
  const getChartData = (last_month_active, current_month_active) => last_month_active ?
    wheelOfLiveCategoryesList.map((category, index) => {
      const last_months_score = last_months_scores[index] ? last_months_scores[index] : 0
      return {
        ...category,
        current_value: current_month_active ? this_months_scores[index] - last_months_score : 0,
        last_value: last_months_score,
        current_value_label: this_months_scores[index],
      }
    }) :
    wheelOfLiveCategoryesList.map((category, index) => ({
      ...category,
      current_value: current_month_active ? this_months_scores[index] : 0,
      last_value: 0,
      current_value_label: this_months_scores[index],
    }))

  const handleCurrentMonthToggle = (last_month_active, current_month_active) => {
    const new_chart_data = getChartData(last_month_active, current_month_active)
    setDateForChart(prevState => ({
      chart_data: new_chart_data,
      last_month_active,
      current_month_active,
      subtraction: prevState.subtraction + 851
    }))
  }

  useEffect(() => {
    const new_chart_data = getChartData(true, true)
    setDateForChart(prevState => ({
      ...prevState,
      chart_data: new_chart_data
    }))
  }, [])

  return (
    <div id="wheel_of_life" className="card wheel_of_life results__wheelOfLife">
      <h4 className="results__card-title results__card-title_wheel">IGNTD Wheel of Life</h4>
      <div className="flex_to_center pb-4">
        {
          show_summary_button &&
          <a href="/summary" className="btn_primary">
            Go to my full assessment summary
          </a>
        }
      </div>
      {
        date_for_chart.chart_data &&
        <WheelOfLiveChart
          chartData={date_for_chart.chart_data}
          iconData={iconData_dashboard}
          onMonthToggle={handleCurrentMonthToggle}
          last_month_active={date_for_chart.last_month_active}
          current_month_active={date_for_chart.current_month_active}
          subtraction={date_for_chart.subtraction}
          with_prev_month={true}
        />
      }
      {  (this_months_scores?.length == last_months_scores?.length && last_months_scores?.length == 0) &&
        <div className='text-center mt-3'>
          <a   className="btn_primary" href='/assessments'>
            Take assessment
          </a>
        </div>
      }

    </div>
  )
}

export default WheelOfLive
