import React from 'react'

import LinkToStart from '../../../shared/buttons/LinkToStart'

import img from '../../../../assets/images/img-personalized.png'

const PersonalizedSect = () => {
  return (
    <div className="personalized">
      <div className="sum-page__wrapper">
        <div className="personalized__content">
          <div className="personalized__img-wrapper">
            <img src={img} alt="img" />
          </div>
          <div className="personalized__description-wrapper">
            <div className="personalized__description">
              <h3 className="personalized__subtitle">IGNTD Was Created to Help YOU!</h3>
              <h2 className="personalized__title">
                Below Are Your Personalized IGNTD Recommendations Based on Your Assessment
              </h2>
              <p className="personalized__sub-title">
                At IGNTD, we don’t believe there is ANY one-size-fits-all solution out there. Your specific struggles require a specific set of solutions. That’s why our workshops, groups, lessons and exercises are tailored to each participant - so YOU get the help YOU need to BREAK FREE FOREVER!              </p>
              <LinkToStart
                link="https://www.igntdrecovery.com/change-your-life-drinking-score1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalizedSect
