import React from 'react'

import Instruction from './Instruction'
import Introduction from './Introduction'
import Test from './Test'
import ProgressBorder from '../../components/ProgressBorder'

function ExerciseStep({ step, completedProfiles, completedTestsIdList, profile, authToken, changeStep, updateProgress }) {

   const sortedPages = getSortedPages()
   const activePage = sortedPages[step] || sortedPages[0]
   const isCompletedProfile = completedProfiles.includes(profile.id)
   const isCompletedTest = completedTestsIdList.includes(activePage.id)
   const stepsAmount = profile.pages.length - 1

   //a reset of this step is necessary
   if (!sortedPages[step]) {
      changeStep(0)
   }
   
   function getSortedPages() {
      const pages = [...profile.pages]
      if (pages <= 1) return pages
      return pages.sort((page, nextPage) => page.sort_order - nextPage.sort_order)
   }

   switch (activePage.page_type) {
      case 'introduction':
         return (
            <ProgressBorder
               isCompleted={isCompletedProfile}
               completedText="This exercise is completed!"
               uncompletedText="Please complete this exercise!"
            >
               <Introduction
                  profile={profile}
                  title={activePage.title}
                  text={activePage.subtitle}
                  stepsAmount={stepsAmount}
                  step={step}
                  changeStep={changeStep}
               />
            </ProgressBorder>
         )
      case 'instruction':
         return (
            <ProgressBorder
               isCompleted={isCompletedProfile}
               completedText="This exercise is completed!"
               uncompletedText="Please complete this exercise!">
               <Instruction
                  profile={profile}
                  title={activePage.title}
                  text={activePage.subtitle}
                  stepsAmount={stepsAmount}
                  step={step}
                  changeStep={changeStep} />
            </ProgressBorder>
         )
      case 'worksheet':
         return (
            <ProgressBorder
               isCompleted={isCompletedTest}
               completedText="Your answers are completed!"
               uncompletedText="Please complete your answers!">
               <Test
                  authToken={authToken}
                  test={activePage}
                  profile={profile}
                  stepsAmount={stepsAmount}
                  step={step}
                  changeStep={changeStep}
                  updateProgress={updateProgress}
               />
            </ProgressBorder>
         )
      default:
         return null
   }
}

export default ExerciseStep