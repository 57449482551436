import React from 'react'

function AddictionalColorMeanings({ colors }) {

   return (
      <div className='wrap_colors'>
         <div className="elem_to_center card__colors">
            {
               colors.map(({ color, text }, index) =>
                  <div key={index} className="card__colors-item">
                     <div style={{ backgroundColor: color }} className="card__colors-circle" />
                     <p className="card__colors-text">{text}</p>
                  </div>
               )
            }
         </div>

      </div>
   )
}

export default AddictionalColorMeanings