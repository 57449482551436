import React from 'react'

import ChapterSwitcherBtn from './ChapterSwitcherBtn'

function ChapterSwitcher({ activeChapter, handleActiveChapterChange, buttons }) {
   return (
      <div className="chapter-switcher">
         {
            buttons.map((button, index) =>
               <ChapterSwitcherBtn
                  key={index}
                  text={button.text}
                  id={button.id}
                  activeChapter={activeChapter}
                  handleActiveChapterChange={handleActiveChapterChange} />)
         }
      </div>
   )
}

export default ChapterSwitcher