import React, { useState } from 'react'

function ProgressBadges({ progress_badges_info }) {
  //const [hoverBageIndex, setHoverBageIndex] = useState(null)
  const [showBagePopUp, setShowBagePopUp] = useState(false)

  const handleMouseOver = () => {
    setShowBagePopUp(true)
  }


  return (
    <div className="card__half">
      <p>Here are the badges you are earned already. Keep going and discover + earn them all!</p>

      <div className="horizontal-flex">
        <div className="badges-table">
          {progress_badges_info.length ? progress_badges_info.map((badge, index) => (
            <div key={index} className="badge-item" data-title={badge.content}>
              {showBagePopUp && (
                <div className="adge-item__popup">
                  <span>{badge.content}</span>
                </div>
              )}
              <img src={badge.badge_image} alt="Bage" className="rounded img-badge reward-popup" />
              <span>{badge.reward_count > 1 ? badge.reward_count : ''}</span>
            </div>
          ))
            :
            <h6 className="mt-3">You don't have any badges yet</h6>
          }
        </div>
      </div>
    </div>
  )
}

export default ProgressBadges
