import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'

import SnippetCard from '../../../shared/components/SnippetCard'


function RecommedationsModal({ authToken, current_emotion, recommendation_snippets }) {

   const [show, setShow] = useState(false)

   useEffect(() => {
      if (window.location.href.includes('showRecommendations')) {
         setShow(true)
      }
   }, [])

   const closeModal = () => {
      window.location.href = "/dashboard"

   }

   return (
      <Modal
         centered={false}
         open={show}
         tabIndex="-1"
         role="dialog"
         size="large"
         dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
         className="recomendation_modal"
      >
         <div className="modal-content">
            <div className="modal-header">
               <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span className="closing_cross" aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="modal-body">
               <div className="modal-description">
                  <h3>Based on your inputs, we'd recommend these videos to help you with your {' '}
                     {current_emotion && current_emotion.name ? current_emotion.name : 'current struggles'}</h3>
                  <p>You can watch these now, or continue with the lesson as planned</p>
               </div>
               <div className="recommendation-videos">
                  {
                     recommendation_snippets.length ? recommendation_snippets.map((snipet) =>
                        <SnippetCard
                           key={snipet.snippet_id}
                           authToken={authToken}
                           snippet={{
                              ...snipet,
                              isFavorite: snipet['favorite?'],
                              ratable_type: "Snippet",
                              disabled_rating: false
                           }}
                        />
                     ) : null
                  }
               </div>
            </div>
         </div>
      </Modal>
   )
}

export default RecommedationsModal