import React from 'react'

import SpecificIssues from '../components/SpecificIssues'
import WheelOfLife from '../components/WheelOfLive'
import XYChart from '../components/XYChart'
import Speedometer from '../components/Speedometer'
import LifeScore from '../components/LifeScore'
import ComparisonChart from '../../../shared/components/ComparisonChart'

import DRINKING_SCORE from '../../../../assets/images/DRINKING_SCORE.png'

const ResultsSection = ({ wheelOfLifeData, life_score, speedo_meter_percentile, other_addiction_percentages, addiction_description, primary_addiction, current_user, usage_scores }) => {
  return (
    <div className="results">
      <div className="results__wrapper p-4">
        <div className="results__header pt-5 pb-5 mb-4">
          <h1 className="results__title ">Personal Assessment Profile </h1>
          {/* <h2 className="results__subtitle">
            A personalized summary + individualized recommendations to<br />
            eliminate your struggles, transform your world break free forever!
          </h2> */}
        </div>
        <div className="results__main p-3">
          <div className="results__block">
            <div className="results__card results__card_drinking-score">
              <div className="results__card_img_container">
                <img src={DRINKING_SCORE} alt="Drinking Score" />
              </div>
              <Speedometer speedo_meter_percentile={speedo_meter_percentile} />
              <div className="results__speedometer">
                <span className="results__value">0%</span>
                <span className="results__value">100%</span>
              </div>
              <p className="results__card-descripton">
                {addiction_description && addiction_description.short_intro && addiction_description.short_intro}
              </p>
              {current_user && 
                <div className='text-center mt-3'>
                  <a   className="btn_primary" href='/usage-assessment'>
                    Retake My Assessment 
                  </a>
              </div>

              }
            </div>
            <div className="results__card results__card_specific-issues">
              <SpecificIssues
                addiction_description={addiction_description}
              />
            </div>
          </div>

          {current_user &&
            <>
            {usage_scores?.length > 1 &&
              <div className='results__card my-4'>
                <ComparisonChart usage_scores = {usage_scores}/>
              </div>
            }
              <div className="results__block">
                <div className="results__card results__card_life-score">
                  <LifeScore life_score={life_score} />
                </div>
                <WheelOfLife this_months_scores={wheelOfLifeData} />
              </div>
              <div className="results__block ">
                <div className="results__card results__card_xychart">
                  <XYChart
                    other_addiction_percentages={other_addiction_percentages}
                    primary_addiction={primary_addiction}
                  />
                </div>
              </div>

            </>
          }

        </div>
      </div>
    </div>
  )
}

export default ResultsSection
