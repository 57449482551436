import { Chart } from 'chart.js'
import React, { useEffect, useState } from 'react'

import ClarifyField from './ClarifyField'
import Label from './Label'

import {
   removeValueFromState as removeValue,
   addValueToState as addValue
} from '../../helpers/helperFunctions'

function InputRadioCheckbox({ onChangeInput, name, optionsList, type, clarifyInputData = null, label_text, value, ommitOption }) {

   const isCheckbox = type === 'checkbox'
   const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
   const [clarifyValue, setClarifyValue] = useState('')
   const [activeClarifyIndices, setActiveClarifyIndices] = useState([])
   const [options, setOptions] = useState([])


   useEffect(() => {
      if(ommitOption !== undefined){
         const optionStrings =  optionsList?.map(({value, ...option}) => ({
   
            ...option, value: value.toString()
         }))
         setOptions(optionStrings)
      }else{
         setOptions(optionsList)
      }


      options?.map(option => {
         const optionValue = option.value ? option.value : option.text

         if (option.checked) {
            addValue(setSelectedCheckboxes, optionValue)
         }
      })
   }, [])

   console.log(options)
   useEffect(() => {
      onChangeInput({
         target: {
            name,
            value: selectedCheckboxes.length ? selectedCheckboxes : null
         }
      })
   }, [selectedCheckboxes])

   const isСarificationNeed = (value) => {
      if (!clarifyInputData) return false
      if (clarifyInputData.condition) {
         return clarifyInputData.condition === value
      } else {
         return true
      }
   }

   const onCheckboxChange = (event, index) => {
      const { value, checked } = event.target

      if (checked) {
         addValue(setSelectedCheckboxes, value)
         isСarificationNeed(value) && addValue(setActiveClarifyIndices, index)
         if (value === 'Other') {
            removeValue(setSelectedCheckboxes, clarifyValue)
         }
      } else {
         removeValue(setSelectedCheckboxes, value)
         isСarificationNeed(value) && removeValue(setActiveClarifyIndices, index)
         if (value === 'Other') {
            removeValue(setSelectedCheckboxes, clarifyValue)
         }
      }
   }

   const onChangeClarifyWithoutSeparateName = (event, defaultValue) => {
      const valueExist = selectedCheckboxes.includes(defaultValue)

      if (event.target.value.length && valueExist) {
         removeValue(setSelectedCheckboxes, defaultValue)
      }

      removeValue(setSelectedCheckboxes, clarifyValue)
      if (event.target.value.length === 0 && !valueExist) {
         addValue(setSelectedCheckboxes, defaultValue)
      } else {
         addValue(setSelectedCheckboxes, event.target.value)
      }
      setClarifyValue(event.target.value)
   }
   return (
      <>
         {
            label_text &&
            <Label htmlFor={name} label_text={label_text} />
         }
         <br />
         <div>
            
            {
               options?.map((option, index) => {
                  if( ommitOption === undefined || ommitOption != option.value ){
                     const optionValue = option.value ? option.value : option.text
                     const clarifyName = name === 'hear_about_igntd' ? `hear_from_${optionValue.replaceAll(' ', '_').replaceAll('/', '_').toLowerCase()}` : name
                     const withSeparateName = clarifyName !== name
                     return (
                        <div className="field__inputs-container" key={index}>
                           <div className="field__check-radio-container">
                              {
                                 isCheckbox ?
                                    <input
                                       type='checkbox'
                                       onChange={(event) => onCheckboxChange(event, index)}
                                       name={name}
                                       className="radio_button"
                                       value={optionValue}
                                       checked={
                                          value && Array.isArray(value) ? value.includes(optionValue)
                                             :
                                             optionValue === 'Other' ?
                                                selectedCheckboxes.includes(optionValue) || selectedCheckboxes.includes(clarifyValue)
                                                :
                                                selectedCheckboxes.includes(optionValue)}
                                    /> :
                                    <input
                                       type='radio'
                                       onChange={onChangeInput}
                                       name={name}
                                       className="radio_button"
                                       value={optionValue}
                                       checked={optionValue == value}
                                    />
                              }
                              <label htmlFor="rating" className={`field__label--${type}`}>
                                 {option.text ? option.text : option.value}
                              </label>
                           </div>
                           {
                              activeClarifyIndices.includes(index) &&
                              <ClarifyField
                                 text={clarifyInputData.text}
                                 clarifyName={clarifyName}
                                 defaultValue={withSeparateName ? '' : 'Other'}
                                 onChangeInput={withSeparateName ? onChangeInput : onChangeClarifyWithoutSeparateName}
                              />
                           }
                        </div>
                     )
                  }
               })
            }
         </div>
      </>
   )
}

export default InputRadioCheckbox