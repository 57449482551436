import React from 'react'
import ReactHtmlParser from 'react-html-parser'

function Label({ htmlFor, label_text, label_img, html_label, title, required }) {

   const getLabelText = () => {
      if (label_text) return label_text
      if (html_label) return html_label
      return " "
   }

   const text = getLabelText()

   return (
      <label htmlFor={htmlFor} className={`field__label ${required}`} >
         {label_img && <img className="mb-1" src={label_img} alt="label img" />}
         <div className='text'> 
            {/* {title && <span className="que-title">{title}</span>} */}
            {ReactHtmlParser(text)}
         </div> 
      </label>
   )
}

export default Label