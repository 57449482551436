import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import BiBiCheck from '../../../../assets/images/bi-bi-play.svg'
import LockedImg from '../../../../assets/images/locked.svg'
import CompletedImg from '../../../../assets/images/tick_navy_blue.svg'
import ActiveLessonImg from '../../../../assets/images/Triangle.svg'


export default function Sidenav(props) {

  const [openModuleIndex, setOpenModuleIndex] = useState(props.portions.findIndex(portion => portion.id === props.currentLesson.portion_id))

  function openModule(index, status) {
    if (status === "upcoming") return

    if (index === openModuleIndex) {
      setOpenModuleIndex(null)
      setTimeout(() => setOpenModuleIndex(index), 300)
      return
    }
    setOpenModuleIndex(index)
  }

  const checkClass = (lesson) => {
    const isCurrent = props.currentLesson.id == lesson.id
    return (isCurrent ? `current-${lesson.status}` : `past-${lesson.status}`)
  }
  const getModuleImg = (status) => {
    switch (status) {
      case 'upcoming':
        return LockedImg
      default:
        return CompletedImg
    }
  }
  const getLessonImg = (status) => {
    switch (status) {
      case 'incomplete':
        return ActiveLessonImg
      case 'complete':
        return CompletedImg
    }
  }

  return (
    <ul className="sidenav">
      {props.portions.map((portion, portionIndex) => {
        const isActive = portion.id === props.currentLesson.portion_id
        return (
          <li className="sidenav-accordion" key={portionIndex}>
            <div className="card-header">
              <button
                onClick={() => openModule(portionIndex, portion.status)}
                className={`btn btn-link sidenav-item ${isActive ? 'active' : portion.status}`}
              >
                <div className="split-title">
                  <h6 className="split-title__heading">Module {portion.order}</h6>
                  <div className="status-icon">
                    {
                      isActive ?
                        <h6 className="split-title__heading"><i className="fas fa-bookmark"></i> here</h6>
                        :
                        <img src={getModuleImg(portion.status)} />
                    }
                  </div>
                </div>
                <h5 className="split-title__module-name">{portion.title}</h5>
              </button>
            </div>
            <div className={openModuleIndex === portionIndex ? "lessons_list" : "lessons_list hide"}>
              <ul className="card-body">
                {portion.lessons.map((lesson, lessonIndex) => {

                  return (
                    <a key={lessonIndex} href={`/lesson/${lesson.id}`}>
                      <li
                        className={`sidenav-item sidenav-item--${checkClass(lesson)}`}
                      >
                        <div className="split-title">
                          <h6>Lesson {lessonIndex + 1}</h6>
                          <div className="status-icon">
                            {
                              props.currentLesson.id !== lesson.id &&
                              <img src={getLessonImg(lesson.status)} />
                            }
                          </div>
                        </div>
                        {/* /lesson/${lesson.id} */}
                        <h5 className="sidenav-item__title">
                          {lesson.title}
                        </h5>
                      </li>
                    </a>
                  )
                })}
              </ul>
            </div>
          </li>
        )
      })}
    </ul>
  )
}
