
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router'

import IntroFormQuestion from './IntroFormQuestion'

import { fetchIntakeQuestions } from '../../helpers/fetchHelper'

const IntroForm = ({ propsFromBackEnd, redirectSettings = { to: '/summary', withReactRouter: false } }) => {

  const history = useHistory()

  const [questionsData, setQuestionsData] = useState(null)
  const [formData, setFormData] = useState({})
  const [disable_next, setDisableNext] = useState(true)

  const isAllValuesNotEmpty = (obj) => Object.values(obj).every(value => value !== null)
  const checkFormFill = (formData) => isAllValuesNotEmpty(formData) ? setDisableNext(false) : setDisableNext(true)

  const getDefaultFormData = (data) => {
    if (data.question_type === "wheel_of_life") {
      setDisableNext(false)
      return data.questions.reduce((formData, question) => (formData[question.answer_key] = 0, formData), {});
    } else {
      return data.questions.reduce((formData, question) => (formData[question.answer_key] = null, formData), {});
    }
  }

  const gethIntakeQuestions = () => {
    fetchIntakeQuestions(propsFromBackEnd.authToken)
      .then(data => {
        const newFormData = getDefaultFormData(data)
        setFormData(newFormData)
        setQuestionsData(data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    gethIntakeQuestions()
  }, [])

  const redirectToAnotherPage = () => {
    if (redirectSettings.withReactRouter) {
      history.push(redirectSettings.to)
      window.location.reload()
    } else {
      window.location.href = redirectSettings.to
    }
  }

  const handleChange = (event, dedefault_value = null) => {
    const newFormData = {
      ...formData,
      [event.target.name]: event.target.value ? event.target.value : dedefault_value,
    }
    setFormData(newFormData)
    checkFormFill(newFormData)
  }

  const sendForm = (e) => {
    e.preventDefault()
    setDisableNext(true)
    const data = {
      portion: questionsData.portion,
      question_type: questionsData.question_type,
      button_type: 'next',
      answers: formData
    }
    fetch("/update-intake-assessment", {
      credentials: 'same-origin',
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': propsFromBackEnd.authToken,
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(({ portion, status }) => {
        if (status !== "success") return
        if (portion == 5) {
          redirectToAnotherPage()
        } else {
          gethIntakeQuestions()
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <form className="introform">
      <div className="introform__wrapper">
        <h2 className="introform__title">Get your free personalized report</h2>
        {
          questionsData &&
          <div className="intro__card">
            {
              questionsData.questions.map((question =>
                <IntroFormQuestion
                  forLanding={true}
                  key={question.id}
                  value={formData[question.answer_key] && formData[question.answer_key]}
                  handleChange={handleChange}
                  question={question} />
              ))
            }
          </div>
        }
        <div className="introform__button-container">
          <button
            className={`introform__button ${disable_next ? '' : 'active'}`}
            type="submit"
            onClick={sendForm}>Next</button>
        </div>
      </div>
    </form >
  )
}

export default IntroForm
