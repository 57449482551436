import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import { Subnav, Sidenav } from './components'
import RatingModal from './components/lessonPages/RatingModal'

import DoneModal from '../../shared/modals/DoneModal'
import Stepper from '../../shared/components/lessons/Stepper'
import TextContainer from '../../shared/components/lessons/TextContainer'
import VideoContainer from '../../shared/components/lessons/VideoContainer'
import Summary from '../../shared/components/lessons/Summary'
import WorksheetPage from './components/lessonPages/WorksheetPage'
import { Modal } from 'semantic-ui-react'
import Bookmark from '../../shared/buttons/Bookmark'

export default class LessonPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      progress: this.props.progress,
      rewards: [],
      showRating: false,
      assessmentType: this.props.assessment_type,
      linkToRedirect: '',
      activeExerciseIndex: (1),
      exerciseStep: 0,
      completedProfiles: this.getPreviouslyCompletedProfiles(),
      completedTests: this.getPreviouslyCompletedTests()
    }
  }

  componentDidMount() {
    switch (window.location.hash) {
      case '#video':
        this.setState({ currentStep: 2 })
        break
      case '#worksheet':
        this.setState({ currentStep: 3 })
        break
      case '#summary':
        this.setState({ currentStep: 4 })
        break
      default:
        this.setState({ currentStep: 1 })
        break
    }
    const exerciseStep = localStorage.getItem('exerciseStep')
    if (exerciseStep) {
      this.setState({ exerciseStep: Number(exerciseStep) })
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.exerciseStep != this.state.exerciseStep) {
      localStorage.setItem('exerciseStep', this.state.exerciseStep);
    }
  }

  getCurrentPortionFullData = () => this.props.portions.find(portion => this.props.currentOrder === portion.order)
  checkModuleCompleted = (lessons) => lessons.slice(0, lessons.length - 1).every(lesson => lesson.status === "complete") && this.state.progress >= 50

  closeModal = () => this.setState({ linkToRedirect: '' })
  getPreviouslyCompletedProfiles = () => this.props.profiles.filter(exercise => exercise['completed?']).map(exercise => exercise.id)

  getAllPages = () => this.props.profiles.map(profile => profile.pages).flat()
  getAllTestPages = () => this.getAllPages().filter(page => page.page_type === "worksheet")

  getPreviouslyCompletedTests = () => {
    const allTestsPages = this.getAllTestPages()
    return allTestsPages.filter(test => test["completed?"]).map(test => ({
      id: test.id,
      profile_id: test.profile_id
    }))
  }



  updateProgress = (value) => {
    this.setState({ progress: value })
  }

  getStep = (step, newStep) => {
    switch (step) {
      case 1:
        return (step < newStep) ? 'intro' : 'previous_lesson'
      case 2:
        return 'video'
      case 3:
        return 'worksheet'
      case 4:
        return 'next_lesson'
    }
  }

  redirectToDashboard = (next_path) => {
    const { lessons } = this.getCurrentPortionFullData()
    const isModuleCompleted = this.checkModuleCompleted(lessons)
    if (isModuleCompleted) {
      window.location.href = next_path
    } else {
      this.setState({ linkToRedirect: next_path })
    }
  }

  submitStep = (newStep, currentStep = this.state.currentStep) => {

    const stepName = this.getStep(currentStep, newStep)

    if (currentStep > newStep && stepName == 'next_lesson') {
      this.changeStep(newStep, this.state.progress)
    } else {

      fetch(`/lesson/${this.props.currentLesson.id}/${stepName}`, {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.props.authToken,
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (response.status > 204) {
            throw new Error('Not success')
          }
          return response.json()
        })
        .then((data) => {
          if (data.next_path) {
            if (data.next_path === '/') {
              this.redirectToDashboard(data.next_path)
              return
            }
            window.location.href = data.next_path
            return
          }
          if (data.previous_path) {
            window.location.href = data.previous_path
            return
          }
          this.submitWorksheet(data, newStep)
        })
        .catch((error) => console.log(error))
    }
  }

  submitWorksheet = (data, newStep) => {
    if (data.progress) this.changeStep(newStep, data.progress)
    if (data.rewards && data.rewards.length) {
      this.setState({ rewards: data.rewards })
    }
    if (data.show_rating) this.setState({ showRating: data.show_rating })
  }

  changeStep = (newStep, progress) => {
    if (newStep > 0 && newStep < 5) {
      this.setState({ currentStep: newStep, progress: progress })
    }
  }

  onNextExercise = () => {
    if (this.state.activeExerciseIndex === this.props.profiles.length) return
    this.setState({ activeExerciseIndex: this.state.activeExerciseIndex + 1 })
    this.setState({ exerciseStep: 0 })
  }

  onPrevExercise = () => {
    if (this.state.activeExerciseIndex === 1) return
    this.setState({ activeExerciseIndex: this.state.activeExerciseIndex - 1 })
    this.setState({ exerciseStep: 0 })
  }


  onCompleteTest = (profileId, testId) => {
    window.location.reload()

    const { completedProfiles, completedTests } = this.state

    document.querySelector("body").scrollTo(0, 0)

    if (this.checkIfProfileCompleted(profileId, testId)) {
      const newCompletedProfiles = [...completedProfiles, profileId]
      this.setState({
        completedProfiles: newCompletedProfiles
      })
    }
    if (!completedTests.map(obj => obj.id).includes(testId)) {
      const newCompletedTest = [...completedTests, {
        id: testId,
        profile_id: profileId
      }]
      this.setState({
        completedTests: newCompletedTest
      })
    }
  }

  changeExersiceStep = (newStep, profileId, completedTestId) => {

    this.setState({ exerciseStep: newStep })

    if (profileId || profileId === 0) {
      this.onCompleteTest(profileId, completedTestId)
    }
  }

  checkIfProfileCompleted(newProfileId, newTestId) {
    const { completedProfiles, completedTests } = this.state

    if (completedProfiles.includes(newProfileId) || completedTests.map(obj => obj.id).includes(newTestId)) return false

    const allProfileTestsPages = this.getAllTestPages().filter(test => test.profile_id === newProfileId)

    return this.filterByProfileAffiliation(allProfileTestsPages, newProfileId).length === this.filterByProfileAffiliation(completedTests, newProfileId).length + 1 ?
      true : false
  }

  filterByProfileAffiliation(arr, profileId) {
    return arr.filter(item => item.profile_id === profileId)
  }

  setSeenReward = (id) => {
    fetch('/api/v1/rewards', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.props.authToken,
      },
      body: JSON.stringify({
        user_reward_id: id,
        current_user_id: this.props.currentUser.id,
      }),
    })
      .then((response) => {
        let newRewards = this.state.rewards.filter((el) => el.id != id)
        this.setState({ rewards: newRewards })
      })
      .catch((error) => console.log(error))
  }

  render() {
    console.log(this.props)
    const allTestsPages = this.getAllTestPages()
    return (
      <HashRouter hashType="noslash">
        <Subnav
          currentOrder={this.props.currentOrder}
          currentPortion={this.props.currentPortion}
          currentLesson={this.props.currentLesson}
        />
        <div className="lesson-main-content">
          <Sidenav
            portions={this.props.portions}
            currentPortion={this.props.currentPortion}
            currentLesson={this.props.currentLesson}
          />
          <div className="module">
            <Stepper
              submitStep={this.submitStep}
              currentStep={this.state.currentStep}
              progress={this.state.progress}
            />
            <div className="container__module pt-5">
              <Bookmark
                isFavorite={this.props.currentLesson['favorite?']}
                authToken={this.props.authToken}
                contentType="Lesson"
                contentId={this.props.currentLesson.id}
              />
            </div>
            {this.state.rewards.length ? (
              <DoneModal reward={this.state.rewards[0]} setSeenReward={this.setSeenReward} />
            ) : (
              ''
            )}
            <Switch>
              <Route
                exact
                path="/"
                component={() => <TextContainer currentLesson={this.props.currentLesson} />}
              />

              <Route
                path="/video"
                component={() => (
                  <VideoContainer
                    currentUser={this.props.currentUser}
                    authToken={this.props.authToken}
                    currentLesson={this.props.currentLesson}
                    updateProgress={this.updateProgress}
                  />
                )}
              />

              <Route
                path="/worksheet"
                component={() => (
                  <WorksheetPage
                    currentLesson={this.props.currentLesson}
                    updateProgress={this.updateProgress}
                    authToken={this.props.authToken}
                    changeStep={this.changeStep}
                    assessmentType={this.state.assessmentType}
                    submitStep={this.submitStep}
                    profiles={this.props.profiles}
                    onNextExercise={this.onNextExercise}
                    onPrevExercise={this.onPrevExercise}
                    changeExersiceStep={this.changeExersiceStep}
                    activeExerciseIndex={this.state.activeExerciseIndex}
                    exerciseStep={this.state.exerciseStep}
                    completedProfiles={this.state.completedProfiles}
                    completedTests={this.state.completedTests}
                    allTestsPages={allTestsPages}
                  />
                )}
              />

              <Route
                path="/summary"
                component={() => (
                  <Summary
                    currentLesson={this.props.currentLesson}
                    updateProgress={this.updateProgress}
                    progress={this.state.progress}
                    authToken={this.props.authToken}
                    current_emotion={this.props.current_emotion}
                    additional_resources={this.props.additional_resources}
                    type="lesson"
                  />
                )}
              />
            </Switch>
          </div>
        </div>
        {this.state.showRating && !this.state.rewards.length && (
          <RatingModal
            type="lesson"
            authToken={this.props.authToken}
            currentLesson={this.props.currentLesson}
            closeModal={() => this.setState({ showRating: false })}
          />
        )}
        {
          this.state.linkToRedirect &&
          <Modal
            centered={false}
            open={open}
            tabIndex="-1"
            role="dialog"
            size='tiny'
            dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
            onClose={this.closeModal}
          >
            <p className="p-4">
              Lessons are marked complete when you finish watching the video and/or complete the lesson exercise(s)
            </p>
            <div className="flex-between pr-4 pl-4 pb-4">
              <a onClick={(e) => {
                e.preventDefault()
                this.closeModal()
              }} className="mr-3">Back to Lessons</a>
              <a href={this.state.linkToRedirect}>Go to Dashboard</a>
            </div>
          </Modal>
        }
      </HashRouter>
    )
  }
}
