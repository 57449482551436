import React from 'react'

import forum_img from '../../../../assets/images/forum.png'

function JoinDiscussion() {

   return (
      <div id="community" className="card__half">
         <h4>Join in on the discussion</h4>
         <p>Discuss topics with your fellow peers on our very own forum! Click below to start a conversation.</p>
         <div className="vertical-flex">
            <div className="image-wrapper"> 
               <img style={{ width: '70%' }} src={forum_img} alt="Forums" />
            </div>
            <div className="card__button">
               <a href="/community">
                  <button type="button" id="enter-community" className="btn_card">
                     <span aria-hidden="true">Enter the Community</span>
                  </button>
               </a>
            </div>
         </div>
      </div>
   )
}

export default JoinDiscussion
