import React, { useState, useEffect } from 'react'
import AvatarUploader from '../community/AvatarUploader'
import Button from '../community/Button'
import MobileNavigation from '../Dashboard/sections/MobileNavigation'
import Switch from "react-switch"
import FlashMessage from 'react-flash-message'
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import Input from '../../shared/components/Input'

const Profile = (props) => {
  const [editAvatar, setEditAvatar] = useState(false)
  const [userAvatar, setUserAvatar] = useState(props.current_user.avatar_url)
  const toggleAttr = ['notify_mentions', 'notify_reactions', 'notify_replies', 'allow_tagging']
  const [msg, setMsg] = useState(null)

  const [toggles, setToggles] = useState(null)
  const [currentProgram, setCurrentProgram] = useState(props.current_user.program_id)
  const [userhandle, setUserhandle] = useState(props.current_user.user_handle)

  useEffect(() => {
    if (props.settings.community) {
      setToggles(Object.keys(props.settings.community)
        .filter(key => toggleAttr.includes(key))
        .reduce((obj, key) => {
          obj[key] = props.settings.community[key]
          return obj
        }, {})
      )
    }
  }, [])

  useEffect(() => {
    if (userAvatar !== props.current_user.avatar_url) window.location.reload()
  }, [userAvatar])

  const submitSettings = () => {
    setMsg(null)
    var regex = /^[a-zA-Z0-9_]+$/

    if (!regex.test(userhandle)) {
      setTimeout(() => {
        setMsg(<div className='text-danger text-center'>Username can only contain letters, numbers, and underscore (_)</div>)
      }, 1000)
      return
    }

    fetch('/profile/validate_username', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': props.authToken,
      },
      body: JSON.stringify({
        user_handle: userhandle
      })
    }).then(response => response.json())
      .then(({ data }) => {
        if (data.error) {
          setTimeout(() => {
            setMsg(<div className='text-danger text-center'>{data.error}</div>)
          }, 1000)
          return
        } else {
          submitForm()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const submitForm = () => {
    fetch('/profile/settings', {
      credentials: 'same-origin',
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': props.authToken,
      },
      body: JSON.stringify({
        toggles: toggles,
        program_id: currentProgram,
        user_handle: userhandle,
        yes: 'no'
      })
    }).then(response => response.json())
      .then(() => {
        setTimeout(() => {
          setMsg(<div className='text-success text-center'>Settings Saved!</div>)
        }, 2000);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleNameChange = (e) => {
    setUserhandle(e.target.value)
  }

  return (
    <div className='profile-wrap'>
      <div className='card'>
        <div>
          <div className='upload-avatar text-center'>
            <div className='pb-4 mb-4'>
              {userAvatar && <img src={userAvatar} />}
            </div>
            <Button
              text='Change Picture'
              type='button'
              classes='text-center text-lower'
              disabled={false}
              handleClick={() => setEditAvatar(true)}
            />
          </div>
          {editAvatar &&
            <AvatarUploader
              authToken={props.authToken}
              setEditAvatar={setEditAvatar}
              setUserAvatar={setUserAvatar}
            />
          }
        </div>
        <br /><br />
        {props.settings.community &&
          <div className='settings'>
            <h3><span>Community Settings</span></h3>
            <div className='row'>
              <div className='col-sm-6'>
                <div className="toggle">
                  <h6>Allow others to Tag me</h6>
                  <Switch onChange={(value) => { setToggles({ ...toggles, allow_tagging: value }) }} checked={toggles?.allow_tagging || false} />
                </div>
                <div className="toggle">
                  <h6>Send email notifications when I am tagged</h6>
                  <Switch onChange={(value) => { setToggles({ ...toggles, notify_mentions: value }) }} checked={toggles?.notify_mentions || false} />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className="toggle">
                  <h6>Send email notifications on new replies</h6>
                  <Switch onChange={(value) => { setToggles({ ...toggles, notify_replies: value }) }} checked={toggles?.notify_replies || false} />
                </div>
                <div className="toggle">
                  <h6>Send email notifications on new reactions</h6>
                  <Switch onChange={(value) => { setToggles({ ...toggles, notify_reactions: value }) }} checked={toggles?.notify_reactions || false} />
                </div>
              </div>
              <div className="field">
                <InputLabel id="user_handle">User Name</InputLabel>
                <Input
                  value={userhandle}
                  onChangeInput={handleNameChange}
                  name='user_handle'
                  inputType="text"
                />
              </div>
            </div>
            {props.settings?.dashboard?.programs &&
              <div className='text-center'>
                <h3><span>Dashboard Settings</span></h3>
                <FormControl sx={{ m: 1, minWidth: 300 }} >
                  <InputLabel id="switch-program">Switch Program</InputLabel>
                  <Select
                    labelId="switch-program"
                    id="switch-program"
                    value={currentProgram}
                    onChange={(e) => setCurrentProgram(e.target.value)}
                    input={<OutlinedInput label="switch-program" />}
                  >
                    {props.settings.dashboard.programs.map((program) => (
                      <MenuItem
                        key={program.id}
                        value={program.id}
                      >
                        {program.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            }
            <br />
            {msg &&
              <FlashMessage duration={5000} persistOnHover={true} className='text-center mb-3'>
                {msg}
              </FlashMessage>
            }
            <br />
            <Button
              text='Save Settings'
              type='button'
              classes='text-center text-lower'
              disabled={false}
              handleClick={submitSettings}
            />
          </div>
        }
      </div>
      <MobileNavigation role_access={props.role_access} />
    </div>
  )
}

export default Profile