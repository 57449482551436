import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'

import alert__success from '../../../../assets/images/alert__success.png'

function SummaryModal({ summary_modal_info }) {

   const [show, setShow] = useState(false)

   useEffect(() => {
      if (window.location.href.includes('showSummary')) {
         setShow(true)
      }
   }, [])

   const closeModal = () => {
      setShow(false)
   }

   return (
      <Modal
         centered={false}
         open={show}
         tabIndex="-1"
         role="dialog"
         size='small'
         dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
      >
         <div className="modal-content">
            <div className="modal-header">
               <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span className="closing_cross" aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="modal-body">
               <div className="module__alert--modal">
                  <img src={alert__success} alt="Great Job" />
                  <div className="module__title--alert">
                     <h3>Great Job!</h3>
                  </div>
                  <p>Based on your answers, here are the areas of your life you should work on this month</p>
               </div>
               <div className="priorities">
                  <div className="priorities__title">
                     <h4><b>Highest Functioning</b></h4>
                  </div>
                  <div className="priorities__list">
                     <div className="priorities__list--item-primary">
                        <h6>{summary_modal_info.highest_life_area}</h6>
                     </div>
                  </div>
                  <br />
                  <br />

                  <div className="priorities__title">
                     <h4><b>Focus Areas</b></h4>
                  </div>
                  <div className="priorities__list">
                     <div className="priorities__list--item-secondary">
                        <h6>{summary_modal_info.first_focus_area}</h6>
                     </div>
                     <div className="priorities__list--item-tertiary">
                        <h6>{summary_modal_info.second_focus_area}</h6>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Modal>
   )
}

export default SummaryModal