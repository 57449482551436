import React from 'react'

import Label from './Label'

function Input({ value, onChangeInput, name, inputType, label_text }) {

  const onChangeNumberInput = (event) => {
    if (event.target.value < 0) return
    onChangeInput(event)
  }

  return (
    <>
      {
        label_text &&
        <Label htmlFor={name} label_text={label_text} />
      }
      <input
        className="text_field"
        type={inputType}
        value={value === null ? "" : value}
        onChange={inputType === 'number' ? onChangeNumberInput : onChangeInput}
        name={name}
        id={name}
      />
    </>
  )
}

export default Input
