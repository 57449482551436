import React, { useState, useEffect } from 'react'
import LessonBadge from '../../../assets/images/lesson_complete.png'
import ModuleBadge from '../../../assets/images/module_complete.png'
import Fire from '../../../assets/images/fire.svg'
import Confetti from 'react-confetti'
import './modal.scss'
import { Modal } from 'semantic-ui-react'

function DoneModal(props) {

   const onlyFire = props.reward.state == "invisable" ? true : false

   const [show, setShow] = useState(true)

   function closeModal() {
      props.setSeenReward(props.reward.id)
      setShow(false)
   }

   useEffect(() => {
      if (onlyFire === true) {
         let timer = setTimeout(() => closeModal(), 5000);
         return () => {
            clearTimeout(timer);
         };
      }
   }, [])

   return (
      <>
         <div className="confetti_wrapper">
            <Confetti
               gravity={0.04}
               numberOfPieces={520}
               initialVelocityX={6}
               initialVelocityY={7}
               confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight / 2, w: 7, h: 10 }}
               recycle={false}
               className="reward-confetti"
            />
         </div>
         {
            onlyFire ?
               <Modal
                  basic
                  onClose={() => closeModal()}
                  onOpen={() => setShow(true)}
                  open={show}
                  size='small'
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                  className="reward-modal"

               >
                  <div className="points-fire">
                     <img src={Fire} alt="Fire img" />
                     <span>{props.reward.user_point["points"] || props.reward.reward.default_points}</span>
                     <h2 className="mt-2">You've earned<br /> +{props.reward.user_point["points"] || props.reward.reward.default_points} points!</h2>
                  </div>
               </Modal>
               :
               <Modal
                  basic
                  onClose={() => closeModal()}
                  onOpen={() => setShow(true)}
                  open={show}
                  size='tiny'
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                  className="reward-modal"
               >
                  <div className="modal-content badges">
                     <div className="modal-header">
                        <button
                           type="button"
                           className="close"
                           onClick={() => closeModal()}
                        >
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div className="modal-body">
                        <img className="badge_img" height="200px" src={props.reward.reward.short_name == "lesson_complete" ? LessonBadge : ModuleBadge} alt="Bage img" />
                        <h2 className="modal-title mx-auto" id="exampleModalLabel">Well done!</h2>
                        <p className="points-description">
                           {
                              props.reward.text ?
                                 `${props.reward.text}` :
                                 props.reward.reward.short_name != "lesson_complete" && props.reward.reward.short_name != "workshop_complete" ?
                                    `You've earned a badge for ${props.reward.reward.description} ${props.reward.count > 0 && props.reward.count}` :
                                    `You've earned a badge for ${props.reward.reward.description}`
                           }
                        </p>
                        {
                           !props.reward.text &&
                           <div className="points-fire mt-5">
                              <img src={Fire} alt="Fire img" />
                              <span>{props.reward.user_point["points"] || props.reward.reward.default_points}</span>
                           </div>
                        }
                     </div>
                  </div>
               </Modal>
         }
      </>
   )
}

export default DoneModal
