import React, {useState, useEffect, useContext} from 'react'
import ReactHtmlParser from 'react-html-parser'
import NewComment from './NewComment'
import ReactionsModal from './ReactionsModal'
import { reactionsContext } from './CommunityPage'
import UserAvatar from 'react-user-avatar'
import EditComment from './EditComment'

var hdate = require('human-date')

const Comments = ({comment_lists, authToken, current_user}) => {
    const [comments, setComments] = useState({})
    const [commentsList, setCommentsList] = useState(comment_lists)
    const [showComments, setShowComments] = useState(false)
    const [currentCommentId, setCurrentCommentId] = useState(0)
    const [showAddComment, setShowAddComment] = useState(false)

    const [showDropDown, setShowDropDown] = useState(false)
    const [editable, setEditable] = useState(null)
    const [editComment, setEditComment] = useState(false)
    const colors = ['#f4dcb0', '#98b6ca', '#cf9494', '#a6d2a9', '#b1e2ff', '#ebbc44']

    

    const [showAddReactions, setShowAddReactions] = useState(false)
    const [newReactions, setNewReactions] = useState(null)
    const [emojis, currentPostId] = useContext(reactionsContext)

    const hideOptions = () => {
        setShowComments(false)
        setShowAddComment(false)

        setShowDropDown(false)
        setShowAddReactions(false)

    }
    
    const handleCommentToggle = (comment_id)=>{
        if(currentCommentId == comment_id){
            setShowDropDown(!showDropDown)
        }else{
            setCurrentCommentId(comment_id)
            hideOptions()
            setShowDropDown(true)
        }
    }

    const handleCommentEdit = (comment) => {
        hideOptions()
        setEditable(comment)
        setEditComment(true)
    }

    const addComment = (comment_id) => {
        setEditComment(null)
        setCurrentCommentId(comment_id)
        hideOptions()
        setShowAddComment(true)
    }

    const getComments = (comment_id) =>{
            setShowAddComment(false)
            setCurrentCommentId(comment_id)
            if(showComments && comments[0]?.commentable_id == comment_id){
                setShowComments(false)
                return
            }
    
            fetch(`/comments/${comment_id}/comments`, {
              credentials: 'same-origin',
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  'X-CSRF-Token': authToken,
              }
            })
            .then((response) => {
                if (response.status > 204) {
                  throw new Error('Not success')
                }
                return response.json()
              })
              .then(({comments_list}) => {
                if(comments_list?.length > 0){
                    setComments(comments_list)
                    setShowComments(true)
                }
                
              })
            .catch(err => {
              console.log(err)
            })
        
    }


    const addReaction = (comment_id) => {
        setCurrentCommentId(comment_id)
        setShowAddReactions(true)
    }

    useEffect(() => {
        if(newReactions){
            const newState = commentsList.map(comm => {
                if (comm.id === currentCommentId) {
                  return {...comm, reactions: newReactions}
                }
                return comm
              });
            setCommentsList(newState)
        }
    }, [newReactions])

    const sortReactions = (reactions) => {
        var emoji_groups = []
        reactions.map((react) => {
            if(emoji_groups[react.emoji_id]){
                emoji_groups[react.emoji_id].count += 1
            }else{
                emoji_groups[react.emoji_id] = {...react.emoji, count: 1}
            }
        })
        return emoji_groups
    }

    const handleDelete = (comment_id) =>{
        if (confirm("Proceed to delete comment?") == false) return

        fetch(`/comments/${comment_id}`, {
            credentials: 'same-origin',
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': authToken,
            }
          })
          .then((response) => {
              if (response.status > 204) {
                throw new Error('Not success')
              }
              return response.json()
            })
            .then(() => {
                setCommentsList(commentsList.filter((comment)=> comment.id !== comment_id))
            })
          .catch(err => {
            console.log(err)
          })
    }




  return (
    <ul className="comment-wrap list-unstyled">
        {commentsList.map((comment) => (
            
            <div 
            className="comment" 
            key={comment.id}
            >
                <div className="post-header border-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="user-avatar">
                                {comment.user.avatar_url ? 
                                    <UserAvatar size="32"
                                    name={comment.user.user_handle}
                                    src={comment.user.avatar_url}
                                    />
                                :
                                    <UserAvatar size="32" name={comment.user.user_handle}
                                    colors={colors}
                                    />
                                }
                            </div>
                            <div>
                            <div className="user_details">
                                <h6 className="name">{comment.user.user_handle}
                                <span className="posted_at small float-right"> {hdate.relativeTime(comment.created_at)}</span>
                                </h6>
        
                            </div>
                            {/* <p className="mb-0 small">Web Developer at Webestica</p> */}
                            </div>
                        </div>
                        <div className="dropdown">
                                <span 
                                    onClick={()=>handleCommentToggle(comment.id)} 
                                    className="text-secondary btn btn-secondary-soft-hover py-1 px-2 show" 
                                    aria-expanded="true"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 128 512">
                                    <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/></svg>
                                 </span>

                                {showDropDown && comment.id == currentCommentId &&
                                <ul className="post-drop-down show">
                                    {current_user.id == comment.user.id &&
                                    <>
                                        <li 
                                        className='post-item'
                                        onClick={()=>handleCommentEdit(comment)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                            <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/></svg>
                                            &nbsp;  &nbsp;Edit
                                        </li>
                                        <li 
                                        className='post-item'
                                        onClick={()=>handleDelete(comment.id)}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                            <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                                        </svg>
                                            &nbsp;  &nbsp;Delete
                                        </li>
                                    </>
                                    }
                                    <li className="post-item" onClick={()=>addComment(comment.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                    <path d="M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z"/>
                                            </svg>
                                        &nbsp;  &nbsp;Reply
                                    </li>
                                </ul>
                            }

                        </div>
                    </div>
                </div>
                <div className="comment-body">
                    {editComment && comment.id === editable?.id ?
                        <EditComment
                            setCommentables={setCommentsList}
                            commentables = {commentsList}
                            authToken = {authToken}
                            comment = {editable}
                            setEditComment = {setEditComment}
                            setEditable = {setEditable}
                            current_user={current_user}
                        />
                    :
                        <div className='comment-content'>{ReactHtmlParser(comment.content)}</div>
                    }

                    <div className='reactions'>
                                { sortReactions(comment.reactions).map((emoji) => (
                                    <span key={emoji.id}>{emoji.e_character} <i>{emoji.count}</i></span>
                                ))}
                    </div>
                    <ul className=" nav-stack small d-flex">
                        <li className="post-item" onClick={() => addReaction(comment.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                        </li>
                        { comment.comments_count > 0 &&
                            <li className="post-item"
                            >
                                <span className="post-link" onClick={()=>getComments(comment.id)}> 
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                             <path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/>
                                    </svg>&nbsp;{comment.comments_count}</span>
                            </li>
                        }
                    </ul>
                    {   showAddComment && currentCommentId == comment.id && 
                            <NewComment
                            setCommentables={setCommentsList}
                            commentables = {commentsList}
                            commentableId = {currentCommentId}
                            authToken = {authToken}
                            setShowAddComment = {setShowAddComment}
                            type = 'comments'
                            currentPostId={currentPostId}
                            current_user={current_user}
                            />
                    }
                    {   showComments && comments[0]?.commentable_id == comment.id &&
                        <Comments
                            comment_lists = {comments}
                            authToken={authToken}
                            current_user={current_user}
                        />
                    } 
                </div>
            </div>
        ))}
        {showAddReactions &&
            <ReactionsModal
            emojis = {emojis}
            reactionable_id = {currentCommentId}
            authToken = {authToken}
            type = 'comments'
            hideOptions={hideOptions}
            setNewReactions={setNewReactions}
            currentPostId = {currentPostId}
            />
        }

    </ul>

  )
}

export default Comments