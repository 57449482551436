import React from 'react'

import sponsors1 from '../../../../assets/images/sponsors1.svg'
import sponsors2 from '../../../../assets/images/sponsors2.svg'

const Sponsors = () => {
    return (
        <section className="sponsors">
            <div className="sponsors__content">
                <div className="sponsors__img-wrapper">
                    <img src={sponsors1} alt='sponsors' />
                </div>
                <div className="sponsors__img-wrapper">
                    <img src={sponsors2} alt='sponsors' />
                </div>
            </div>
        </section>
    )
}

export default Sponsors;