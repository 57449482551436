import React from 'react'

import CardAdapter from '../../../bundles/SummaryPage/components/CardAdapter'

function DeepenResources({ additional_resources, authToken }) {

   return (
      additional_resources.length ?
         <section className="deepen_resources">
            <h3>
               Here are additional resources to help extend & deepen the concepts covered in this lesson
            </h3>
            <div className="deepen_resources__card-container">
               {
                  additional_resources.map((card, index) =>
                     <CardAdapter
                        ratable_type="AdditionalResource"
                        authToken={authToken}
                        key={index}
                        disabled_rating={false}
                        {...card}
                        style="blue"
                        isSummary={true} />
                  )}
            </div>
         </section>
         :
         null
   )
}

export default DeepenResources
