import React from 'react'

const ScoreCard = (props) => {
  return (
    <div className={`scoreCard__wrapper ${props.isSpecial ? 'scoreCard__wrapper_special' : ''}`}>
      <h3 className={`scoreCard__title ${props.isSpecial ? 'scoreCard__title_special' : ''}`}>
        {props.title}
      </h3>
      <ul className="scoreCard__list">
        {props.areas.map((item, i) => {
          if (!item) return null
          return (
            <li
              key={i}
              className={`scoreCard__list-item ${props.isSpecial ? 'scoreCard__list-item_special' : ''
                }`}
            >
              <img src={item.icon} alt="icon" />
              <p>{item.title}</p>
            </li>
          )
        }
        )}
      </ul>
    </div>
  )
}

export default ScoreCard
