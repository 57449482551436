import React from 'react'
import { HashRouter } from 'react-router-dom'
import { install } from 'resize-observer'

import Dashboard from './Dashboard'

if (!window.ResizeObserver) install()

function DashboardPage(props) {

  return (
    <HashRouter hashType="noslash">
      <Dashboard {...props} />
    </HashRouter>
  )
}

export default DashboardPage
