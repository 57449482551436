import React from 'react'
import { Modal } from 'semantic-ui-react'

const AnnouncementModal = ({ current_user, showAnnouncementModal, setShowAnnouncementModal }) => {

  const markAsViewed = () => {
    fetch('/announcement_viewed')
      .then(response => response.json())
      .then(() => {
        setShowAnnouncementModal(false)
      }
      )
  }
  return (
    <Modal
      centered={true}
      open={showAnnouncementModal}
      tabIndex="-1"
      role="dialog"
      size="small"
      dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
      className='announcement'
    >
      <Modal.Header>
        <button onClick={() => markAsViewed()} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span className="closing_cross" aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h4>Great news!</h4>
          <h5>You can now personalize your username that is displayed in the community forum.</h5>
          <br />
          <p>By default, it's the first part of your email address, <b>'{current_user.user_handle}'</b>, which can
            easily change in your <a href="/profile">profile settings</a>.
          </p>
          <br />
          <p>
            Make your username uniquely yours and enhance your community experience today!</p>

        </Modal.Description>
      </Modal.Content>

    </Modal>
  )
}

export default AnnouncementModal