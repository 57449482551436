import React, { useState } from 'react'
import { Rating } from 'semantic-ui-react'
import { postRating, putRating } from '../../helpers/fetchHelper'

function CustomRating({
   average_rating,
   already_rated,
   ratable_id,
   ratable_type,
   disabled = true,
   authToken,
   onRate }) {

   const [rating, setRating] = useState(average_rating)
   const [rated, setRated] = useState(already_rated)

   const sendRating = (rating) => {

      const body = {
         ratable_type,
         ratable_id,
         rating
      }

      if (rated) {
         putRating(authToken, body, ratable_id)
      } else {
         postRating(authToken, body)
         setRated(true)
      }
   }

   const handleRatingChange = (_, { rating }) => {
      if (onRate) {
         onRate(_, { rating })
      } else {
         sendRating(rating)
      }
      setRating(rating)
   }

   return (
      <Rating
         icon="star"
         defaultRating={rating}
         maxRating={5}
         size="huge"
         disabled={disabled}
         onRate={handleRatingChange}
      />
   )
}

export default CustomRating