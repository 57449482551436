import React, {useState, useEffect, useRef} from 'react'
import ContentArea from './ContentArea'
import FlashMessage from 'react-flash-message'

const NewComment = ({authToken, commentables, setCommentables, commentableId, setShowAddComment, type, currentPostId, current_user}) => {
  const [taggedUsers, setTaggedUsers] = useState([])
  const [taggedGroups, setTaggedGroups] = useState([])
  const contentEditableRef = useRef(null)
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState(null)

  useEffect(() => {
    if(!submit || loading ) return
    // setLoading(true)
    setSubmit(false)
    fetch(`/${type}/${commentableId}/comments`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
          "Content-Type": "application/json",
          'X-CSRF-Token': authToken,
      }, 
      body: JSON.stringify({
        content: contentEditableRef.current.innerHTML, 
        tagged: taggedUsers,
        custom_tags: taggedGroups,
        currentPostId: currentPostId,
      })
    }).then(response => response.json())
        .then(({status}) => {
          if (status === 201) {
            setMsg(<div className='text-success text-center'>Comment Saved!</div>)
            setTimeout(()=> {
              const newState = commentables.map(post => {
                if (post.id === commentableId) {
                return {...post, comments_count: post.comments_count + 1}
                }
                return post
              })
              setLoading(false)
              setCommentables(newState)
              setShowAddComment(false)
            }, 
             1000
            )
          }

        })
        .catch(err => {
          console.log(err)
        })
  }, [submit])
  

    
  return (
    <div className="d-flex">
        <form 
        className=" post-item w-100 position-relative comment-form"
        onSubmit={(e)=>{
          e.preventDefault()
          setSubmit(true)
        }}
        >
          <ContentArea
          setTaggedUsers={setTaggedUsers}
          taggedUsers={taggedUsers}
          setTaggedGroups = { setTaggedGroups}
          taggedGroups = { taggedGroups}
          contentEditableRef={contentEditableRef}
          setSubmit={setSubmit}
          authToken={authToken}
          current_user={current_user}
          />
          {msg && 
            <FlashMessage duration={2000} persistOnHover={true} className='text-center mb-3'>
              {msg}
            </FlashMessage>
          }
          <button 
          type="submit"
          >
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
              <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"/>
          </svg>
          </button>
        </form>
    </div>
  )
}

export default NewComment