import React, { useEffect, useRef } from 'react'
import Vimeo from '@u-wave/react-vimeo'

function Video({ video, onCloseVideo }) {
   const videoRef = useRef()

   function useOnClickOutside(ref, handler) {
      useEffect(() => {
         const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
               return
            }
            handler(event)
         }
         document.addEventListener('mousedown', listener)
         document.addEventListener('touchstart', listener)
         return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
         }
      }, [ref, handler])
   }

   useOnClickOutside(videoRef, onCloseVideo)

   return (
      <div ref={videoRef} className="pop_up__video">
         <Vimeo video={video} />
      </div>
   )
}

export default Video