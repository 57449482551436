import React, {useState} from 'react'
import { Modal} from 'semantic-ui-react'

function GroupsCalendar({ calendar_img, showCalendar, setShowCalendar }) {

   return (
      <div>
         <h4 className='section_header'>
            <span onClick={()=>  setShowCalendar(true)} className="calendar-icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
               </svg>
            </span>&nbsp;
            Upcoming Meetings
         </h4>
      <Modal
        centered={true}
        open={showCalendar}
        tabIndex="-1"
        role="dialog"
        size="small"
        dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
        className='badges_modal'
      >
        <Modal.Header className='zindex-modal-header pb-3'>
         <h4>Our Groups Calendar
            <button onClick={()=>  setShowCalendar(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span className="closing_cross" aria-hidden="true">&times;</span>
            </button>
         </h4>
        </Modal.Header>
        <Modal.Content >
          <Modal.Description >
          <div className="card card-calendar">
               <div className="vertical-flex vertical-flex-workshop">
                  <img src={calendar_img} alt="Calendar" style={{ width: '100%' }} className="calendar-image" />
               </div>
            </div>
          </Modal.Description>
        </Modal.Content> 
      </Modal>
      </div>

   )
}

export default GroupsCalendar