import React, {useState, useEffect, useContext} from 'react'
import ReactHtmlParser from 'react-html-parser'
import Comments from './Comments'
import NewComment from './NewComment'
import ReactionsModal from './ReactionsModal'
import UserAvatar from 'react-user-avatar'
import { colors } from '../../helpers/Colors'
import './Community.css'
import { reactionsContext } from './CommunityPage'
import Loader from './Loader'
import BackIcon from '../../../assets/images/back_icon.svg'

var hdate = require('human-date')
const PostView = ({authToken, current_user, currentNotification, setNotificationsView}) => {
  const [post_id, setPost_id] = useState(currentNotification.comm_post_id)
  const [emojis] = useContext(reactionsContext) 
  const [comments, setComments] = useState({})
  const [showComments, setShowComments] = useState(false)
  const [showAddComment, setShowAddComment] = useState(false)
  const [communityPosts, setCommunityPosts] = useState(null)
  
  const [loading, setLoading] = useState(true)
  const [showAddReactions, setShowAddReactions] = useState(false)
  const [newReactions, setNewReactions] = useState(null)

  const [showDropDown, setShowDropDown] = useState(false)

  const PostClick = (e) => {
    e.preventDefault()
      if(showComments == showAddReactions){
          hideOptions()
          getComments()
      }else{
          hideOptions()
      }
  }

  const handlePostToggle = (e)=>{
      e.stopPropagation()
      setShowDropDown(!showDropDown)
  }

  const hideOptions = ()=>{
      setShowComments(false)
      setShowAddComment(false)

      setShowAddReactions(false)
      setShowDropDown(false)

  }

  const handlePostEdit = (e)=>{
      e.stopPropagation()
      setPost(post)
      hideOptions
  }

  const addReaction = (e) => {
      e.stopPropagation()
      hideOptions
      setShowAddReactions(true)
  }

  useEffect(() => {
      if(newReactions){
          hideOptions
          setCommunityPosts([{...communityPosts[0], reactions: newReactions}])
      }
  }, [newReactions])

  const sortReactions = (reactions) => {
      var emoji_groups = []
      reactions?.map((react) => {
          if(emoji_groups[react.emoji_id]){
              emoji_groups[react.emoji_id].count += 1
          }else{
              emoji_groups[react.emoji_id] = {...react.emoji, count: 1}
          }
      })
      return emoji_groups
  }

  const addComment = (e) => {
      e.stopPropagation()
      setShowAddComment(true)
      setShowComments(false)
      setShowDropDown(false)
  }

  const getComments = () =>{
          setShowAddComment(false)
          if(showComments ){
              setShowComments(false)
              return
          }
          fetch(`/comm_posts/${post_id}/comments`, {
            credentials: 'same-origin',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': authToken,
            }
          })
          .then((response) => {
              if (response.status > 204) {
                throw new Error('Not success')
              }
              return response.json()
            })
            .then(({comments_list}) => {
              if(comments_list?.length > 0){
                  setComments(comments_list)
                  setLoading(false)
                  setShowComments(true)
              }
              
            })
          .catch(err => {
            console.log(err)
          })
      
  }

   
  const [post, setPost] = useState(null)
  
  useEffect(() => {
    fetch(`/comm_posts/${currentNotification.id}/post`, {
      credentials: 'same-origin',
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          'X-CSRF-Token': authToken,
      }
    })
    .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        }
        return response.json()
      })
      .then(({comm_post}) => {
          setPost(comm_post)
          setCommunityPosts[comm_post]
          getComments()
          setLoading(false)
      })
    .catch(err => {
      console.log(err)
    })
  }, [])
  

    

  return (
    <div className='post-viewer comm_post'>
        <div className='back' onClick={()=>setNotificationsView(false)}>
            <img src={BackIcon} alt="Back" />&nbsp;&nbsp;<h6>BACK</h6>
        </div>
      {post && 
        <div className='post'>
            <div>
                <div className="post-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between py-2 ">
                        <div className="d-flex align-items-center">
                            <div className="user-avatar pr-3">
                                {post.user.avatar_url ? 
                                    <UserAvatar size="48"
                                    name={post.user.user_handle}
                                    src={post.user.avatar_url}
                                    />
                                :
                                    <UserAvatar size="48" name={post.user.user_handle}
                                    colors={colors}
                                    />
                                }
                            </div>
                            <div>
                            <div className="user_details">
                                <h6 className="name mb-0"> {post.user.user_handle}
                                    <span className="posted_at small float-right"> {hdate.relativeTime(post.created_at)}</span>
                                </h6>
    
                            </div>
                            {/* <p className="mb-0 small">Web Developer at Webestica</p> */}
                            </div>
                        </div>
                        {current_user.id == post.user.id &&
                            <div className="dropdown">
                                <span 
                                    onClick={(e)=>handlePostToggle(e)} 
                                    className="text-secondary btn btn-secondary-soft-hover py-1 px-2 show" 
                                    aria-expanded="true"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 128 512">
                                    <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/></svg>
                                </span>

                                {showDropDown && 
                                    <ul className="post-drop-down show">
                                        
                                        <li 
                                        className='post-item'
                                        onClick={(e)=>handlePostEdit(e)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                            <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/></svg>
                                            &nbsp;  &nbsp;Edit
                                        </li>
                                    </ul>
                                }

                            </div>

                        }
                        
                    </div>
                </div>
                <div className="post-body pt-4" >
                    <div onClick={(e)=>PostClick(e)} style={{'cursor': 'pointer'}}>
                        <h3 className='title'>{post.title}</h3>
                        <div className='content pb-4 pt-2'>{ReactHtmlParser(post.content)}</div>
                        {post.comm_tags?.length > 0 && 
                            <div className='tag-people p-2 pb-4'>
                                <div className='row pb-4'>
                                <div className='tagged-list col-11'>
                                    {post.comm_tags.map((tagged) => (
                                    <div className='tagged_user' key={tagged.id}>
                                        <p>{tagged.user.user_handle}</p>
                                    </div>
                                    ))}
                                </div>
                                </div>
                            </div>

                        }
                        
                        <div className='reactions'>
                            { sortReactions(post.reactions)?.map((emoji) => (
                                <span key={emoji.id}>{emoji.e_character} <i>{emoji.count}</i></span>
                            ))}
                        </div>

                        <ul className=" nav-stack py-3 small d-flex">
                            <li className="post-item" onClick={(e) => addReaction(e)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                            </li>
                            <li className="post-item">
                                <span className="post-link" onClick={(e)=>addComment(e)} > Post a reply...</span>
                            </li>
                            {post.comments_count > 0 &&
                                <li className="post-item comment-count">
                                    <span className="post-link" onClick={(e)=>{
                                      e.preventDefault()
                                      getComments()
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                            <path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/>
                                    </svg> &nbsp;{post.comments_count}</span>
                                </li>

                            }

                        </ul>
                    </div>


                    { showAddComment ? 
                        <NewComment
                        setCommentables={setCommunityPosts}
                        commentables = {communityPosts}
                        commentableId = {post_id}
                        authToken = {authToken}
                        setShowAddComment = {setShowAddComment}
                        type = 'comm_posts'
                        currentPostId = {post_id}
                        
                        />
                    : 
                        <div>
                            {
                              showComments &&
                                <reactionsContext.Provider value={[emojis, post_id]}>
                                <Comments
                                    comment_lists = {comments}
                                    authToken={authToken}
                                    current_user={current_user}
                                />
                                </reactionsContext.Provider>
                            }
                        </div>
                    }          
    
                </div>
            </div>
            {showAddReactions &&
                <ReactionsModal
                emojis = {emojis}
                reactionable_id = {post_id}
                authToken = {authToken}
                type = 'comm_posts'
                hideOptions={hideOptions}
                setNewReactions={setNewReactions}
                currentPostId = {post_id}
                />
            }
        </div>
      }
      {loading &&
        <div className='posts-loading'>
            <Loader
                type="circle"
            />
        </div>
      }
    </div>
  )
}

export default PostView