import React, { useState, useEffect } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import RatingModal from '../LessonPage/components/lessonPages/RatingModal'
import DoneModal from '../../shared/modals/DoneModal'
import Stepper from '../../shared/components/lessons/Stepper'
import Summary from '../../shared/components/lessons/Summary'
import TextContainer from '../../shared/components/lessons/TextContainer'
import VideoContainer from '../../shared/components/lessons/VideoContainer'
import WorkshopWorksheet from './components/WorkshopWorksheet'
import Bookmark from '../../shared/buttons/Bookmark'
import MobileNavigation from '../Dashboard/sections/MobileNavigation'

const Workshop = (props) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [progress, setProgress] = useState(props.progress)
  const [rewards, setRewards] = useState([])
  const [showRating, setShowRating] = useState(false)

  useEffect(() => {
    switch (window.location.hash) {
      case '#video':
        setCurrentStep(2)
        break
      case '#worksheet':
        setCurrentStep(3)
        break
      case '#summary':
        setCurrentStep(4)
        break
      default:
        setCurrentStep(1)
        break
    }
  }, [])

  const updateProgress = (value) => {
    setProgress(value)
  }

  const changeStep = (newStep, progress) => {
    if (newStep > 0 && newStep < 5) {
      setCurrentStep(newStep)
      setProgress(progress)
    }
  }
  const submitWorksheet = (data, newStep) => {
    if (data.progress) changeStep(newStep, data.progress)
    if (data.rewards && data.rewards.length) {
      setRewards(data.rewards)
    }
    if (data.show_rating) setShowRating(data.show_rating)
  }

  const submitStep = (newStep, currentStep = currentStep) => {
    let stepName
    switch (currentStep) {
      case 1:
        stepName = 'intro'
        break
      case 2:
        stepName = 'video'
        break
      case 3:
        stepName = 'worksheet'
        break
      case 4:
        stepName = 'next_lesson'
        break
      default:
    }
    if (stepName === 'intro' && newStep < currentStep || stepName === 'next_lesson' && newStep > currentStep) {
      window.location.href = '/workshops'
    }
    if (currentStep > newStep && stepName == 'next_lesson') {
      changeStep(newStep, progress)
    } else {
      fetch(`/lesson/${props.currentLesson.id}/${stepName}`, {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.authToken,
        },
        body: JSON.stringify({}),
      })
        .then((response) => {
          if (response.status > 204) {
            throw new Error('Not success')
          }
          return response.json()
        })
        .then((data) => {
          submitWorksheet(data, newStep)
        })
        .catch((error) => console.log(error))
    }
  }

  const setSeenReward = (id) => {
    fetch('/api/v1/rewards', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
      body: JSON.stringify({
        user_reward_id: id,
        current_user_id: props.currentUser.id,
      }),
    })
      .then((response) => {
        let newRewards = rewards.filter((el) => el.id != id)
        setRewards(newRewards)
      })
      .catch((error) => console.log(error))
  }

  const renderEntry = () => {
    return (
      <>
        <Switch>
          <Route
            exact
            path="/"
            component={() => <TextContainer currentLesson={props.currentLesson} isWorkshop={true} />}
          />

          <Route
            path="/video"
            component={() => (
              <VideoContainer
                currentUser={props.currentUser}
                authToken={props.authToken}
                currentLesson={props.currentLesson}
                updateProgress={updateProgress}
                isWorkshop={true}
              />
            )}
          />

          <Route
            path="/worksheet"
            component={() => (
              <WorkshopWorksheet
                currentUser={props.currentUser}
                authToken={props.authToken}
                currentLesson={props.currentLesson}
                changeStep={changeStep}
                updateProgress={updateProgress}
                submitStep={submitStep}
                isWorkshop={true}
              />
            )}
          />

          <Route
            path="/summary"
            component={() => (
              <Summary
                currentLesson={props.currentLesson}
                updateProgress={updateProgress}
                progress={progress}
                authToken={props.authToken}
                isWorkshop={true}
                current_emotion={props.current_emotion}
                additional_resources={props.additional_resources}
                type="workshop"
              />
            )}
          />
        </Switch>
        <MobileNavigation
          role_access={props.role_access}
        />
        </>
    )
  }

  return (
    <HashRouter hashType="noslash">
      <div className="module pb-5 mb-0 mb-md-5">
        <Stepper submitStep={submitStep} currentStep={currentStep} progress={progress} />
        <div className="container__module pt-5">
          <Bookmark
            isFavorite={props.currentLesson['favorite?']}
            authToken={props.authToken}
            contentType="Lesson"
            contentId={props.currentLesson.id}
          />
        </div>
        {renderEntry()}
      </div>
      {rewards.length ? (
        <DoneModal reward={rewards[0]} setSeenReward={setSeenReward} />
      ) : (
        ''
      )}
      {showRating && !rewards.length && (
        <RatingModal
          type="workshop"
          authToken={props.authToken}
          currentLesson={props.currentLesson}
          closeModal={() => setShowRating(false)}
        />
      )}
    </HashRouter>
  )
}

export default Workshop
