import React, { useEffect } from 'react'
import { Pagination } from 'semantic-ui-react'

import LeftArrow from '../../../assets/images/arrow-o-left.svg'
import RightArrow from '../../../assets/images/arrow-o-right.svg'

function CustomPagination({ pageAmount, activePage, handlePageChange }) {

   function getImg(imgSrc) {
      return <img style={{ width: '6px', height: '12px' }} src={imgSrc} />
   }

   function onPageChange(_, { activePage }) {
      document.querySelector("body").scrollTo(0, 0)
      handlePageChange(activePage)
   }

   return (
      <Pagination
         activePage={activePage}
         totalPages={pageAmount}
         onPageChange={onPageChange}
         //customizing
         siblingRange={0}
         firstItem={false}
         lastItem={false}
         prevItem={{
            content: getImg(LeftArrow)
         }}
         nextItem={{
            content: getImg(RightArrow)
         }}
      />
   )
}

export default CustomPagination