export const chapterButtons = [
   {
      id: 'favorite_lessons',
      text: 'Lessons'
   },
   {
      id: 'favorite_workshops',
      text: 'Workshops'
   },
   // {
   //    id: 'exercise',
   //    text: 'Exercise'
   // },
   {
      id: 'favorite_snippets',
      text: 'Snippets'
   },
   {
      id: 'favorite_additional_resources',
      text: 'Resources'
   }
]