import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)

const ComparisonChart = ({usage_scores}) => {
    const convertDateToExpectedFormat = (dateString) =>{
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    }
    
  useEffect(() => {
    // Create a chart instance
    const chart = am4core.create('comparison_div', am4charts.XYChart)
    chart.colors.list = [
        am4core.color('#E7683B'),
        am4core.color('#D23D26'),
     ]

    // Add data
    chart.data = usage_scores.map((score) => (
        { date: convertDateToExpectedFormat(score.real_created_at), value: score.usage_score }
    ))

    // Create date axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.dataFields.category = 'date'

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    // Create a line series
    const series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.dateX = 'date'
    series.dataFields.valueY = 'value'
    series.tooltipText = 'Date: {dateX.formatDate("yyyy-MM-dd")}\nValue: {valueY}'
    series.bullets.push(new am4charts.CircleBullet()); 

    // Add a cursor
    chart.cursor = new am4charts.XYCursor()

    dateAxis.title.text = 'Date';
    valueAxis.title.text = 'Score Result';

    // Start the animation
    chart.events.on('ready', () => {
    chart.animate({ opacity: 1 }, 1000); // Animation duration: 1000ms (1 second)
    });

    return () => {
      chart.dispose() // Clean up when the component unmounts
    }
  }, [])

  return(
    <div>
        <h2 className="results__card-title results__card-title_wheel">
            Your Drinking Score Progress
        </h2>
        <div id="comparison_div" style={{ width: '100%', height: '400px' }}></div>
    </div>
  )
}

export default ComparisonChart
