function getFetchOptions(authToken, method, body) {
   const options = {
      credentials: 'same-origin',
      method,
      headers: {
         'Content-Type': 'application/json',
         'X-CSRF-Token': authToken,
      }
   }
   if (body) {
      options.body = JSON.stringify(body)
   }
   return options
}

export function fetchIntakeQuestions(authToken) {
   return fetch('/intake_questions',
      {
         credentials: 'same-origin',
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authToken,
         },
      })
      .then(resp => resp.json())
}

export function postRating(authToken, body) {
   return fetch('/rates', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         'X-CSRF-Token': authToken,
      },
      body: JSON.stringify(body),
   })
}

export function putRating(authToken, body, id) {
   return fetch(`/rates/${id}`, {
      credentials: 'same-origin',
      method: 'PUT',
      headers: {
         'Content-Type': 'application/json',
         'X-CSRF-Token': authToken,
      },
      body: JSON.stringify(body),
   })
}

export async function postTest(authToken, body, lesson_id, exerciseId, pageId) {
   try {
      const response = await fetch(`/lesson/${lesson_id}/exercise-upload?profile_id=${exerciseId}&page_id=${pageId}`, {
         credentials: 'same-origin',
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authToken,
         },
         body: JSON.stringify(body),
      })
      if (!response.ok) throw Error('failed to load test')
      const data = await response.json()
      return Promise.resolve(data)
   } catch (err) {
      return Promise.reject(err.message)
   }
}

export async function postFavorite(authToken, body) {
   try {
      const response = await fetch('/favorites', getFetchOptions(authToken, "POST", body))
      if (!response.ok) throw Error('error when adding to favorites')
      const data = await response.json()
      return Promise.resolve(data)
   } catch (err) {
      return Promise.reject(err.message)
   }
}

export async function deleteFavorite(authToken, contentType, contentId) {
   try {
      const response = await fetch(`/favorites/${contentId}?favoritable_id=${contentId}&favoritable_type=${contentType}`,
         getFetchOptions(authToken, "DELETE"))

      if (!response.ok) throw Error('error when deleting from favorites')
      const data = await response.json()
      return Promise.resolve(data)
   } catch (err) {
      return Promise.reject(err.message)
   }
}

export async function fetchFavoritesPortion(authToken, favoriteType, page) {
   try {
      const response = await fetch(`/favorites/${favoriteType}/next_page?page=${page}`, getFetchOptions(authToken, "GET"))
      if (!response.ok) throw Error('error when try to get favorites')
      const data = await response.json()
      return Promise.resolve(data)
   } catch (err) {
      return Promise.reject(err.message)
   }
}

export async function postSnippetWatched(authToken, body) {
   try {
      const response = await fetch('/create_user_snippet', getFetchOptions(authToken, "POST", body))
      if (!response.ok) throw Error('error sending snippet view')
      const data = await response.json()
      return Promise.resolve(data)
   } catch (err) {
      return Promise.reject(err.message)
   }
}
