import React from 'react'
import { Swiper } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
SwiperCore.use([Navigation, Pagination])
function CustomCarousel({
  className = '',
  navigation = false,
  pagination = false,
  prevEl,
  nextEl,
  children,
  slidesPerView = 3,
  slidesPerGroup = 1,
  highlighted = false,
  centeredSlides = true,
  breakpoints = null
}) {
  return (
    <div className={navigation ? 'swiper-summary' : ''}>
      {navigation ? <button className="swiper-button-prev" id={prevEl} /> : null}
      <Swiper
        className={`mySwiper ${className ? className : ''}`}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={100}
        autoplay={{
          delay: 2500,
        }}
        centeredSlides={centeredSlides}
        pagination={
          pagination
            ? {
              clickable: true,
            }
            : false
        }
        autoHeight={highlighted ? true : false}
        navigation={
          navigation
            ? {
              prevEl: `#${prevEl}`,
              nextEl: `#${nextEl}`,
            }
            : false
        }
        loop={true}
        loopFillGroupWithBlank={true}
        breakpoints={breakpoints || {
          1026: {
            slidesPerView: `${slidesPerView}`,
            spaceBetween: 20,
            slidesPerGroup: `${slidesPerGroup}`,
          },
        }}
      >
        {children}
      </Swiper>
      {navigation ? <button className="swiper-button-next" id={nextEl} /> : null}
    </div>
  )
}

export default CustomCarousel
