import React, { useState, useEffect } from 'react'

import DeepenResources from './DeepenResources'
import SnippetCard from '../SnippetCard'

import sucsess from '../../../../assets/images/alert__success.png'

export default function Summary(props) {

  const [videos, setVideos] = useState([])

  useEffect(() => {
    if (!videos.length) {
      getRecomendations()
    }
  }, [])


  const getRecomendations = () => {
    fetch(`/lesson/${props.currentLesson.id}/recomendation_videos`, {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': props.authToken,
      },
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        }
        return response.json()
      })
      .then((data) => {
        setVideos(data.videos)
      })
      .catch((error) => console.log(error))
  }
  return (
    <div className="module">
      <div className="container__module">
        <div className="module__alert">
          <div className="module__title--alert">
            <img className="module__title-img" src={sucsess} alt="" />
            <h3>Great Job!</h3>
          </div>
          {props.progress == 100 ?
            (<p className="module__title-header">You have successfully completed the {props.type}</p>)
            :
            <p className="module__title-main-text">
              You’re doing a fantastic job moving forward on your Hero Journey! Remember to watch all
              of the {props.type}s and upload all of your Exercises to complete each {props.type}, get your badges
              and complete all levels!
            </p>
          }
        </div>
        <div className="module__content">
          <div className="module__content--recommendations">
            <h3 className="module__content--recomend-title">
              Based on your inputs, we'd recommend these videos to help you with your {props.current_emotion && props.current_emotion.name}
            </h3>
            <p className="module__content--recomend-info">
              You can watch these now, or continue with the {props.type} as planned.
            </p>
            <div className="recommendation-videos">
              {videos.map((snipet) => {
                return (
                  <SnippetCard
                    key={snipet.snippet_id}
                    authToken={props.authToken}
                    snippet={{
                      ...snipet,
                      isFavorite: snipet['favorite?'],
                      ratable_type: "Snippet",
                      disabled_rating: false
                    }}
                  />
                )
              })}
            </div>
          </div>
          {
            Array.isArray(props.additional_resources) &&
            <DeepenResources
              additional_resources={props.additional_resources}
              authToken={props.authToken}
            />
          }
          <div className="module__content--next-steps">
            <h3>Up next...</h3>
            <div className="next-steps">
              <div className="flex-wrapper">
                <div className="next-steps__link">
                  <h4 className="next-steps-title">Go Home</h4>
                  <p className="next-steps-main-text">Find out where you are in your journey.</p>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="CloseclassName="
                    className="btn_primary full-width"
                    onClick={() => window.location.href = "/"}
                  >
                    Go Home
                  </button>
                </div>
              </div>

              <div className="flex-wrapper">
                <div className="next-steps__link">
                  <h4 className="next-steps-title">Forums</h4>
                  <p className="next-steps-main-text">
                    Go to the forums & discuss your experience.
                  </p>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="CloseclassName="
                    className="btn_primary full-width"
                    onClick={() => window.location.href = "/community"}

                  >
                    Discuss
                  </button>
                </div>
              </div>

              <div className="flex-wrapper">
                <div className="next-steps__link">
                  <h4 className="next-steps-title">End Session</h4>
                  <p className="next-steps-main-text">Well done! End session and exit the site.</p>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="CloseclassName="
                    className="btn_primary full-width"
                    onClick={() => window.location.href = "/users/sign_out"}
                  >
                    End Session
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
