import React from 'react'

const GetCard = (props) => {
  return (
    <div className="card">
      <h3 className="card__title">{props.title}</h3>
      <p className="card__description">{props.description}</p>
    </div>
  )
}

export default GetCard
