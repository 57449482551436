import React from 'react'

import ClientsSpeakSummary from './sections/ClientsSpeakSummary'
import HighlightedTools from './sections/HighlightedTools'
import PersonalizedSect from './sections/PersonalizedSect'
import ResultsSection from './sections/ResultsSection'
import RewiewHeader from './sections/ReviewHeader'
import MobileNavigation from '../Dashboard/sections/MobileNavigation'

const SummaryPage = (props) => {
  console.log(props)
  return (
    <>
      <ResultsSection
        wheelOfLifeData={props.summaryDetails.wheel_of_life_scores}
        life_score={props.summaryDetails.IGNTD_life_score}
        speedo_meter_percentile={props.summaryDetails.speedo_meter_percentile}
        other_addiction_percentages={props.summaryDetails.other_addiction_percentages}
        addiction_description={props.summaryDetails.addiction_description}
        primary_addiction={props.summaryDetails.primary_addiction}
        current_user = {props.current_user}
        usage_scores={props.summaryDetails.usage_scores}
      />
      {!props.current_user &&
        <PersonalizedSect />
      }
      {props.IGNTD_tools?.length > 0 &&
        <HighlightedTools
        loginCount={props.loginCount}
        dynamic_tools={props.summaryDetails.IGNTD_tools}
      />
      }
      {!props.current_user  &&
        <>
          <RewiewHeader />
          <ClientsSpeakSummary reviews={props.summaryDetails.reviews} />
        </>
      }
      <MobileNavigation
        role_access={props.role_access}
      />
    </>
  )
}

export default SummaryPage
