import React, { useState, useEffect, useRef } from 'react'
import UserAvatar from 'react-user-avatar'
import { colors } from '../../helpers/Colors'
import ReactHtmlParser from 'react-html-parser'


const ContentArea = ({ setTaggedUsers, taggedUsers, setTaggedGroups, taggedGroups, contentEditableRef, setSubmit, authToken, current_user, defaultText }) => {
  const [tagsList, setTagsList] = useState(null)
  const [groupsList, setGroupsList] = useState(null)

  const [tagStarts, setTagStarts] = useState([])
  const [tagEnds, setTagEnds] = useState([])

  const [groupStarts, setGroupStarts] = useState([])
  const [groupEnds, setGroupEnds] = useState([])

  const [loading, setLoading] = useState(false)
  const [commandMode, setCommandMode] = useState(false)

  const placeholder = "type '@' to tag users ..."

  const handleKeyDown = (event) => {

    if (event.key === 'Enter') setSubmit(true)
    if (event.key == " " || event.code == "Space" || event.keyCode == 32) setCommandMode(false)

    if (event.key === "Backspace" || event.key === "Delete") {
      if (contentEditableRef.current.innerHTML?.length <= tagEnds[tagEnds.length - 1]) {
        contentEditableRef.current.innerHTML = contentEditableRef.current.innerHTML.slice(0, tagStarts[tagStarts.length - 1])

        tagEnds.pop()
        tagStarts.pop()
        setTaggedUsers(taggedUsers.slice(0, taggedUsers?.length - 1))

        moveCursor()
      } else if (contentEditableRef.current.innerHTML?.length <= groupEnds[groupEnds.length - 1]) {
        contentEditableRef.current.innerHTML = contentEditableRef.current.innerHTML.slice(0, groupStarts[groupStarts.length - 1])

        groupEnds.pop()
        groupStarts.pop()
        setTaggedGroups(taggedGroups.slice(0, taggedGroups?.length - 1))
        moveCursor()
      }
    }

  }

  const previousContentRef = useRef('')

  const handleContentChange = (e) => {
    let currentContent = ''

    if (contentEditableRef.current.innerHTML !== previousContentRef.current) {
      const tempContent = contentEditableRef.current.innerHTML
      if (e.type === 'focus') {
        if (tempContent.substring(0, placeholder?.length) === placeholder) {
          previousContentRef.current = tempContent.substring(placeholder?.length)
          contentEditableRef.current.innerHTML = tempContent.substring(placeholder?.length)
        }
        return
      } else {
        currentContent = contentEditableRef.current.innerHTML
      }

      previousContentRef.current = currentContent

      if (currentContent[currentContent?.length - 1] == "@" &&
        (currentContent?.length == 1 || currentContent[currentContent?.length - 2] == " " || currentContent[currentContent?.length - 2] == ";")) {
        if (!commandMode) {
          setCommandMode(true)
          if (!loading) getTagList("")
        }

      } else if (commandMode) {
        getTagList(currentContent.substring(currentContent.lastIndexOf('@') + 1))
      }
    }
  }

  const updateTaggedUsers = (user) => {
    if (!taggedUsers.includes(user.id)) {
      setTaggedUsers([...taggedUsers, user.id])
      setCommandMode(false)
      setTagsList(null)

      const text = contentEditableRef.current.innerHTML.slice(0, contentEditableRef.current.innerHTML.lastIndexOf('@'))
      contentEditableRef.current.innerHTML = text + `@<span className='user_tag'>${user.user_handle}</span>&nbsp;`
      setTagStarts([...tagStarts, text?.length + 2])
      setTagEnds([...tagEnds, contentEditableRef.current.innerHTML?.length])

      moveCursor()


    }
  }

  const updateTaggedGroups = (tag) => {
    if (!taggedGroups.includes(tag.id)) {
      setTaggedGroups([...taggedGroups, tag.id])
      setCommandMode(false)
      setGroupsList(null)

      const text = contentEditableRef.current.innerHTML.slice(0, contentEditableRef.current.innerHTML.lastIndexOf('@'))
      contentEditableRef.current.innerHTML = text + `@<span className='group_tag'>${tag.code_value}</span>&nbsp;`
      setGroupStarts([...groupStarts, text?.length + 2])
      setGroupEnds([...groupEnds, contentEditableRef.current.innerHTML?.length])

      moveCursor()
    }
  }

  const moveCursor = () => {
    contentEditableRef.current.focus()
    const range = document.createRange()

    range.selectNodeContents(contentEditableRef.current)
    range.collapse(false) // Collapse the range to the end

    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }

  const getTagList = (search_term) => {
    setLoading(true)
    fetch(`/comm_posts/@${search_term}/comm_tags`, {
      credentials: 'same-origin',
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': authToken,
      }
    })
      .then((response) => {
        if (response.status > 204) {
          throw new Error('Not success')
        }
        return response.json()
      })
      .then(({ tags_list, custom_tags_list }) => {
        setTagsList(tags_list)
        setGroupsList(custom_tags_list)
        setLoading(false)

      })
      .catch(err => {
        console.log(err)
      })
  }
  return (
    <div>
      {commandMode &&
        <div className='tags'>
          {(tagsList?.length > 0 || groupsList?.length > 0) &&
            <ul>
              {tagsList?.map((user, index) => (
                <li onClick={() => updateTaggedUsers(user)} key={index}>
                  <div className='tag-avatar'>
                    {user.avatar_url ?
                      <UserAvatar size="30"
                        name={user.user_handle}
                        src={user.avatar_url}
                      />
                      :
                      <UserAvatar size="30" name={user.user_handle}
                        colors={colors}
                      />
                    }
                  </div>
                  {user.user_handle} </li>
              ))
              }
              {groupsList?.map((tag, index) => (
                <li onClick={() => updateTaggedGroups(tag)} key={index}>
                  <div className='tag-avatar'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                      <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" />
                    </svg> &nbsp;
                    {tag.code_value}
                    <span className='text-muted text-small'> &nbsp;Notify {tag.user_count} users</span>
                  </div>
                </li>
              ))
              }
            </ul>
          }
          {!current_user?.allow_tagging &&
            <div className='allow'><a href='/profile'>Allow Others to Tag You <b>Here</b></a></div>
          }

        </div>
      }
      <div
        className='textarea'
        placeholder='Reply ...'
        onKeyDown={handleKeyDown}
        contentEditable={true}
        ref={contentEditableRef}
        onInput={(e) => handleContentChange(e)}
        suppressContentEditableWarning={true}
        onFocus={(e) => handleContentChange(e)}
      >
        {defaultText ? ReactHtmlParser(defaultText) : ReactHtmlParser(placeholder)}
      </div>
    </div>
  )
}

export default ContentArea