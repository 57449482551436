import React, { useState } from 'react'

import BackBtn from '../../shared/buttons/BackBtn'
import CustomPagination from '../../shared/components/CustomPagination'
import Favorites from './Favorites'
import ChapterSwitcher from '../../shared/components/chapterSwitcher/ChapterSwitcher'

import { fetchFavoritesPortion } from '../../helpers/fetchHelper'
import { chapterButtons } from './helper'

function FavoritesPage(props) {

   const [activeChapter, setActiveChapter] = useState('favorite_lessons')
   const [activePage, setActivePage] = useState(1)
   const [activeFavoritesList, setActiveFavorritesList] = useState(props.lessons)
   const [pageAmount, setPageAmount] = useState(Math.ceil(props.count / 12))

   function handleActiveChapterChange(chapter) {
      setActiveChapter(chapter)
      setActivePage(1)
      fetchFavoritesPortion(props.authToken, chapter, 1)
         .then(({ favorites, count }) => {
            setActiveFavorritesList(favorites)
            setPageAmount(Math.ceil(count / 12))
         })
         .catch(err => console.log(err))
   }

   function handlePageChange(page) {
      setActivePage(page)
      fetchFavoritesPortion(props.authToken, activeChapter, page)
         .then(({ favorites }) => {
            setActiveFavorritesList(favorites)
         })
         .catch(err => console.log(err))
   }

   return (
      <div className="favorites">
         <BackBtn />
         <h1 className="text_to_center pt-5">Your Favorites</h1>
         <div className="flex_to_center pt-5">
            <ChapterSwitcher
               buttons={chapterButtons}
               activeChapter={activeChapter}
               handleActiveChapterChange={handleActiveChapterChange} />
         </div>
         {
            activeFavoritesList.length ?
               <Favorites
                  authToken={props.authToken}
                  chapter={activeChapter}
                  favoritesList={activeFavoritesList}
               /> :
               <h4 className="text_to_center mt-5 font-italic">Your favorites list is empty</h4>
         }
         {
            pageAmount > 1 &&
            <div className="flex_to_center pt-5">
               <CustomPagination
                  pageAmount={pageAmount}
                  activePage={activePage}
                  handlePageChange={handlePageChange}
               />
            </div>
         }
      </div>
   )
}

export default FavoritesPage