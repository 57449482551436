import { colors } from '@amcharts/amcharts4/core';
import React, { useContext, useState } from 'react';

import { upcomingContext } from '../../Dashboard';


function BookedMeetings({ groupsData, appointments }) {

  const [upcoming, setUpcoming] = useContext(upcomingContext)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const border_colors = ["#B1E2FF", "#FBC743", "#0B3653"]

  return (
    <>
      <div className="upcoming-list mt-3 mb-2">
        {
          appointments.map((appoinment, index) => (
            <div
              className='mb-3 card'
              key={appoinment.id}
              style={{ borderLeft: ` 10px solid ${border_colors[(index + 1) % border_colors.length]}` }}
            >
              <h5>{appoinment.topic}</h5>
              <span className='time text-muted'>
                {new Date(appoinment.start_time).toDateString()} <br />
                {new Date(appoinment.start_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                &nbsp;-&nbsp;
                {new Date(appoinment.start_time + appoinment.duration).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                &nbsp;
                {timezone}

              </span>

              {appoinment.upcoming === true &&
                <a href={appoinment.join_url} target='_blank'>Join Meeting</a>
              }
            </div>
          ))
        }
        {upcoming?.length > 0 ?
          upcoming.map((upNext, index) => (
            groupsData?.find(item => item.id === upNext.sds_group_id)?.title && (
              <div
                className='mb-3 card'
                key={upNext.id}
                style={{ borderLeft: ` 10px solid ${border_colors[(index + 1) % border_colors.length]}` }}
              >
                <h5>{groupsData?.find(item => item.id === upNext.sds_group_id)?.title}</h5>
                <span className='time text-muted'>
                  {new Date(upNext.start_time).toDateString()} <br />
                  {new Date(upNext.start_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                  &nbsp;-&nbsp;
                  {new Date(upNext.start_time + upNext.duration).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                  &nbsp;
                  {timezone}

                </span>

                {upNext.upcoming === true &&
                  <a href={upNext.join_url} target='_blank'>Join Meeting</a>
                }
              </div>
            )
          ))
          :
          (
            <p>Book a group meeting or a session with your coach above. </p>

          )
        }
      </div>
    </>
  );
}

export default BookedMeetings;
