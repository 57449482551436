import React from 'react'
import { SwiperSlide } from 'swiper/react'

import Coach from '../components/Coach'
import CustomCarousel from '../../../shared/components/CustomCarousel'

import adiJaffe from '../../../../assets/images/adi_jaffe.png'

function Coaches(props) {
  return (
    <section className="coaches">
      <div className="wrapper">
        <h2 className="title">Your IGNTD Coaches</h2>
        <div className="coaches__adi-jaffe">
          <div className="coaches__adi-info">
            <h4>Dr. Adi Jaffe</h4>
            <p>
              World renowned author, speaker, and IGNTD founder – Dr. Adi Jaffe is dedicated to
              changing the way we think about and approach mental health issues.
              <br />
              <br />
              He is passionate about ending the shame and stigma surrounding addiction. Dr. Jaffe
              uses his personal experience with addiction as well as decades of research to motivate
              and inspire his community.
            </p>
          </div>
          <img src={adiJaffe} />
        </div>
        <CustomCarousel
          className="carousel"
          pagination={true}
          navigation={true}
          prevEl="prev-el2"
          nextEl="next-el2"
        >
          {props.coaches && props.coaches.map((coach, index) => (
            <SwiperSlide key={index}>
              <Coach {...coach} />
            </SwiperSlide>
          ))}
        </CustomCarousel>
      </div>
    </section>
  )
}

export default Coaches
