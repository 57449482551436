import React from 'react'
import arrowLeftShort from '../../../../assets/images/bi-bi-arrow-left-short.svg'

export default function Subnav(props) {
  return (
    <div className="module__sub-nav">
      <a href="/">
        <div className="module__sub-nav--button">
          <img src={arrowLeftShort} alt="" />
          <h6>Back to dashboard</h6>
        </div>
      </a>
      <div className="container__module">
        <h5>Module {props.currentOrder}. Lesson {props.currentLesson.position}. {props.currentLesson.title}</h5>
      </div>
    </div>
  )
}
