import React, { useEffect, createContext, useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import Hero from './sections/Hero'
import CurrentProgress from './sections/CurrentProgress/CurrentProgress'
import WheelOfLive from './sections/WheelOfLive'
import DailyScores from './sections/DailyScores'
import JoinDiscussion from './sections/JoinDiscussion'
import WorkshopsCarusel from './sections/workshops/WorkshopsCarusel'
import RecommedationsModal from './modals/RecommedationsModal'
import SummaryModal from './modals/SummaryModal'
import LifeScore from '../SummaryPage/components/LifeScore'
import MobileNavigation from './sections/MobileNavigation'
import SdsAssessment from '../AssessmentPage/SdsAssessment'
import AddictionalProgress from './sections/addictionProgress/AddictionalProgress'
import DailyAssessment from '../Homepage/components/DailyAssessment'

import GroupMeetingModal from './modals/GroupMeetingModal'
import RecommendedSnippets from './sections/RecommendedSnippets'
import AcuityBoardingModal from './modals/AcuityBoardingModal'
import WelcomeVideo from './newPatient/WelcomeVideo'
import Checklist from './Checklist'
import AppTips from '../../shared/AppTips'
import AnnouncementModal from './modals/AnnouncementModal'

const upcomingContext = createContext()
function Dashboard(props) {

   const history = useHistory()

   const isMobile = window.innerWidth < 576 ? true : false
   const [showVideo, setShowVideo] = useState(props.dashboard_data.welcome_video != null)
   const [showGroupModal, setShowGroupModal] = useState(false)
   const [showSession, setShowSession] = useState(false)
   const [upcoming, setUpcoming] = useState(props.dashboard_data.meetings_data.upcoming_meetings)
   const [showAnnouncementModal, setShowAnnouncementModal] = useState(!props.current_user.announcement_viewed)

   useEffect(() => {

      if (props.dashboard_data.show_boarding) {
         history.push('/onboardingcall')
         return
      }

      if (props.dashboard_data.sds_assessments_due?.length > 0) {
         history.push('/assessment')
         return
      }


      if (props.showDaily) {
         history.push('/daily')
         return
      }

   }, [])


   const handleCloseModal = () => {
      setShowVideo(false)
   }

   const renderDashboard = () => {
      return (
         <div className="row m-0 p-0 dash-wrapper flex-column-reverse flex-sm-row">
            <div className='col-12 col-sm-8 main-content'>
               <AnnouncementModal
                  current_user={props.current_user}
                  showAnnouncementModal={showAnnouncementModal}
                  setShowAnnouncementModal={setShowAnnouncementModal}
               />
               {!isMobile && (
                  <>
                     <Hero
                        current_user={props.current_user}
                        quote={props.dashboard_data.quote}
                        progress_badges_info={props.dashboard_data.progress_badges_info}
                        total_points={props.dashboard_data.daily_scores_info.total_points}
                     />
                     <Checklist
                        current_user={props.current_user}
                        assignments={props.dashboard_data.assignments}
                        setShowGroupModal={setShowGroupModal}
                        authToken={props.authToken}
                     />
                  </>
               )}

               <div className="graphs">
                  {!isMobile && (
                     <CurrentProgress
                        current_lesson={props.current_lesson}
                        program_completeness={props.dashboard_data.program_completeness}
                        current_program_name={props.current_program.name}

                     />
                  )}

                  <div className="results__block">
                     <div className="results__card results__card_life-score-without-after">
                        <LifeScore
                           life_score={props.dashboard_data.igtnd_life_score}
                           with_two_months={true}
                           account={props.account}
                        />
                     </div>
                     <WheelOfLive
                        this_months_scores={props.dashboard_data.this_months_scores}
                        last_months_scores={props.dashboard_data.last_months_scores}
                        show_summary_button={props.dashboard_data.show_summary_button}
                        account={props.account}
                     />
                  </div>
                  <div className=" mt-4 pt-2 row">
                     <div className="scores-card col-sm-7">
                        <DailyScores
                           end_week_day={props.dashboard_data.daily_scores_info.end_week_day}
                           start_week_day={props.dashboard_data.daily_scores_info.start_week_day}
                           weeks_daily_scores={props.dashboard_data.daily_scores_info.weeks_daily_scores}
                           b2b_user={props.dashboard_data.daily_scores_info.b2b_user}
                           days_counter_amount={props.current_user.days_counter_amount}
                           streak_score={props.dashboard_data.streak_score}
                           setShowSession={setShowSession}
                           role_access={props.role_access}
                        />
                     </div>
                     <div className="progress-card col-sm-5">
                        <AddictionalProgress
                           addiction_progress={props.dashboard_data.addiction_progress}
                           current_user={props.current_user}
                           setShowSession={setShowSession}
                           role_access={props.role_access}
                        />
                     </div>
                  </div>
                  <br />
               </div>

               {isMobile && (
                  <>
                     <WorkshopsCarusel
                        workshops={props.dashboard_data.carousel_workshops}
                        role_access={props.role_access}
                     />
                     <br />


                     {!!props?.role_access && props.role_access.find(item => item.id === 10) && (
                        <div className=" mt-4 pt-2">
                           <br />
                           <div className="flex">
                              <JoinDiscussion current_user_id={props.current_user.id} authToken={props.authToken} />
                           </div>
                           <br />
                        </div>
                     )}

                  </>
               )}
            </div>
            <div className='col-12 col-sm-4 sidebar-right'>
               {isMobile && (
                  <>
                     <Hero
                        current_user={props.current_user}
                        quote={props.dashboard_data.quote}
                        progress_badges_info={props.dashboard_data.progress_badges_info}
                        total_points={props.dashboard_data.daily_scores_info.total_points}
                     />

                     <Checklist
                        current_user={props.current_user}
                        assignments={props.dashboard_data.assignments}
                        setShowGroupModal={setShowGroupModal}
                        authToken={props.authToken}
                     />
                  </>
               )}
               <upcomingContext.Provider value={[upcoming, setUpcoming]}>
                  <GroupMeetingModal
                     current_user={props.current_user}
                     b2b_user={props.dashboard_data.daily_scores_info.b2b_user}
                     authToken={props.authToken}
                     meetings_data={props.dashboard_data.meetings_data}
                     account={props.account}
                     role_access={props.role_access}
                     calendar_img={props.dashboard_data.calendar_img}
                     showGroupModal={showGroupModal}
                     setShowGroupModal={setShowGroupModal}
                     setShowSession={setShowSession}
                     showSession={showSession}
                  />
               </upcomingContext.Provider>
               {isMobile && (
                  <>
                     <CurrentProgress
                        current_lesson={props.current_lesson}
                        program_completeness={props.dashboard_data.program_completeness}
                        current_program_name={props.current_program.name}
                     />
                  </>
               )}

               <RecommendedSnippets
                  recommendation_snippets={props.dashboard_data.recommendation_snippets_info}
                  current_emotion={props.dashboard_data.current_emotion}
                  authToken={props.authToken}
               />
               {!isMobile && (
                  <>
                     <WorkshopsCarusel
                        workshops={props.dashboard_data.carousel_workshops}
                        role_access={props.role_access}
                     />
                     {!!props?.role_access && props.role_access.find(item => item.id === 10) && (
                        <div className=" mt-4 pt-2">
                           <br />
                           <div className="flex">
                              <JoinDiscussion />
                           </div>
                           <br />
                        </div>
                     )}
                  </>

               )}

            </div>
            <WelcomeVideo
               welcome_video_url={props.dashboard_data.welcome_video}
               current_program_name={props.current_program.name}
               show={showVideo}
               handleCloseModal={handleCloseModal}
            />
            <SummaryModal summary_modal_info={props.dashboard_data.summary_modal_info} />
            <RecommedationsModal
               recommendation_snippets={props.dashboard_data.recommendation_snippets_info.slice(0, 3)}
               current_emotion={props.dashboard_data.current_emotion}
               authToken={props.authToken}
            />

            <MobileNavigation
               current_lesson_id={props.current_lesson.id}
               role_access={props.role_access}
            />
            <AppTips
               alerts={props.app_tips}
            />
         </div>
      )
   }

   return (
      <Switch>
         <Route exact path="/" component={renderDashboard} />
         {
            props.dashboard_data.sds_assessments_due?.length > 0 &&
            <Route path="/assessment" component={() =>
               <SdsAssessment
                  {...props}
                  sds_assessments_due={props.dashboard_data.sds_assessments_due}
               />
            }
            />
         }
         {
            props.dashboard_data.show_boarding &&
            <Route path="/onboardingcall" component={() =>
               <AcuityBoardingModal
                  {...props}
               />
            }
            />
         }
         {
            props.showDaily &&
            <Route path="/daily" component={() =>
               <DailyAssessment
                  {...props}
               />} />
         }
         <Route exact path="*" component={renderDashboard} />
      </Switch>
   )
}

export default Dashboard
export { upcomingContext }

