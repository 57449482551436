import React, { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'


function AcuityBoardingModal(props) {
  const acuity_url = props.role_access.find(role => role.id === 11)?.content

  const history = useHistory()
  if (props.dashboard_data.show_boarding == false || acuity_url == null) history.push('/')

  const [showAcuity, setShowAcuity] = useState(true)
  const closeBoarding = () => {
    setShowAcuity(false)
    if (props.dashboard_data.sds_assessments_due?.length > 0) {
      history.push('/assessment')
    } else history.push('/')

  }
  return (
    <Modal
      centered={false}
      open={showAcuity}
      tabIndex="-1"
      role="dialog"
      size="large"
      className="acuitymodal"
      dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={closeBoarding} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span className="closing_cross" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-description">
            <div className='dash_install_btn'><a href='/install'>Install App</a></div>
            <h4 className='pb-4'>
              At IGNTD, we care a lot about your experience, so you get a free onboarding call; all you need to do is schedule it and one of our coaches will walk you through the platform and share resources to help you with your journey.
            </h4>
            <iframe
              src={acuity_url}
              title="Schedule Appointment"
              width="100%"
              height="800"
              frameBorder="0"
            ></iframe>
            <script
              src="https://embed.acuityscheduling.com/js/embed.js"
              type="text/javascript"
            ></script>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AcuityBoardingModal



