export const data = {
  age: '',
  gender: '',
  how_are_you_feeling_today: "0",
  primary_problem: '',
  days_drink_per_week: "0",
  weekly_drink_count: "0",
  weekly_alcohol_spending: "0",
  bothered_by_drinking: "0",
  days_cannabis_per_week: "0",
  earliest_cannabis: "",
  weekly_cannabis_spending: "0",
  bothered_by_cannabis: "0",
  other_drugs: '',
  other_drugs_per_week: "0",
  weekly_other_drug_spending: "0",
  bothered_by_other_drugs: "0",
  sex_issues: '',
  sex_issues_per_week: "0",
  weekly_sex_issues_spending: "0",
  bothered_by_sex_issues: "0",
  gambling_per_week: "0",
  weekly_gambling_spending: "0",
  bothered_by_gambling: "0",
  food_issues: '',
  weekly_food_spending: "0",
  bothered_by_food: "0",
  nicotine_per_week: "0",
  cigarettes_per_day: '',
  earliest_nicotine: '',
  nicotine_form: '',
  weekly_nicotine_spending: "0",
  bothered_by_nicotine: "0",
  additional_struggles: '',
  mental_health_issues: '',
  bothered_by_mental_health: "0",
  first_time: 'Yes',
  hear_about_igntd: '',
  why_sign_up: '',
  looking_forward_feature: '',
  looking_forward_solving: '',
  feel_taken_care_of: '',
  frequency_of_contact: '',
  additional_information: '',
}

export const aboutYourselfQuestions = {
  questions: [
    {
      answer_type: 'integer',
      id: 'age',
      question: 'What is your age?',
    },
    {
      answer_type: 'selection',
      id: 'gender',
      question: 'What is your gender?',
      question_options: [
        {
          value: 'male',
          text: 'Male',
        },
        {
          value: 'female',
          text: 'Female',
        },
        {
          value: 'other_gender',
          text: 'Other',
        },
      ],
    },
    {
      answer_type: 'selection',
      id: 'primary_problem',
      question: 'What is the primary problem you came to IGNTD Recovery for?',
      question_options: [
        {
          value: 'alcohol',
          text: 'Alcohol',
        },
        {
          value: 'cannabis',
          text: 'Cannabis/marijuana',
        },
        {
          value: 'drugs',
          text: 'Drugs',
        },
        {
          value: 'sex',
          text: 'Sex',
        },
        {
          value: 'food',
          text: 'Food',
        },
        {
          value: 'gambling',
          text: 'Gambling',
        },
        {
          value: 'other_primary_problem',
          text: 'Other addiction-related problems',
        },
      ],
    },
    {
      answer_type: 'integer',
      id: 'how_are_you_feeling_today',
      question: 'How are you feeling overall today?',
      max_value: '100',
    },
  ]
}

export const mentalQuestions = {
  questions: [
    {
      id: 'additional_struggles',
      question: 'Are you also struggling with any other problems (if NO, answer \"No\")?',
      answer_type: 'text',
    },
    {
      answer_type: 'selection',
      id: 'mental_health_issues',
      question: 'Which mental health issues are you struggling with currently?',
      question_options: [
        {
          value: 'depression',
          text: 'Depression',
        },
        {
          value: 'anxiety',
          text: 'Anxiety',
        },
        {
          value: 'insomnia',
          text: 'Insomnia',
        },
        {
          value: 'focus',
          text: 'Focus',
        },
        {
          value: 'motivation',
          text: 'Motivation',
        },
        {
          value: 'none_mental',
          text: 'None of the above',
        },
        {
          value: 'other_mental',
          text: 'Other',
        },
      ],
    },
    {
      answer_type: 'integer',
      id: 'bothered_by_mental_health',
      question: 'On a scale of 1-10, how bothered are you by your mental health struggles?',
      max_value: '10',
    },
    {
      answer_type: 'radio',
      id: 'first_time',
      question: 'Is this your first time taking this survey?',
      question_options: [
        {
          value: "Yes",
          text: "Yes"
        },
        {
          value: "No",
          text: "No"
        }
      ]
    },
    {
      answer_type: 'selection',
      id: 'hear_about_igntd',
      question: 'How did your first hear about IGNTD?',
      question_options: [
        {
          value: 'facebook',
          text: 'Facebook',
        },
        {
          value: 'instagram',
          text: 'Instagram',
        },
        {
          value: 'igntd_podcast',
          text: 'The IGNTD podacast',
        },
        {
          value: 'online',
          text: 'Online Search',
        },
        {
          value: 'recommendation',
          text: 'Recommendation',
        },
        {
          value: 'other_podcast',
          text: 'Another podcast',
        },
        {
          value: 'idk',
          text: `I don't remember`,
        },
        {
          value: 'other_about',
          text: 'Other',
        },
      ],
    },
    {

      id: 'why_sign_up',
      question: ' What is that one thing that made you decide to sign up for IGNTD?',
      answer_type: 'text',
    },
    {

      id: 'looking_forward_feature',
      question: 'What feature of the program are you most looking forward to accessing?',
      answer_type: 'text',
    },
    {

      id: 'looking_forward_solving',
      question: 'What problem/problems are you most looking forward to the program solving for you?',
      answer_type: 'text',
    },
    {

      id: 'feel_taken_care_of',
      question: 'What do you think we could do to make sure that you feel taken care of?',
      answer_type: 'text',
    },
    {

      id: 'frequency_of_contact',
      question: 'How many days per week would you like to be contacted by email or phone?',
      answer_type: 'text',
    },
    {
      answer_type: 'textarea',
      id: 'additional_information',
      question: `Thank you very much for completing the survey, if there is additional information
    you want us to be aware of, please fill out this questions.`,
      cols: "100",
      rows: "10"
    }
  ]
}

export const infoList = [
  {
    title: 'Alcohol Addiction:',
    subtitle:
      'If you are in need of help for alcohol dependancy, click here to answer a few questions.',
    id: 'alcoholAddiction',
    questions: [
      {
        answer_type: 'integer',
        id: 'days_drink_per_week',
        question: 'Approximately how many days per week are you drinking alcohol?',
        max_value: '7',
      },
      {
        answer_type: 'integer',
        id: 'weekly_drink_count',
        question: 'About how many alcoholic drinks do you have each week?',
        max_value: '100',
      },
      {
        answer_type: 'integer',
        id: 'weekly_alcohol_spending',
        question: 'How much money are you spending on alcohol per week?',
        max_value: '1000',
      },
      {
        answer_type: 'integer',
        id: 'bothered_by_drinking',
        question: 'On a scale of 1-10, how bothered are you by your drinking?',
        max_value: '10',
      },
    ],
  },
  {
    title: 'Marijuana Addiction:',
    subtitle:
      'If you are in need of help for marijuana dependancy, click here to answer a few questions.',
    id: 'marijuanaAddiction',
    questions: [
      {
        answer_type: 'integer',
        id: 'days_cannabis_per_week',
        question: 'Approximately how many days per week are you smoking cannabis?',
        max_value: '7',
      },
      {
        answer_type: 'selection',
        id: 'earliest_cannabis',
        question: 'How early do you currently begin smoking?',
        question_options: [
          {
            value: 'morning',
            text: 'I currently smoke first thing in the morning',
          },
          {
            value: 'lunch',
            text: 'I currently smoke before lunch',
          },
          {
            value: 'afternoon',
            text: 'I currently smoke in the afternoon',
          },
          {
            value: 'evening',
            text: 'I currently smoke in the evening',
          },
          {
            value: 'bed',
            text: 'I currently smoke before I go to bed',
          },
        ],
      },
      {
        answer_type: 'integer',
        id: 'weekly_cannabis_spending',
        question: 'How much money are you spending on cannabis per week?',
        max_value: '1000',
      },
      {
        answer_type: 'integer',
        id: 'bothered_by_cannabis',
        question: 'On a scale of 1-10, how bothered are you by your cannabis use?',
        max_value: '10',
      },
    ],
  },
  {
    title: 'Other addiction-related problems',
    subtitle:
      'If you are in need of help of help with othe drug dependancies, click here to answer a few questions.',
    id: 'otherDrugs',
    questions: [
      {
        answer_type: 'selection',
        id: 'other_drugs',
        question: 'Please selection the specific drug(s) you came for help with',
        question_options: [
          {
            value: 'opiates',
            text: 'Prescription opiates',
          },
          {
            value: 'stimulants',
            text: 'Prescription stimulants',
          },
          {
            value: 'sedatives',
            text: 'Prescription sedatives',
          },
          {
            value: 'heroin',
            text: 'Heroin',
          },
          {
            value: 'cocaine',
            text: 'Cocaine',
          },
          {
            value: 'meth',
            text: 'Methamphetamine',
          },
          {
            value: 'other_substance',
            text: 'Other',
          },
        ],
      },
      {
        answer_type: 'integer',
        id: 'other_drugs_per_week',
        question: 'Approximately how many days per week are you using the above drugs?',
        max_value: '7',
      },
      {
        answer_type: 'integer',
        id: 'weekly_other_drug_spending',
        question: 'How much money are you spending on drugs per week?',
        max_value: '1000',
      },
      {
        answer_type: 'integer',
        id: 'bothered_by_other_drugs',
        question: 'On a scale of 1-10, how bothered are you by your drug use?',
        max_value: '10',
      },
    ],
  },
  {
    title: 'Sex Issues:',
    subtitle:
      'If you are in need of help for sex dependancy, click here to answer a few questions.',
    id: 'sexIssues',
    questions: [
      {
        answer_type: 'selection',
        id: 'sex_issues',
        question: 'What specific sex-related problem(s) are you having?',
        question_options: [
          {
            value: 'porn',
            text: 'Pornagraphy',
          },
          {
            value: 'paying',
            text: 'Paying for sex',
          },
          {
            value: 'infidelity',
            text: 'Sexual relationships outside commitment',
          },
          {
            value: 'fetish',
            text: 'Fetish',
          },
          {
            value: 'massage',
            text: 'Massage parlor',
          },
          {
            value: 'other_sex_issue',
            text: 'Other',
          },
        ],
      },
      {
        answer_type: 'integer',
        id: 'sex_issues_per_week',
        question: 'Approximately how many days per week are you engaging in these behaviors?',
        max_value: '7',
      },
      {
        answer_type: 'integer',
        id: 'weekly_sex_issues_spending',
        question: 'How much money are you spending on sex/porn per week?',
        max_value: '1000',
      },
      {
        answer_type: 'integer',
        id: 'bothered_by_sex_issues',
        question: 'On a scale of 1-10, how bothered are you by your sex struggles?',
        max_value: '10',
      },
    ],
  },
  {
    title: 'Gambling:',
    subtitle:
      ' If you are in need of help for gambling dependancy, click here to answer a few questions.',
    id: 'gambling',
    questions: [
      {
        answer_type: 'integer',
        id: 'gambling_per_week',
        question: 'Approximately how many days per week are you gambling?',
        max_value: '7',
      },
      {
        answer_type: 'integer',
        id: 'weekly_gambling_spending',
        question: 'How much money are you spending on gambling per week?',
        max_value: '1000',
      },
      {
        answer_type: 'integer',
        id: 'bothered_by_gambling',
        question: 'On a scale of 1-10, how bothered are you by your gambling struggles?',
        max_value: '10',
      },
    ],
  },
  {
    title: 'Food & Eating',
    subtitle:
      'If you are in need of help for food & diet related dependancy, click here to answer a few questions.',
    id: 'foodIssues',
    questions: [
      {

        id: 'food_issues',
        question: 'Please let us know what specific foods you are struggling with',
        answer_type: 'text',
      },
      {
        answer_type: 'integer',
        id: 'weekly_food_spending',
        question: 'How much money are you spending on food-binging per week?',
        max_value: '1000',
      },
      {
        answer_type: 'integer',
        id: 'bothered_by_food',
        question: 'On a scale of 1-10, how bothered are you by your food struggles?',
        max_value: '10',
      },
    ],
  },
  {
    title: 'Nicotine & Tobacco:',
    subtitle:
      'If you are in need of help for nicotine or tobacco dependancy, click here to answer a few questions.',
    id: 'nicotineTabacco',
    questions: [
      {
        answer_type: 'integer',
        id: 'nicotine_per_week',
        question: 'Approximately how many days per week are using tobacco/nicotine?',
        max_value: '7',
      },
      {

        id: 'cigarettes_per_day',
        question: 'About how many cigarettes do you smoke in a typical day?',
        answer_type: 'integer',
      },
      {
        answer_type: 'selection',
        id: 'earliest_nicotine',
        question: 'How early do you currently begin smoking?',
        question_options: [
          {
            value: 'morning',
            text: 'I currently smoke first thing in the morning',
          },
          {
            value: 'lunch',
            text: 'I currently smoke before lunch',
          },
          {
            value: 'afternoon',
            text: 'I currently smoke in the afternoon',
          },
          {
            value: 'evening',
            text: 'I currently smoke in the evening',
          },
          {
            value: 'bed',
            text: 'I currently smoke before I go to bed',
          },
        ],
      },
      {
        answer_type: 'selection',
        id: 'nicotine_form',
        question: 'In what form do you consume nicotine?',
        question_options: [
          {
            value: 'smoking',
            text: 'Smoking',
          },
          {
            value: 'chewing',
            text: 'Chewing',
          },
          {
            value: 'vaping',
            text: 'Vaping',
          },
          {
            value: 'other_nicotine',
            text: 'Other',
          },
        ],
      },
      {
        answer_type: 'integer',
        id: 'weekly_nicotine_spending',
        question: 'How much money are you spending on nicotine per week?',
        max_value: '1000',
      },
      {
        answer_type: 'integer',
        id: 'bothered_by_nicotine',
        question: 'On a scale of 1-10, how bothered are you by your nicotine use?',
        max_value: '10',
      },
    ],
  },
]
