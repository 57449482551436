import React from 'react'

const Lesson = ({assignment}) => {
  return (
    <div>
      <div className='desc text-muted'>
        {assignment.status === "assigned" ? 
          `${assignment.sds_user.fullname} assigned you a ${assignment.assignable.lesson_type}`
          : 
          `You completed a ${assignment.assignable.lesson_type} assignment by ${assignment.sds_user.fullname}`
        }
      </div>
      <a href={assignment.url}> {assignment.status === "assigned" ? 'Complete' : 'Retake'} {assignment.assignable.lesson_type}</a>
    </div>
  )
}

export default Lesson