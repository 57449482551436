import React from 'react'

import dancers from '../../../../assets/images/dancers.png'
import introWorkshop from '../../../../assets/images/introWorkshop.png'

export default function TextContainer(props) {
  //props.isWorkshop
  return (
    <div className="container__module">
      <div className="module__title">
        {props.isWorkshop ? (
          //title from props for workshop!
          <h3 className="module__title-subtitle">{props.currentLesson.title}</h3>
        ) : (
          <h3 className="module__title-subtitle">{props.currentLesson.title}</h3>
        )}
      </div>
      <div className="module__description">
        {props.isWorkshop ? (
          //description from props for workshop!
          <p className="module__description--inner">{props.currentLesson.description}</p>
        ) : (
          <p className="module__description--inner">{props.currentLesson.description}</p>
        )}
      </div>
      <div className="module__content--image">
        {props.isWorkshop ? <img src={introWorkshop} alt="" /> : <img src={dancers} alt="" />}
      </div>
    </div>
  )
}
