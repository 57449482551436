import environment from '../../assets/images/wheelOfLive/environment.svg'
import fun from '../../assets/images/wheelOfLive/fun.svg'
import growth from '../../assets/images/wheelOfLive/growth.svg'
import romance from '../../assets/images/wheelOfLive/romance.svg'
import family from '../../assets/images/wheelOfLive/family.svg'
import health from '../../assets/images/wheelOfLive/health.svg'
import finances from '../../assets/images/wheelOfLive/finances.svg'
import business from '../../assets/images/wheelOfLive/business.svg'
import contribution from '../../assets/images/wheelOfLive/contribution.svg'
import purpose from '../../assets/images/wheelOfLive/purpose.svg'

const rangeList = [
  {
    answer_key: 'Purpose',
    question: 'Purpose : How aligned do you feel with what truly matters for you?',
    img: purpose
  },
  {
    answer_key: 'Contribution',
    question: ' Contribution : How satisfied are you with your service to the world?',
    img: contribution
  },
  {
    answer_key: 'Business',
    question: 'Business : How satisfied do you feel with your overall work situation?',
    img: business
  },
  {
    answer_key: 'Finance',
    question: 'Finance : How satisfied are you with your money + financial reality?',
    img: finances
  },
  {
    answer_key: 'Health',
    question: 'Health : How satisfied are you with your current health status?',
    img: health
  },
  {
    answer_key: 'Family & Friends',
    question: 'Family & Friends : How satisfied are you with your family and friend relationships?',
    img: family
  },
  {
    answer_key: 'Romance',
    question: 'Romance : How satisfied are you with your romantic situation?',
    img: romance
  },
  {
    answer_key: 'Personal Growth',
    question: 'Personal Growth : How satisfied are you with your direction and growth?',
    img: growth
  },
  {
    answer_key: 'Fun & Recreation',
    question: 'Fun & Recreation : How satisfied are you with your level of non-work pure enjoyment and participation?',
    img: fun
  },
  {
    answer_key: 'Physical Environment',
    question: 'Physical Environment : How satisfied are you with your physical space, residence, etc?',
    img: environment
  },
]

export default rangeList
