export const emotionsSwiperBreakpoints = {
   220: {
      slidesPerView: 1,
      spaceBetween: 10,
      slidesPerColumn: 2
   },
   520: {
      slidesPerView: 2,
      spaceBetween: 15,
      slidesPerColumn: 2
   },
   1024: {
      slidesPerView: 3,
      spaceBetween: 15,
      slidesPerColumn: 2
   }
}

export const emotionsSwiperMiniBreakpoints = {
   600: {
      slidesPerColumn: 2
   }
}

export const exerciseEmotionsSwiperBreakpoints = {
   520: {
      slidesPerView: 2,
      spaceBetween: 15,
   },
   768: {
      slidesPerView: 2,
      spaceBetween: 15,
   },
   1025: {
      slidesPerView: 3,
      spaceBetween: 15,
   },
   1440: {
      slidesPerView: 4,
      spaceBetween: 20
   }
}

export const highlightedToolBreakpoints = {
   1026: {
      slidesPerView: 2,
      spaceBetween: 20
   },
   1200: {
      slidesPerView: 3,
      spaceBetween: 20,
   },
}