import React, { useState } from 'react'

import ClarifyField from './ClarifyField'
import Label from './Label'

function Select({ value, onChange, name, options, clarifyInputData = null, popUpDate, label_text }) {

  const optionsList = [{ id: -1, text: "", value: "" }, ...options]

  const [show_clarify, setShowClarify] = useState(false)
  const [show_pop_up, setShowPopUp] = useState(false)

  const handleSelectChange = (event) => {
    onChange(event)
    if (clarifyInputData && event.target.value === clarifyInputData.condition) {
      setShowClarify(true)
    } else {
      setShowClarify(false)
    }
    if (popUpDate && popUpDate.condition === event.target.value) {
      setShowPopUp(true)
    }
  }

  return (
    <>
      {
        label_text &&
        <Label htmlFor={name} label_text={label_text} />
      }
      <select value={value === null ? "null" : value} id={name} name={name} className="dropdown" onChange={handleSelectChange}>
        {optionsList.map((option, index) => (
            <option value={option.value ? option.value : option.text} key={option.id ? option.id : index}>
              {option.text ? option.text : option.value}
            </option>

        ))}
      </select>
      {
        show_clarify &&
        <ClarifyField
          text={clarifyInputData.text}
          clarifyName={name}
          onChangeInput={onChange}
        />
      }
      {
        popUpDate &&
        popUpDate.modal(show_pop_up)
      }
    </>
  )
}

export default Select
