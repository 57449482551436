import React, { useState } from 'react'

import worshops_img from '../../../../../assets/images/dashboard_workshop.png'
import Workshop from './Workshop'

import { removeEmptyValues } from '../../../../helpers/helperFunctions'


function WorkshopsCarusel({ workshops , role_access}) {


   let workshopUpgrade = {
      title: 'Upgrade to join our workshops!',
      description: 'In these workshops, we introduce the concept of picking the destination for your recovery journey and work on getting you there.',
      thumbnail_image_url: worshops_img,
      button_text: 'Upgrade Now',
      button_link: '/get-subscription',
      average_rating: 5,
      id: ''
   }

   if(!role_access.find(item => item.id ==14) ){
      return (<></>);
   }

   let workshopsList = role_access.find(item => item.id ===3) ? 
      [workshops.upcoming_workshop, workshops.highest_rated_workshop1, workshops.highest_rated_workshop2, workshops.highest_rated_workshop3
      ]
      : [workshopUpgrade]

   const getWorkshopList = () => workshopsList


   const workshops_list = removeEmptyValues(getWorkshopList())

   return (
      <section>
         <h4 className='section_header d-none d-sm-block dash_workshops'>Workshops 
            <span className='float-right link'>
               <a href='/workshops'>
                  Explore More &nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                  </svg>
               </a>
            </span>
         </h4>
         <div className='workshops-wrapper'>
            <h4 className='section_header d-sm-none'>Workshops </h4>
            <div className='workshops-slider pb-2'>
               <div className={`workshops ${ workshops_list?.length < 2 && 'upgrade'}`}>
                  {
                     workshops_list.map((workshop, index) =>
                     <Workshop
                        {...workshop}
                        is_upcoming_workshop={workshops.upcoming_workshop && index === 1 ? true : false}
                        key={index} 
                     />)
                  }
                  <div className='d-sm-none dashboard_workshop row mb-3 ml-0'>
                     <a href='/workshops' className='text-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="40.000000pt" height="40.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                           <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#f99267" stroke="none">
                              <path d="M2346 4549 c-369 -39 -752 -199 -1046 -436 -89 -72 -257 -243 -321 -328 -270 -353 -419 -788 -419 -1225 0 -453 161 -906 447 -1260 72 -89 243 -257 328 -321 352 -269 790 -419 1225 -419 435 0 873 150 1225 419 85 64 256 232 328 321 583 722 595 1744 28 2485 -64 85 -232 256 -321 328 -411 332 -950 491 -1474 436z m539 -178 c408 -76 787 -289 1051 -590 218 -249 375 -572 436 -896 32 -173 32 -477 0 -649 -76 -403 -291 -787 -587 -1048 -249 -220 -573 -378 -900 -440 -173 -32 -477 -32 -649 0 -692 131 -1238 622 -1442 1297 -52 170 -68 294 -68 515 0 169 4 225 22 320 50 268 145 500 292 714 140 204 293 356 495 491 263 175 519 268 855 309 89 11 389 -3 495 -23z" fill="#f99267"/>
                              <path d="M2265 3335 c-16 -15 -25 -35 -25 -56 0 -37 -33 -5 432 -421 180 -161 327 -295 327 -298 0 -3 -147 -137 -327 -298 -465 -416 -432 -384 -432 -421 0 -42 38 -81 79 -81 34 0 1 -28 555 470 180 162 319 295 322 308 4 12 4 32 0 45 -4 19 -215 213 -743 685 -115 103 -143 113 -188 67z" fill="#f99267"/>
                           </g>
                        </svg> <br/>
                        Explore More 
                     </a>
                  </div>
               </div>
            </div>
         </div>

      </section>
   )
}

export default WorkshopsCarusel
