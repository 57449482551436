import React from 'react'

const SpecificIssuesCard = ({ title = false, text_list, not_marked = false, limit = null }) => {
  return (
    <div className={`specific-issues__wrapper ${text_list.length ? '' : 'd-none'}`}>
      {
        title &&
        <h3 className="specific-issues__title">{title}</h3>
      }
      {text_list.map((text, i) => {
        if (limit && i + 1 > limit) {
          return null
        }
        return (
          <p className={`specific-issues__text ${not_marked && 'specific-issues__not-marked'}`} key={i}>
            {text}
          </p>
        )
      }
      )}
    </div>
  )
}

export default SpecificIssuesCard
