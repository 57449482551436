import React from 'react'

const Meeting = ({assignment, setShowGroupModal}) => {
  return (
    <div>
      <div className='desc text-muted'>
      { assignment.status === "assigned" ?
         <p>
          {assignment.sds_user.fullname} assigned you a group meeting, '{assignment.title}'
          {assignment.due_today && ` which is happening today.`}
         </p>
        : 
        `You attended a group assigned by ${assignment.sds_user.fullname}`
      }
        
      </div>
      { assignment.status === "assigned" && !assignment.booked &&
        <span onClick={()=>setShowGroupModal(true)} className='link'> Book Now. </span>
      }
    </div>


  )
}

export default Meeting