import React from 'react'

function ClarifyField({ text, clarifyName, onChangeInput, defaultValue = 'Other' }) {
   return (
      <>
         <label className="field__clarify-label" htmlFor={clarifyName}>{text ? text : ""}</label>
         <input
            className="field__clarify-input"
            onChange={(event) => onChangeInput(event, defaultValue)}
            id={clarifyName}
            name={clarifyName}
         />
      </>
   )
}

export default ClarifyField