import React from 'react'
import beercan from '../../../../assets/images/alchoholcan.svg'
import beerglas from '../../../../assets/images/glass_of_alchohol.svg'
import wine from '../../../../assets/images/glass_of_wine.svg'
import wiskey from '../../../../assets/images/glass_of_wiskey.svg'

export default function Tooltip() {
  const alcoDataPercents = [5, 7, 12, 40]
  return (
    <div className="tooltip-wrapper">
      <div className="item">
        <div className="title">
          <span>
            12 fl oz of = <br /> regular beer
          </span>
        </div>
        <div className="alchohol-percent">
          <img src={beercan} />
          <span>(about 5% alcohol)</span>
        </div>
      </div>
      <div className="item">
        <div className="title">
          <span>
            8-9 fl oz of = <br /> malt liquor
          </span>
        </div>
        <div className="alchohol-percent">
          <img src={beerglas} />
          <span>(about 7% alcohol)</span>
        </div>
      </div>
      <div className="item">
        <div className="title">
          <span>
            5 fl oz of table = <br />
            wine
          </span>
        </div>
        <div className="alchohol-percent">
          <img src={wine} />
          <span>(about 12% alcohol)</span>
        </div>
      </div>
      <div className="item">
        <div className="title">
          <span>1.5 fl oz shot of 80-proof spirits</span>
        </div>
        <div className="alchohol-percent">
          <img src={wiskey} />
          <span>(about 40% alcohol)</span>
        </div>
      </div>
    </div>
  )
}
