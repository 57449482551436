import React, { useState, useEffect } from 'react'

import SpecificIssuesCard from './SpecificIssuesCard'
import ModalWithContinue from '../../../shared/modals/ModalWithContinue'

const SpecificIssues = ({ addiction_description }) => {

  const [showModal, setShowModal] = useState(false)

  const handleReadMoreClick = () => {
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    const card = document.querySelector('.specific-issues')
  })

  if (addiction_description) {
    const {
      problems = [],
      critical_problems = [],
      strengths = [],
      critical_strengths = [],
      long_intro = "",
      outro = ""
    } = addiction_description
    return (
      // specific-issues_scrollable
      <div className={`specific-issues specific-issues_scrollable`}>

        <SpecificIssuesCard
          title='Some specific problems we have noted for you'
          text_list={[...problems, ...critical_problems]}
          limit={2}
        />
        <SpecificIssuesCard
          title='Some specific strengths we have noted for you'
          text_list={[...strengths, ...critical_strengths]}
          limit={2}
        />
        {problems?.length < 1 && critical_strengths?.length < 1 &&
          <SpecificIssuesCard
          text_list={long_intro ? [long_intro] : []}
          not_marked={true}
          />
        }
        <button className="results__btn" onClick={() => handleReadMoreClick()}>
          Read More
        </button>
        <ModalWithContinue
          show={showModal}
          onCloseModal={handleCloseModal}
        >
          <SpecificIssuesCard
            text_list={long_intro ? [long_intro] : []}
            not_marked={true}
          />
          <SpecificIssuesCard
            title='Some specific problems we have noted for you'
            text_list={[...problems, ...critical_problems]}
          />
          <SpecificIssuesCard
            title='Some specific strengths we have noted for you'
            text_list={[...strengths, ...critical_strengths]}
          />
          <SpecificIssuesCard
            text_list={outro ? [outro] : []}
            not_marked={true}
          />
        </ModalWithContinue>
      </div >
    )
  } else {
    return null
  }
}

export default SpecificIssues
