import coachAlexandra from '../../assets/images/coachAlexandra.png'
import coachJeff from '../../assets/images/coachJeff.png'
import coachTina from '../../assets/images/coachTina.png'
import module1 from '../../assets/images/module1.jpg'
import module2 from '../../assets/images/module2.jpg'
import module3 from '../../assets/images/module3.jpg'
import workshop1 from '../../assets/images/AreYouReallyAnAlcoholic_.png'
import workshop2 from '../../assets/images/MasterYourBeliefs.png'
import workshop3 from '../../assets/images/UltimateBlissBlueprint.png'

import img1 from '../bundles/SummaryPage/sections/temporaryImg/Master_Your_Beliefs_.png'
import img2 from '../bundles/SummaryPage/sections/temporaryImg/Are_You_Really_An_Alcoholic_.png'
import img3 from '../bundles/SummaryPage/sections/temporaryImg/3.png'

import mod2 from '../bundles/SummaryPage/sections/temporaryImg/Module_2.jpg'
import mod5 from '../bundles/SummaryPage/sections/temporaryImg/Module_5.jpg'
import mod10 from '../bundles/SummaryPage/sections/temporaryImg/Module_10.jpg'

export const dataList = [
  {
    id: 5,
    title: "Let's Get Started",
    description:
      "Congrats! You've made it to the program! You've taken an incredible step by getting this help for yourself and committing to beating your addiction.\r\n\r\nWhether this is your first time getting help or if you've tried dozens of groups and programs and nothing has quite fit, IGNTD is different. \r\n\r\nWe built the SPARx platform to intelligently match content to your mood, your struggles and your life. Recovery no longer has to be one-size-fits all. Get ready for your own unique recovery journey where you can track your progress and actually see the changes you've been hoping for.\r\n\r\nQuick reminder to ensure you take all of the assessments and upload your exercises so that you can continue to progress through the content.\r\n\r\nNow let's get started!",
    rating: 5,
    resource_type: 'outer_link',
    safe_resource_image: module1,
  },
  {
    id: 6,
    title: 'Are you really an alcoholic',
    description:
      'Get access to bonus workshops from our IGNTD Experts Coaches designed specifically for our Heroes!',
    rating: 5,
    resource_type: 'outer_link',
    safe_resource_image: workshop1,
  },
  {
    id: 1,
    name: 'Jeff Jones',
    resource_type: 'Coach',
    public_image_url: coachJeff,
    experience: 18,
    expertise: ['Family Interventions', 'Family Coaching', 'Neuroscience of Nutrition'],
  },
  {
    id: 10,
    title: 'The Power of Our Thoughts',
    description:
      "This session will explore why your negative beliefs and your anchors that have kept you stuck in place. We have to recognize that these exist within ourselves and within others, as well as in the the world at large, and in our recovery in particular. These invisible anchors may have been why recovery has been challenging for you. It's time to honestly explore our own unique stories and where these anchors have been stuck, in order to get ready to raise them. Estimated time: 11 mins",
    rating: 5,
    resource_type: 'outer_link',
    safe_resource_image: module2,
  },
  {
    id: 6,
    title: 'Master your beliefs',
    description:
      'Get access to bonus workshops from our IGNTD Experts Coaches designed specifically for our Heroes!',
    rating: 5,
    resource_type: 'outer_link',
    safe_resource_image: workshop2,
  },
  {
    id: 2,
    name: 'Tina Konkin',
    resource_type: 'Coach',
    public_image_url: coachTina,
    experience: '25+',
    expertise: ['Relationships Coaching'],
  },
  {
    id: 15,
    title: 'Change is the Only Constant',
    description:
      'In this lesson, we explore how the severity and nature of your struggles with addiction have changed over time. Estimated Time: 14 minutes',
    rating: 5,
    resource_type: 'outer_link',
    safe_resource_image: module3,
  },
  {
    id: 6,
    title: 'Ultimate bliss blueprint',
    description:
      'Get access to bonus workshops from our IGNTD Experts Coaches designed specifically for our Heroes!',
    rating: 5,
    resource_type: 'outer_link',
    safe_resource_image: workshop3,
  },
  {
    id: 3,
    name: 'Alexandra Pullen',
    resource_type: 'Coach',
    public_image_url: coachAlexandra,
    experience: 5,
    expertise: ['Yoga', 'Mindfulness & Meditation'],
  },
]

export const tools_list_2 = {
  title: 'Master Your Beliefs Workshop',
  description: 'Led by Bruce Lipton, PhD, author of Biology of Belief, and world renowned speaker. This workshop will help you uncover your subconscious limiting beliefs and use that knowledge for change and growth.',
  safe_resource_image: img1
}
export const tools_list_5 = {
  title: 'Are You Really an Alcoholic Workshop',
  description: 'Led by Dr. Adi Jaffe, founder of IGNTD and leader of the F Shame Movement. This workshop will shed light on the centuries of societal shame and stigma around "alcoholics" and give you the knowledge to begin to F Shame.',
  safe_resource_image: img2
}
export const tools_list_8 = {
  title: 'Boundaries, Communication & Recovery ',
  description: 'Led by Terri Cole, author of Boundary Boss and global relationship and empowerment expert. This workshop will help you understand what boundaries are, how to create and enforce them, while in recovery.',
  safe_resource_image: img3
}

export const modal_2 = {
  title: 'Your Path, Your Story',
  description: 'Within this module, Dr. Jaffe dives into the power of our thoughts, addiction stereotypes and their impact on you, and how to re-script your old beliefs.',
  safe_resource_image: mod2
}

export const modal_5 = {
  title: 'F-Shame & the Hidden Truths',
  description: 'Within this module, Dr. Jaffe explains how to map out our personal path to freedom, the F-Shame philosophy, and the hidden truths of biology, psychology, environment and spirituality.',
  safe_resource_image: mod5
}

export const modal_10 = {
  title: 'The Body Holds So Much',
  description: 'Within this module, Dr. Jaffe shares knowledge on the relationship between trauma and the body, embodiment healing approaches, and how to implement these resources.',
  safe_resource_image: mod10
}
