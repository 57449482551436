import React from 'react'
import { useHistory } from 'react-router-dom'

import ExercisesSwitch from '../lessons/ExercisesSwitch'
import ExerciseStep from './ExerciseStep'

function Exercise({ authToken,
   profiles,
   updateProgress,
   onNextExercise,
   onPrevExercise,
   changeStep,
   submitStep,
   activeExerciseIndex,
   exerciseStep,
   completedProfiles,
   completedTests,
   allTestsPages
}) {

   const history = useHistory()

   const getCompletedTestsIdsList = () => completedTests.map(test => test.id)
   const completedTestsIdList = getCompletedTestsIdsList()

   const handleStepChange = (newStep, completedProfileId, completedTestId) => {

      changeStep(newStep, completedProfileId, completedTestId)

      const newCompletedTestsAmount = completedTestsIdList.includes(completedTestId) ? completedTests.length : completedTests.length + 1
      if (newCompletedTestsAmount === allTestsPages.length && completedProfileId) {
         submitStep(4, 3)
         history.push('/summary')
      }
   }


   return (
      <div className="exercise">
         {
            profiles.length > 1 &&
            <ExercisesSwitch
               currentIndex={activeExerciseIndex}
               exercisesAmount={profiles.length}
               completedEmount={completedProfiles.length}
               onNextClick={onNextExercise}
               onPrevClick={onPrevExercise}
               type="Exercise"
            />
         }
         {
            profiles.length ?
               <div className="mt-50">
                  <ExerciseStep
                     updateProgress={updateProgress}
                     changeStep={handleStepChange}
                     profile={profiles[activeExerciseIndex - 1]}
                     completedProfiles={completedProfiles}
                     completedTestsIdList={completedTestsIdList}
                     step={exerciseStep}
                     authToken={authToken}
                  />
               </div> : null
         }
      </div>
   )
}

export default Exercise