import React, { useState, useEffect, createContext } from 'react'
import ReactHtmlParser from 'react-html-parser'
import Comments from './Comments'
import NewPost from './NewPost'
import NewComment from './NewComment'
import ReactionsModal from './ReactionsModal'
import UserAvatar from 'react-user-avatar'
import Loader from './Loader'
import Notifications from './Notifications'
import './Community.css'
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material'
import { TumblrShareCount } from 'react-share'

var hdate = require('human-date')
const reactionsContext = createContext()

function CommunityPage(props) {
    const [communityPosts, setCommunityPosts] = useState(props.comm_posts)
    const [currentPage, setCurrentPage] = useState(1)


    const [comments, setComments] = useState({})
    const [showComments, setShowComments] = useState(false)
    const [showAddComment, setShowAddComment] = useState(false)

    const [currentPostId, setCurrentPostId] = useState(0)
    const [showNewPost, setShowNewPost] = useState(false)
    const [post, setPost] = useState(null)


    const [showAddReactions, setShowAddReactions] = useState(false)
    const [newReactions, setNewReactions] = useState(null)

    const [showDropDown, setShowDropDown] = useState(false)
    const emojis = props.reactions
    const colors = ['#f4dcb0', '#98b6ca', '#cf9494', '#a6d2a9', '#b1e2ff', '#ebbc44']

    const [loading, setLoading] = useState(false)
    const [commentLoading, setCommentLoading] = useState(false)
    const [scrollableTop, setScrollableTop] = useState(false)
    const [prevScroll, setPrevScroll] = useState(0)
    const [showNotifications, setshowNotifications] = useState(false)
    const [showCommunity, setShowCommunity] = useState(true)
    const [showCommunityEntryModal, setShowCommunityEntryModal] = useState(TumblrShareCount)



    const PostClick = (e, post_id) => {
        if (showComments == showAddReactions) {
            hideOptions()
            getComments(e, post_id)
        } else {
            hideOptions()
        }
    }

    const handlePostToggle = (e, post_id) => {
        e.stopPropagation()
        if (currentPostId == post_id) {
            setShowDropDown(!showDropDown)
        } else {
            setCurrentPostId(post_id)
            setShowDropDown(true)
        }
    }

    const hideOptions = () => {
        setShowComments(false)
        setShowAddComment(false)
        setShowNewPost(false)

        setShowAddReactions(false)
        setShowDropDown(false)
        setshowNotifications(false)

    }

    const handlePostEdit = (e, post) => {
        e.stopPropagation()
        setPost(post)
        hideOptions()
        setShowCommunity(false)
        setShowNewPost(true)
    }

    const addReaction = (e, post_id) => {
        e.stopPropagation()
        setCurrentPostId(post_id)
        hideOptions()
        setShowAddReactions(true)
    }

    useEffect(() => {
        if (newReactions) {
            hideOptions()
            const newState = communityPosts?.map(comm => {
                if (comm.id === currentPostId) {
                    return { ...comm, reactions: newReactions }
                }
                return comm
            });
            setCommunityPosts(newState)
        }
    }, [newReactions])

    const sortReactions = (reactions) => {
        var emoji_groups = []
        reactions?.map((react) => {
            if (emoji_groups[react.emoji_id]) {
                emoji_groups[react.emoji_id].count += 1
            } else {
                emoji_groups[react.emoji_id] = { ...react.emoji, count: 1 }
            }
        })
        return emoji_groups
    }

    const addComment = (e, post_id) => {
        e.stopPropagation()
        setCurrentPostId(post_id)
        setShowAddComment(true)
        setShowComments(false)
        setShowDropDown(false)
    }

    const getComments = (e, post_id) => {
        e.stopPropagation()
        setShowAddComment(false)
        setCurrentPostId(post_id)
        if (showComments && comments[0]?.commentable_id == post_id) {
            setShowComments(false)
            return
        }
        setCommentLoading(true)
        fetch(`/comm_posts/${post_id}/comments`, {
            credentials: 'same-origin',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': props.authToken,
            }
        })
            .then((response) => {
                if (response.status > 204) {
                    throw new Error('Not success')
                }
                return response.json()
            })
            .then(({ comments_list }) => {
                if (comments_list?.length > 0) {
                    setComments(comments_list)
                    setShowComments(true)
                }
                setCommentLoading(false)

            })
            .catch(err => {
                console.log(err)
            })

    }

    const handleOnScroll = (e) => {
        if (loading == true) return
        const { scrollTop, scrollHeight, offsetHeight } = e.target;

        if (scrollTop < prevScroll) setScrollableTop(false)
        else { if (scrollHeight > window.innerHeight) setScrollableTop(true) }

        const hasScrollReachedBottom =
            offsetHeight + scrollTop > scrollHeight - 40;
        if (hasScrollReachedBottom && props.posts_count > communityPosts?.length) {
            getPosts()
        }

        setPrevScroll(scrollTop)
    }

    const handleChannelChange = (new_channel) => {
        setCurrentChannel(new_channel)
        fetchChannelPosts(0, new_channel, true)
    }

    const getPosts = () => {
        setLoading(true)
        setCurrentPage(currentPage + 1)
        fetchChannelPosts(currentPage + 1, currentChannel)
    }

    const fetchChannelPosts = (page, channel, replace = false) => {
        fetch(`/comm_posts/${channel}/${page}/navigate_posts`, {
            credentials: 'same-origin',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': props.authToken,
            }
        })
            .then((response) => {
                if (response.status > 204) {
                    throw new Error('Not success')
                }
                return response.json()
            })
            .then(({ comm_posts }) => {
                console.log(comm_posts)
                if (replace) { setCommunityPosts(comm_posts) }
                else setCommunityPosts([...communityPosts, ...comm_posts])
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const toggleNotifications = () => {
        setShowNewPost(false)

        if (showNotifications == true) setShowCommunity(true)
        else setShowCommunity(false)
        setshowNotifications(!showNotifications)
    }

    const toggleNewPost = () => {
        setshowNotifications(false)

        if (showNewPost == true) setShowCommunity(true)
        else setShowCommunity(false)

        setShowNewPost(!showNewPost)
    }

    const toggleCommunity = () => {
        setshowNotifications(false)
        setShowNewPost(false)
        setShowCommunity(true)
    }

    const handleDelete = (post_id) => {
        if (confirm("Proceed to delete post?") == false) return

        fetch(`/comm_posts/${post_id}`, {
            credentials: 'same-origin',
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': props.authToken,
            }
        })
            .then((response) => {
                if (response.status > 204) {
                    throw new Error('Not success')
                }
                return response.json()
            })
            .then(() => {
                setCommunityPosts(communityPosts.filter((post) => post.id !== post_id))
            })
            .catch(err => {
                console.log(err)
            })
    }

    const [currentChannel, setCurrentChannel] = useState(props.channels[0]?.id)

    return (
        <>
            <div className="community">
                <div className='community-wrapper' onScroll={handleOnScroll}>

                    <div className='community-nav' id="has_scroll" >
                        <ul>
                            <li className='home-btn'>
                                <a href="/dashboard" >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                                        <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                    </svg>
                                    <p>Home</p>
                                </a>
                            </li>
                            {props.channels.length > 0 &&
                                <li >
                                    <FormControl sx={{ m: 1, minWidth: 200 }} >
                                        <InputLabel id="switch-channel">Channels</InputLabel>
                                        <Select
                                            labelId="switch-channel"
                                            id="switch-channel"
                                            value={currentChannel}
                                            onChange={(e) => handleChannelChange(e.target.value)}
                                            input={<OutlinedInput label="switch-channel" />}
                                        >
                                            {props.channels.map((channel) => (
                                                <MenuItem
                                                    key={channel.id}
                                                    value={channel.id}
                                                >
                                                    {channel.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </li>
                            }

                            <li onClick={() => toggleCommunity()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                                    <path d="M211.2 96a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zM32 256c0 17.7 14.3 32 32 32h85.6c10.1-39.4 38.6-71.5 75.8-86.6c-9.7-6-21.2-9.4-33.4-9.4H96c-35.3 0-64 28.7-64 64zm461.6 32H576c17.7 0 32-14.3 32-32c0-35.3-28.7-64-64-64H448c-11.7 0-22.7 3.1-32.1 8.6c38.1 14.8 67.4 47.3 77.7 87.4zM391.2 226.4c-6.9-1.6-14.2-2.4-21.6-2.4h-96c-8.5 0-16.7 1.1-24.5 3.1c-30.8 8.1-55.6 31.1-66.1 60.9c-3.5 10-5.5 20.8-5.5 32c0 17.7 14.3 32 32 32h224c17.7 0 32-14.3 32-32c0-11.2-1.9-22-5.5-32c-10.8-30.7-36.8-54.2-68.9-61.6zM563.2 96a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zM321.6 192a80 80 0 1 0 0-160 80 80 0 1 0 0 160zM32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32H608c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z" />
                                </svg>
                                <p>Posts</p>
                            </li>
                            <li onClick={() => toggleNewPost()} >
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                    <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                                </svg>
                                <p>New Post</p>
                            </li>
                            <li className={`notification-bell ${(props.active_noti_count + props.mentions?.length) > 0 ? 'active' : ''}`} onClick={() => toggleNotifications()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                    <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
                                </svg>
                                {(props.active_noti_count + props.mentions?.length) > 0 &&
                                    <span><i>{(props.active_noti_count + props.mentions?.length)}</i></span>
                                }
                                <p>Notifications</p>
                            </li>
                            <li>
                                <a href="/profile" >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                                    </svg>
                                    <p>Settings</p>
                                </a>
                            </li>

                        </ul>

                    </div>

                    {showNewPost &&
                        <NewPost
                            authToken={props.authToken}
                            setCommunityPosts={setCommunityPosts}
                            setShowNewPost={setShowNewPost}
                            setShowCommunity={setShowCommunity}
                            is_edit={post?.id == currentPostId}
                            current_user={props.current_user}
                            post={post}
                            channel_id={currentChannel}
                        />
                    }

                    {showNotifications &&
                        <reactionsContext.Provider value={[emojis, currentPostId]}>
                            <Notifications
                                current_user={props.current_user}
                                notifications={props.notifications}
                                mentions={props.mentions}
                                authToken={props.authToken}
                                reactions={props.reactions}
                            />
                        </reactionsContext.Provider>

                    }
                    {showCommunity &&
                        <div>
                            {communityPosts?.map((post) => (
                                <div
                                    className="comm_post"
                                    key={post.id}
                                >
                                    <div className="post-header border-0 pb-0">
                                        <div className="d-flex align-items-center justify-content-between py-2 ">
                                            <div className="d-flex align-items-center">
                                                <div className="user-avatar pr-3">
                                                    {post.user.avatar_url ?
                                                        <UserAvatar size="48"
                                                            name={post.user.user_handle}
                                                            src={post.user.avatar_url}
                                                        />
                                                        :
                                                        <UserAvatar size="48" name={post.user.user_handle}
                                                            colors={colors}
                                                        />
                                                    }
                                                </div>
                                                <div>
                                                    <div className="user_details">
                                                        <h6 className="name mb-0"> {post.user.user_handle}
                                                            <span className="posted_at small float-right"> {hdate.relativeTime(post.created_at)}</span>
                                                        </h6>

                                                    </div>
                                                    {/* <p className="mb-0 small">Web Developer at Webestica</p> */}
                                                </div>
                                            </div>
                                            {props.current_user.id == post.user.id &&
                                                <div className="dropdown">
                                                    <span
                                                        onClick={(e) => handlePostToggle(e, post.id)}
                                                        className="text-secondary btn btn-secondary-soft-hover py-1 px-2 show"
                                                        aria-expanded="true"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 128 512">
                                                            <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" /></svg>
                                                    </span>

                                                    {showDropDown && post.id == currentPostId &&
                                                        <ul className="post-drop-down show">

                                                            <li
                                                                className='post-item'
                                                                onClick={(e) => handlePostEdit(e, post)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                    <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" /></svg>
                                                                &nbsp;  &nbsp;Edit
                                                            </li>
                                                            <li
                                                                className='post-item'
                                                                onClick={() => handleDelete(post.id)}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                                                    <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                                                </svg>
                                                                &nbsp;  &nbsp;Delete
                                                            </li>
                                                        </ul>
                                                    }

                                                </div>

                                            }

                                        </div>
                                    </div>
                                    <div className="post-body pt-4" >
                                        <div onClick={(e) => PostClick(e, post.id)} style={{ 'cursor': 'pointer' }}>
                                            <h3 className='title'>{post.title}</h3>
                                            <div className='content pb-4 pt-2'>{ReactHtmlParser(post.content)}</div>

                                            {post.comm_tags?.length > 0 &&
                                                <div className='tag-people p-2 pb-4'>
                                                    <div className='row pb-4'>
                                                        <div className='tagged-list col-11'>
                                                            {post.comm_tags.map((tagged) => (
                                                                <div className='tagged_user' key={tagged.id}>
                                                                    <p>{tagged.user.user_handle}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            }

                                            <div className='reactions'>
                                                {sortReactions(post.reactions)?.map((emoji) => (
                                                    <span key={emoji.id}>{emoji.e_character} <i>{emoji.count}</i></span>
                                                ))}
                                            </div>

                                            <ul className=" nav-stack py-3 small d-flex">
                                                <li className="post-item" onClick={(e) => addReaction(e, post.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                        <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" /></svg>
                                                </li>
                                                <li className="post-item">
                                                    <span className="post-link" onClick={(e) => addComment(e, post.id)} > Post a reply...</span>
                                                </li>
                                                {post.comments_count > 0 &&
                                                    <li className="post-item comment-count">
                                                        <span className="post-link" onClick={(e) => getComments(e, post.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                                                <path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z" />
                                                            </svg> &nbsp;{post.comments_count}</span>
                                                    </li>

                                                }

                                            </ul>
                                        </div>


                                        {
                                            showAddComment ?
                                                currentPostId == post.id &&
                                                <NewComment
                                                    setCommentables={setCommunityPosts}
                                                    commentables={communityPosts}
                                                    commentableId={currentPostId}
                                                    authToken={props.authToken}
                                                    setShowAddComment={setShowAddComment}
                                                    type='comm_posts'
                                                    currentPostId={currentPostId}
                                                    current_user={props.current_user}

                                                />
                                                :
                                                <div>
                                                    {commentLoading && currentPostId == post.id && post.comments_count > 0 ?
                                                        <div className='posts-loading'>
                                                            <Loader
                                                                type="circle"
                                                            />
                                                        </div>
                                                        :
                                                        showComments && comments[0]?.commentable_id == post.id &&
                                                        <reactionsContext.Provider value={[emojis, currentPostId]}>
                                                            <Comments
                                                                comment_lists={comments}
                                                                authToken={props.authToken}
                                                                current_user={props.current_user}
                                                            />
                                                        </reactionsContext.Provider>
                                                    }
                                                </div>
                                        }

                                    </div>
                                </div>
                            ))}
                            {showAddReactions &&
                                <ReactionsModal
                                    emojis={emojis}
                                    reactionable_id={currentPostId}
                                    authToken={props.authToken}
                                    type='comm_posts'
                                    hideOptions={hideOptions}
                                    setNewReactions={setNewReactions}
                                    currentPostId={currentPostId}
                                />

                            }

                        </div>
                    }
                    {loading &&
                        <div className='posts-loading'>
                            <Loader
                                type="circle"
                            />
                        </div>
                    }

                    {scrollableTop &&
                        <a className='scroll-top' href='#has_scroll'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                <path d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z" />
                            </svg>
                        </a>
                    }
                </div>

            </div>
        </>


    )
}

export default CommunityPage
export { reactionsContext } 