export const removeValueFromState = (setState, value) => setState(prevState => prevState.filter(item => item !== value))

export const addValueToState = (setState, value) => setState(prevState => [...prevState, value])

export const changeArrayItem = (arr, new_value, index) => [...arr.slice(0, index), new_value, ...arr.slice(index + 1)]

export const removeEmptyValues = (arr) => arr.filter(item => item)

export const propsAdapter = (adaptableObject, instructions) => {

   const obj = {}

   for (const key in instructions) {
      obj[key] = adaptableObject[instructions[key]]
   }

   return obj
}

export const isObject = (value) => (typeof value === 'object' && !Array.isArray(value) && value !== null) ? true : false

export const isAllObjectValuesEmpty = (obj) => Object.values(obj).every(value => !value)

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getCurrentMonth = () => {
   const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
   const date = new Date()
   return months[date.getMonth()]
}