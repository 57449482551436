import React from 'react'

function RangeInput({ value, onChange, name }) {
  return (
    <div className="field">
      <label className="field__label text-bolder feeling-label">
        <span className="text-bolder">How are you feeling today?*</span>
      </label>
      <input
        type="range"
        name="score"
        id="score"
        onChange={onChange}
        value={value}
        min="1"
        max="100"
      />
      <output id="sliderOutput">{value} / 100</output>
      <div className="split-labels">
        <h6>Feeling bad</h6>
        <h6 className="mobile-hidden">Average</h6>
        <h6>Feeling good</h6>
      </div>
    </div>
  )
}

export default RangeInput
