export const dailyAndWeeklyColors = [
   {
      color: '#296E93',
      text: 'As usual'
   },
   {
      color: '#E7683B',
      text: 'More than usual'
   },
   {
      color: '#45af89',
      text: 'Less than usual'
   },
]

export const mounthlyColors = [
   // {
   //    color: '#0077B6',
   //    text: 'Your progress'
   // },
   {
      color: '#fcba03',
      text: 'Average'
   },
]

export const chapterButtons = [
   {
      text: 'Daily',
      id: 'daily',
   },
   {
      text: 'Weekly',
      id: 'weekly',
   },
   {
      text: 'Monthly',
      id: 'monthly',
   },
]