import React from 'react'

function ChapterSwitcherBtn({ id, text, activeChapter = false, handleActiveChapterChange }) {

   const onChangeChapter = () => {
      handleActiveChapterChange(id)
   }

   return (
      <button
         onClick={onChangeChapter}
         className={id === activeChapter ? "chapter-switcher__btn-active" : "chapter-switcher__btn"}
      >{text}</button>
   )
}

export default ChapterSwitcherBtn