import React, { useEffect } from 'react'
import CustomCarousel from '../../../shared/components/CustomCarousel'

import { SwiperSlide } from 'swiper/react'
import CardAdapter from '../components/CardAdapter'

import {
  tools_list_2, tools_list_5, tools_list_8,
  modal_2, modal_5, modal_10,
} from '../../../helpers/highlightedToolsData'
import { highlightedToolBreakpoints } from '../../../helpers/swiperBreakpoints'


const HighlightedTools = ({ dynamic_tools, loginCount }) => {
  const prevEl = `prev-el1`
  const nextEl = `next-el2`

  let tools_list = [...dynamic_tools]
  const toolsListToRender = loginCount ? dynamic_tools : tools_list

  tools_list.length >= 1 ? tools_list.splice(1, 0, modal_2) : tools_list.push(modal_2)
  tools_list.length >= 2 ? tools_list.splice(2, 0, tools_list_2) : tools_list.push(tools_list_2)
  tools_list.length >= 4 ? tools_list.splice(4, 0, modal_10) : tools_list.push(modal_10)
  tools_list.length >= 5 ? tools_list.splice(5, 0, tools_list_8) : tools_list.push(tools_list_8)
  tools_list.length >= 7 ? tools_list.splice(7, 0, modal_5) : tools_list.push(modal_5)
  tools_list.length >= 8 ? tools_list.splice(8, 0, tools_list_5) : tools_list.push(tools_list_5)

  tools_list = addToMultiple(tools_list, 3, 0)

  function addToMultiple(arr, multiple, index) {
    let result = [...arr]
    add()
    function add() {
      if (result.length % multiple !== 0) {
        result.push(result[index])
        index++
        add()
      } else {
        return result
      }
    }

    return result
  }

  return (
    <div className="highlighted">
      <div className="sum-page__wrapper">
        <h2 className="highlighted__title">Some Highlighted IGNTD Tools To Help You!</h2>
        {
          toolsListToRender.length ?
            <div className="highlighted__carousel">
              <CustomCarousel
                className="carousel_summary"
                navigation={true}
                prevEl={prevEl}
                nextEl={nextEl}
                slidesPerGroup={3}
                loop={true}
                loopFillGroupWithBlank={true}
                highlighted={true}
                centeredSlides={false}
                breakpoints={highlightedToolBreakpoints}
              >
                {
                  toolsListToRender.map((card, index) => (
                    <SwiperSlide key={index}>
                      <CardAdapter {...card}
                        rating={5}
                        isSummary={true}
                        resource_type={card.class_name} />
                    </SwiperSlide>
                  ))
                }
              </CustomCarousel>
            </div> :
            null
        }
      </div>
    </div>
  )
}

export default HighlightedTools
