import React, {useState} from 'react'
import VideoPlayer from '../../../shared/components/VideoPlayer'


const Video = ({assignment, authToken}) => {
  const [showPlayer, setShowPlayer] = useState(false)
  return (
    <div className='videos'>
      <div className='desc text-muted'>
        {assignment.status === "assigned" ?
          `${assignment.sds_user.fullname} assigned you a video`
        : 
          `You watched a video assigned by ${assignment.sds_user.fullname}`
        }
      </div>
      <span className='link' onClick={()=>setShowPlayer(!showPlayer)}>
        {assignment.status === "assigned" ?
          `Watch it Now`
        : 
          `Rewatch it`
        }
      </span>
      <VideoPlayer 
        title={assignment.assignable?.description}
        vimeo_safe_url={ assignment.url}
        showPlayer={showPlayer}
        setShowPlayer={setShowPlayer}
        destination = '/watching-snippets'
        authToken={authToken}
        source_id={assignment.id}
      />
    </div>
  )
}

export default Video