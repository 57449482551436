import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getDatePickerSettings } from '../../helpers/datePickerHelper';

function CalendatInput({ name, onChangeInput }) {

   const [startDate, setStartDate] = useState(null)

   const settings = getDatePickerSettings(1)

   const handleChange = (date) => {
      setStartDate(date)
      onChangeInput({
         target: {
            name,
            value: date
         }
      })
   }

   return (
      <DatePicker
         name={name}
         selected={startDate}
         onChange={handleChange}
         {...settings}
      />
   );
}

export default CalendatInput