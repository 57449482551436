import React, { useState, useEffect, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
import QuestionsPage from './QuestionsPage'

const SdsAssessment = (props) => {
    const cardRef = useRef(null)
    const assessments = props.sds_assessments_due
    const [currentAssessment, setCurrentAssessment] = useState(0)

    const [currrentPage, setCurrrentPage] = useState(0)
    const [formData, setFormData] = useState({})
    const [required, setRequired] = useState([])

    const [addictionQue, setAddictionQue] = useState(null)


    useEffect(() => {
      let newRequired = [...required]
      assessments[currentAssessment]?.pages[currrentPage]?.questions.forEach((que) => {
        if(que.required){
          newRequired.push(que.id)
        }
      })
      setRequired(newRequired)
    }, [currrentPage])

   
    const pageNav = () => {
      if (assessments[currentAssessment].pages?.length-1 <= currrentPage){
        handleSubmit()
      }else{
        setCurrrentPage(currrentPage+1)
        if (cardRef.current) {
          cardRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }
      
    }

    function handleChange(event) {
      setFormData((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }))

      setRequired(required.filter((question_id) => question_id != event.target.name))
    }
    
  
    const handleSubmit = () => {
      fetch(props.current_user ? '/dynamic-asssessment' : 'public-assessments', {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': props.authToken,
        },
        body: JSON.stringify({
          ...formData,
          profile_id: assessments[currentAssessment]?.id,
          token: props.token,
        }),
      })
        .then((response) => {
          if (response.status > 204) {
            throw new Error('Not success')
          } 
          return response.json()
        })
        .then(({resp}) => {
          if(props.token && !props.current_user){
            window.location.href = `/summary/${props.token}`
          }else if(props.type === "usage"){
            window.location.href = '/summary'
          }else if (currentAssessment+1 >= assessments.length){
            window.location.href = '/dashboard'
          }else {
            setFormData({})
            setCurrentAssessment(currentAssessment+1)
          }
        })
        .catch((error) => console.log(error))
    }

    return (
      <>
      { props.token ?
        <div className="pb-5 embedded">
        <form className="introform sds_form">
            <div className="introform__wrapper" >
              <div>
                <h2 className="introform__title">{assessments[currentAssessment].pages[currrentPage]?.title || 'Get your free personalized report'}</h2>
                {assessments[currentAssessment].pages[currrentPage]?.heading && (
                  <div className='page-header my-3'>{ ReactHtmlParser(assessments[currentAssessment]?.pages[currrentPage]?.heading)}</div>
                )

                }
              </div>
              {
                assessments[currentAssessment]?.pages &&
                <div 
                className="intro__card"
                ref={cardRef}  
                >
                  {
                    assessments[currentAssessment]?.pages.map((page, index) =>(
                      <QuestionsPage
                      key={index}
                      page={page}
                      active = {currrentPage == index && true}
                      handleChange={handleChange}
                      formData={formData}
                      addictionQue={addictionQue}
                      setAddictionQue={setAddictionQue}
                    />))
                  }
                </div>
              }
              {assessments[currentAssessment]?.pages[currrentPage]?.footer && (
                  <div className='page-footer my-3'>{ReactHtmlParser(assessments[currentAssessment]?.pages[currrentPage]?.footer)}</div>
              )
              }
              <div></div>
              <div className="introform__button-container">
                <span
                  className={`introform__button ${required?.length > 0 ? '' : 'active'}`}
                  onClick={() => pageNav()}>
                    {assessments[currentAssessment].pages?.length > 0 ?

                      currrentPage+1 >= assessments[currentAssessment].pages?.length ? 'Submit' : 'Next'
                    :
                      "Continue"
                    }
                    </span>
              </div>
            </div>
          </form >
        </div>
      : 
        <section className='intro dashboard__intro'>
          <div className="container__big intro__wrapper">
            <div className="intro__content align-items-center">
                <div className="intro__col">
                  <h2 className="intro__subtitle subtitle">{assessments[currentAssessment]?.title}</h2>
                  <div className="intro__description">
                    {ReactHtmlParser(assessments[currentAssessment]?.dashboard_message)}
                  </div>
                </div>
                <div className="intro__form-col pb-5">
                <form className="introform sds_form">
                    <div className="introform__wrapper">
                      <div>
                        <h2 className="introform__title">{assessments[currentAssessment].pages[currrentPage]?.title || 'Get your free personalized report'}</h2>
                        {assessments[currentAssessment].pages[currrentPage]?.heading && (
                          <div className='page-header my-3'>{ ReactHtmlParser(assessments[currentAssessment]?.pages[currrentPage]?.heading)}</div>
                        )

                        }
                      </div>
                      {
                        assessments[currentAssessment]?.pages &&
                        <div className="intro__card" ref={cardRef}>
                          {
                            assessments[currentAssessment]?.pages.map((page, index) =>(
                              <QuestionsPage
                              key={index}
                              page={page}
                              active = {currrentPage == index && true}
                              handleChange={handleChange}
                              formData={formData}
                              addictionQue={addictionQue}
                              setAddictionQue={setAddictionQue}
                            />))
                          }
                        </div>
                      }
                      {assessments[currentAssessment]?.pages[currrentPage]?.footer && (
                          <div className='page-footer my-3'>{ReactHtmlParser(assessments[currentAssessment]?.pages[currrentPage]?.footer)}</div>
                      )
                      }
                      <div></div>
                      <div className="introform__button-container">
                        <span
                          className={`introform__button ${required?.length > 0 ? '' : 'active'}`}
                          onClick={() => pageNav()}>
                            {assessments[currentAssessment].pages?.length > 0 ?

                            currrentPage+1 >= assessments[currentAssessment].pages?.length ? 'Submit' : 'Next'
                            :
                            "Continue"
                            }
                            </span>
                      </div>
                    </div>
                  </form >
                </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default SdsAssessment

