import React from 'react'
import Portion from './Portion'

function CurrentProgress({ current_lesson, program_completeness, current_program_name }) {
   const isMobile = window.innerWidth < 576 ? true : false
   return (
      <div className="card mb-5 dash_current_lesson" id="lessons">
         <div className="flex_container flex_container-mob-425">
            <h4>{current_program_name}</h4>
         </div>
         <div id="countdown-wrap" className="mb-2 mt-3">
            <div id="glass">
               <div id="progress" style={{
                  width: `${program_completeness}%`,
                  background: `linear-gradient(45deg, #F99267 ${(100 - program_completeness) * 0.9}%, #76bc75)`
               }}>
               </div>
            </div>
            <div className="goal-stat text-right"
               style={{
                  width: `${program_completeness}%`
               }}>
               <span className="goal-number">{program_completeness}% Complete</span>
            </div>

         </div>

         <h4 className='mt-mob_l-0'>{current_lesson && current_lesson.title}</h4>
         <p>{current_lesson && current_lesson.description.slice(0, 100)}...</p>
         <a href={`/lesson/${current_lesson.id}`}>
            <button className="btn_primary mt-mob_l-20 p-1 pl-2 pr-0 text-right">
               continue to program &nbsp;&nbsp;&nbsp;&nbsp;
               <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="20" fill="white" fillOpacity="0.18" />
                  <path d="M11.3419 21.0318H26.5677L23.7167 24.4293C23.3785 24.8321 23.3785 25.4854 23.7167 25.8884C23.8857 26.0898 24.1073 26.1905 24.3289 26.1905C24.5505 26.1905 24.7721 26.0898 24.9411 25.8883L29.2701 20.7296C29.6082 20.3267 29.6082 19.6734 29.2701 19.2705L24.9411 14.1117C24.603 13.7089 24.0548 13.7089 23.7167 14.1117C23.3785 14.5146 23.3785 15.1679 23.7167 15.5708L26.5677 18.9683H11.3419C10.8637 18.9683 10.4761 19.4302 10.4761 20C10.4761 20.5698 10.8637 21.0318 11.3419 21.0318Z" fill="#FAFAFA" />
               </svg>

            </button>
         </a>
      </div>
   )
}

export default CurrentProgress