import React, { useRef, useState } from 'react'

import Assessment from './Assessment'
import WheelAssessment from './WheelAssessment'
import Worksheet from '../../../../shared/components/worksheet/Worksheet'
import Exercise from '../../../../shared/components/exercise/Exercise'

function WorksheetPage(props) {
  const containerRef = useRef(null)
  const [step, setStep] = useState(props.assessmentType)

  function changeFormStep(assessmentType) {
    containerRef.current.scrollIntoView()
    setStep(assessmentType)
  }

  function getCurrentForm() {
    switch (step) {
      case 0:
        return (
          getRightTestType("worksheet-upload")
        )
      case 1:
        return (
          <Assessment
            currentLesson={props.currentLesson}
            authToken={props.authToken}
            onFormSubmit={changeFormStep}
          />
        )
      case 2:
        return (
          <WheelAssessment
            currentLesson={props.currentLesson}
            authToken={props.authToken}
            onFormSubmit={changeFormStep}
          />
        )
      case 3:
        return (
          getRightTestType("assessment360")
        )
      default:
        return null
    }
  }

  function getRightTestType(worksheetUrl) {
    const sortedProfiles = getSortedProfiles()
    return (props.profiles.length) ?
      <Exercise
        profiles={sortedProfiles}
        authToken={props.authToken}
        updateProgress={props.updateProgress}
        onNextExercise={props.onNextExercise}
        onPrevExercise={props.onPrevExercise}
        changeStep={props.changeExersiceStep}
        submitStep={props.submitStep}
        activeExerciseIndex={props.activeExerciseIndex}
        exerciseStep={props.exerciseStep}
        completedProfiles={props.completedProfiles}
        completedTests={props.completedTests}
        allTestsPages={props.allTestsPages}
      />
      :
      <Worksheet
        currentLesson={props.currentLesson}
        updateProgress={props.updateProgress}
        authToken={props.authToken}
        changeStep={props.changeStep}
        url={worksheetUrl}
        submitStep={props.submitStep}
        type="lesson"
      />
  }

  function getSortedProfiles() {
    const profiles = [...props.profiles]
    if (profiles.length <= 1) return profiles
    return profiles.sort((profile, nextProfile) => profile.sort_order - nextProfile.sort_order)
  }

  return (
    <div ref={containerRef}>
      <div className="container__module">
        {step === 0 ||
          step === 3 && (
            <div className="module__title">
              <h3 className="module__title-subtitle">{props.currentLesson && props.currentLesson.title}</h3>
            </div>
          )}
        <div className="module__content">
          <div className="iframe__container">
            {
              props.currentLesson.worksheet_video_url &&
              <iframe
                className='iframe__item'
                src={props.currentLesson.worksheet_video_url}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen"
                allowFullScreen
              ></iframe>
            }
          </div>
          {getCurrentForm()}
        </div>
      </div>
    </div>
  )
}

export default WorksheetPage
